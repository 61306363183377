var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rows.length)?_c('div',{staticClass:"table",style:({ maxHeight: _vm.maxHeight, border: _vm.borderStyle })},[_c('div',{staticClass:"table-header",style:({
      gridTemplateColumns: _vm.getGridTemplateColumns,
      background: _vm.color.header,
      borderBottom: _vm.borderStyle,
    })},[(_vm.orderActions)?_c('div',[_c('move-text',{attrs:{"p":""}})],1):_vm._e(),_vm._l((_vm.header),function(item){return _c('div',{key:item.value,staticClass:"p8 oX"},[_c('move-text',{attrs:{"p":""}},[_vm._v(_vm._s(item.label))])],1)}),(_vm.actions.length)?_c('div',{staticClass:"p8 oX"},[_c('move-text',{attrs:{"p":""}})],1):_vm._e()],2),_c('div',{staticClass:"table-content scroll"},_vm._l((_vm.rows),function(row,index){return _c('div',{key:("row-" + index),staticClass:"table-row",style:({
        gridTemplateColumns: _vm.getGridTemplateColumns,
        background: _vm.getRowColor({ index: index, row: row }),
        borderBottom: index === _vm.rows.length - 1 ? 'none' : _vm.borderStyle,
      }),on:{"mouseover":function($event){_vm.rowHoverIDX = index},"mouseleave":function($event){_vm.rowHoverIDX = -1},"click":function($event){return _vm.onRowClick({ index: index, row: row })}}},[(_vm.orderActions)?_c('move-sort-actions',{staticClass:"cell",attrs:{"disableUp":index === 0,"disableDown":index >= _vm.rows.length - 1},on:{"mouseover":function($event){_vm.disableRowClickEvent = true},"mouseleave":function($event){_vm.disableRowClickEvent = false},"up":function($event){return _vm.$emit('up', { index: index, row: row })},"down":function($event){return _vm.$emit('down', { index: index, row: row })}}}):_vm._e(),_vm._l((_vm.getCells(_vm.getRow(index))),function(cell,index){return _c('div',{key:("cell-" + index),staticClass:"cell"},[_c('move-text',{attrs:{"p":"","noWrap":""}},[_vm._v(_vm._s(cell))])],1)}),(_vm.actions.length)?_c('move-menu-btn',_vm._g({staticClass:"cell",attrs:{"options":_vm.actions,"value":{ row: row, index: index }}},_vm.$listeners)):_vm._e()],2)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }