import { render, staticRenderFns } from "./MoveListGroup.vue?vue&type=template&id=78db05ae&scoped=true&"
import script from "./MoveListGroup.vue?vue&type=script&lang=js&"
export * from "./MoveListGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78db05ae",
  null
  
)

export default component.exports