<template>
  <a v-bind="$attrs" v-on="$listeners" :class="['link-style']" :style="{textDecorationColor: underlineColor, color: color}">
    <slot />
  </a>
</template>

<script>
export default {
  name: "MoveTextLink",
  props: {
    underlineColor: {
      type: String,
      default: "var(--primary-color)"
    },
    color:{
      type: String,
      default: "var(--primary-color)"
    }
  }
};
</script>

<style scoped>

.link-style {
  text-decoration: none;
  font-size: inherit;
}
.link-style:link {
  text-decoration: none;
}

.link-style:visited {
  text-decoration: none;
}

.link-style:hover {
  text-decoration: underline;
}

.link-style:active {
  text-decoration: none;
}
</style>
