import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// Ready translated locale messages
const messages = {
  en: {
    validation: {
      requiredField: "This field is required.",
      pswRequirements: "Minimum 7 characters, at least one digit and one special character.",
      pswEquals: "Passwords do not match!",
    },
    action: {
      contactUs: "Contact Us",
      callUs: "Call Us",
    },
    day: {
      monday: "Monday",
      friday: "Friday",
    },
    title: {
      trendAndStats: "Trend and Statistics",
      eventList: "List of events",
      eventDetails: "Event details",
      /* Trend */
      displacementTrend: "Displacement Trend",
      txTrend: "Transmission Trend",
      txCount: "Transmissions",
      tempTrend: "Temperature Trend",
      dataSummary: "Data summary",
      /* Stats */
      peakStats: "Displacement Statistics",
      accStats: "Acceleration Statistic",
      densProbDisp: "Pk-Pk Displacement Probability Density Function",
      densProbAcc: "Pk-Pk Acceleration Probability Density Function",
      modalFreq: "Modal Frequencies Statistics",
      anomalyDisp: "Anomaly Displacement Detector",
    },
    subTitle: {
      eventList: "Select an event or sample detected by the selected sensor",
    },
    /* Titoli e descrizioni grafici sezione Dashboard */
    graphTitlesOverview: {
      analogNode: "Channels of the analog communicated node",
      deck: "Displacement Graph",
      tiltmeter: "Angles graph",
      accelerometer: "Accelerations Graph",
      temperature: "Temperature",
    },
    graphSubTitlesOverview: {
      analogNode: "Trend of the last 24 hours of the sensors connected to the analog node.",
      deck: "Trend of the last 24 hours of the dynamic displacement detected by the selected sensor",
      tiltmeter: "Trend of the last 24 hours of the two angles Phi and Theta detected by the selected sensor.",
      accelerometer: "Trend of the last 24 hours of accelerations on the 3 axes detected by the selected sensor.",
      temperature: "Graph representing the temperature trend during the last 24 hours.",
    },
    /* Titoli e descrizioni grafici sezione Eventi */
    graphTitlesDetails: {
      deckTimeResponse: "Time response of displacement",
      deckFreqResponse: "Frequency Response of displacement",
      accTimeResponse: "Acceleration Time Response",
      accFreqResponse: "Acceleration Frequency Response",
      tilt: "Angle alteration",
      analogNode: "Channels of the Analog Node",
      analogNodeV2: "Analog Node",
      temperature: "Temperature trend",
      PPV: "PPV - Peak Particle Velocity",
      PCPV: "PCPV - Peak Component Particle Velocity",
      peakAmplitude: "Spectrum Peak Amplitude",
      peakFrequency: "Spectrum Peak Frequency",
    },
    graphSubTitlesDetails: {
      deckTimeResponse: "Trend of the dynamic displacement of a single selected sample.",
      deckFreqResponse: "Frequency response of the oscillation relative to the selected event.",
      accTimeResponse: "Trend of the triaxial acceleration of a single selected event.",
      accFreqResponse: "Frequency response of the oscillation relative to the selected event.",
      tilt: "Trend of all angles detected by the selected sensor.",
      analogNode: "Trend of the data sampled by each sensor connected to the selected Analog Node.",
      analogNodeV2: "Trend of the sensor connected to the selected Analog Node.",
      temperature: "Trend of the temperature detected by the selected sensor.",
      PPV: "Trend over time of the modulus of the velocity vector.",
      PCPV: "Trend over time of the absolute value of each component of the speed.",
      peakAmplitude: "Trend over time of the peak for each component.",
      peakFrequency: "Trend over time of the peak frequency for each component.",
    },
    /* Titoli e descrizioni grafici sezione Trend */
    graphTitlesTrend: {
      tx: "",
      temperature: "",
      displacement: "",
    },
    graphSubTitlesTrend: {
      tx: "",
      temperature: "",
      displacement: "",
    },
    /* Titoli e descrizioni grafici sezione Statistiche */
    graphTitlesStats: {
      densProb: "Pk-Pk Displacement Probability Density Function",
      anomaly: "Anomaly Displacement Detector",
      FDD: "Modal Frequency Statistics",
      clustering: "Modal Frequency Clustering",
    },
    graphSubTitlesStats: {
      densProb: "Compare the frequencies of appearances of the displacement values of two sensors and the related Gaussian curve.",
      anomaly: "Select one or two different sensors to view the displacement events defined as Abnormal.",
      FDD: "Select a group of sensors to view the modal frequencies of the selected time interval.",
      clustering: "",
    },
    dataType: {
      accelerations: "Accelerations",
      extensions: "Extensions",
      tilts: "Tilts",
      displacements: "Displacements",
      vibrations: "Vibrations",
      acceleration: "Acceleration",
      crack: "Extension",
      tilt: "Tilt",
      temperature: "Temperature",
      displacement: "Displacement",
      amplitudeSpectrum: "Amplitude Spectrum",
      frequency: "Frequency",
    },
    message: {
      /* Warning */
      irreversibleWarning: "This cannot be undone. Are you sure you want to continue? ",
      compensationWarning: "By deactivating the function, the absolute data (read by the instrument) will be displayed, without any compensation related to the installation mode.",
      deviceselect: "Select the device",
      multiDeckSelect: "Select one or more Deck device",
      deviceselectinfo: "This section displays the data about the last transmission and the battery level of the device. You can also select a specific Deck sensor to gain more information about it",
      bridgescroll: "Swipe the bridge to view all sensors",
      railscroll: "Swipe the bridge to select one or more tiltmeter device",
      selectDevicesToViewData: "Select the devices to view the data",
      coordinates: "Coordinates",
      monitoredSpans: "monitored spans",
      installedDevices: "installed devices",
      installDate: "Install date",
      startDate: "Start Date",
      endDate: "End date",
      dateRange: "Date range",
      notEnoughSensorsOnSpan: "Min. 2 sensors per span required",
      someTiltmeterWithoutBaseTilt: "Reset the sensors offset",
      /* Dialog */
      delete: "Delete",
      edit: "Edit",
      undo: "Undo",
      update: "Update",
      loading: "Loading",
      noDataInRange: "No data in selected range",
      noDataForSensor: "No data is available for the selected device",
      activatedF: "Activated",
      deactivatedF: "Deactivated",
      activatedM: "Enabled",
      deactivatedM: "Disabled",
      activeF: "Active",
      inactiveF: "Inactive",
      selectAll: "Select All",
      deselectAll: "Deselect All",
      reset: "Reset",
      resetAll: "Reset all",
      noData: "No data available",
      saveChanges: "Save changes",
      logInCaption: "Welcome Back! Log in to continue.",
      helpMe: "Need help?",
      helpDesk: "Customer service",
      /* Date */
      last7Days: "Last 7 days",
      last15Days: "Last 15 days",
      last30Days: "Last 30 Days",
      last12Weeks: "Last 12 Weeks",
      last6Months: "Last 6 Months",
      last12Months: "Last 12 Months",
      date: "Date",
      /* Devices */
      sensor: "Sensor",
      deck: "Deck",
      vibrometer: "Vibrometer",
      tiltmeter: "Tiltmeter",
      accelerometer: "Accelerometer",
      crackmeter: "Crackmeter",
      analogNode: "Analog Node",
      decks: "Deck",
      tiltmeters: "Tiltmeters",
      accelerometers: "Accelerometers",
      crackmeters: "Crackmeters",
      analogNodes: "Analog Nodes",
      vibrometers: "Vibrometers",
      channel: "Channel",
      /* Options */
      dashboard: "Dashboard",
      events: "Events",
      statistics: "Statistics",
      settings: "Settings",
      statusMonitor: "Status Monitor",
      notifications: "Notifications",
      manage: "Management",
      summary: "Summary",
      alert: "Alarm",
      alertList: "Alert list",
      structure: "Structure",
      spans: "Spans",
      span: "Span",
      axis: "Axis",
      sleeper: "Sleeper",
      sleepers: "Sleepers",
      numberOfSleepers: "Number of sleepers",
      sleepersLength: "Sleepers length",
      sleepersHeight: "Sleepers height",
      add: "Add",
      name: "Name",
      editMode: "Edit mode",
      addSpan: "Add a New Span",
      setSpans: "Set the bridge spans",
      setSensorSpan: "Set the sensor positioning on the structure span",
      positioning: "Positioning",
      mailNotifications: "Mail notifications",
      mailAddresses: "Mail addresses",
      mailAddressesExplain: "Receive notifications when the threshold is exceeded",
      setSampleRate: "Set the sample rate of the following devices",
      setCadence: "Set the sampling cadence of the following devices",
      setResolution: "Set the resolution of the following devices",
      setStructInfos: "Set the general infos of the selected structure",
      manageDevices: "Customize and rename the following devices",
      resetCompensation: "Reset the compensation offset of the following devices",
      absoluteValue: "Absolute valute",
      compensated: "Compensation executed",
      notCompensated: "Compensation not executed yet",
      setActivationThreshold: "Set the minimum activation threshold for data sampling of the following devices",
      threshold: "Threshold",
      alertDevices: "When the set value (Peak to Peak) is exceeded, a notification email will be sent. Set the alarm threshold of the following devices",
      alertTiltmeterDevices: "When the set value is exceeded, a notification email will be sent. Set the alarm threshold of the following devices",
      globalStatus: "Global system status",
      newMailAddress: "Enter a new mail address",
      logIn: "Log in",
      groupSelection: "Selected group",
      mesUnitSelect: "Select Unit of Measurement",
      users: "Users",
      manageUsers: "Manage the guest users",
      addUser: "Insert a new guest user",
      editPsw: "Insert/Edit password",
      modifyPsw: "Edit password",
      insertPsw: "Insert password",
      descending: "Descending",
      ascending: "Ascending",
      allSensors: "All sensors",
      selSensor: "Selected sensor",
      regulation: "Regulation",
      /* Graphs */
      displacementGraph: "Displacement Graph",
      accelerationsGraph: "Accelerations Graph",
      tiltGraph: "Tilt Graph",
      tilt3dGraph: "3D variation angle",
      crackGraph: "Extension Graph",
      temperatureGraph: "Temperature Graph",
      densProb: "Probability Density Function",
      freqApp: "Probability Density",
      deflectionGraph: "Deflection",
      deflectionDesc: "Structure lowering graph",
      /* Infos */
      general: "General",
      dataOf: "Data of",
      dataOfSelectedDevice: "Data of the selected device",
      deviceInfo: "Device info",
      dateSorting: "Sort by date",
      last24Data: "Last 24h data",
      lastTemperature: "Last recorded temperature",
      lastTX: "Last TX",
      signalQuality: "Signal quality",
      samples: "Samples",
      cadence: "Cadence",
      deviceData: "Data of the Dev_EUI",
      deckMultiData: "Data of all selected Deck devices",
      maxPeak: "Maximum peak",
      minPeak: "Minimum peak",
      peakToPeak: "Peak to Peak",
      maxAcceleration: "Maximum acceleration",
      maxCrack: "Maximum extension",
      maxTilt: "Maximum tilt",
      minTemp: "Minimum temperature",
      maxTemp: "Maximum temperature",
      dailyAverageEvents: "Daily average nr. of events",
      counterEvents: "Number of events",
      thresholdCrossingCount: "Threshold crossing count",
      selectedDevices: "Selected devices",
      acqType: "Acquisition type",
      value: "Value",
      meanValue: "Average value",
      minValue: "Min value",
      maxValue: "Max value",
      variance: "Variance",
      stdDeviation: "Std. Deviation",
      /* Functions */
      dataCompensation: "Data compensation",
      sampleRate: "Sample Rate",
      resolution: "Resolution",
      activationThreshold: "Activation threshold",
      compensation: "Compensation",
      mesUnit: "Unit",
    },
  },
  it: {
    validation: {
      requiredField: "Il campo è richiesto.",
      pswRequirements: "Minimo 7 caratteri, almeno un numero ed un carattere speciale.",
      pswEquals: "Le password non corrispondono!",
    },
    action: {
      contactUs: "Contattaci",
      callUs: "Chiamaci",
    },
    day: {
      monday: "Lunedì",
      friday: "Venerdi",
    },
    title: {
      trendAndStats: "Trend e Statistiche",
      eventList: "Elenco degli eventi",
      eventDetails: "Dettagli degli eventi",
      /* Trend */
      displacementTrend: "Trend del Displacement",
      txTrend: "Trend del numero di Trasmissioni",
      txCount: "Numero di Trasmissioni",
      tempTrend: "Trend delle temperature",
      dataSummary: "Riepilogo dei dati",
      /* Stats */
      peakStats: "Statistiche dei picchi di spostamento",
      accStats: "Statistiche dei picchi di accelerazion",
      densProbDisp: "Densità di Probabilità degli spostamenti Pk-Pk",
      densProbAcc: "Densità di Probabilità delle accelerazioni  Pk-Pk",
      modalFreq: "Statistiche delle Frequenze Modali",
      anomalyDisp: "Rivelatore della anomalie di spostamento",
    },
    subTitle: {
      eventList: "Seleziona un evento o un campionamento rilevato dal sensore selezionato",
    },
    /* Titoli e descrizioni grafici sezione Dashboard */
    graphTitlesOverview: {
      analogNode: "Canali del nodo comunicativo analogico",
      deck: "Grafico dello spostamento",
      tiltmeter: "Grafico degli angoli",
      accelerometer: "Grafico delle Accelerazioni",
      temperature: "Temperatura",
    },
    graphSubTitlesOverview: {
      analogNode: "Andamento delle ultime 24 ore dei sensori collegati al nodo analogico.",
      deck: "Andamento delle ultime 24 ore del displacement dinamico rilevato dal sensore selezionato.",
      tiltmeter: "Andamento delle ultime 24 ore dei due angoli Phi e Theta rilevati dal sensore selezionato.",
      accelerometer: "Andamento delle ultime 24 ore delle accelerazioni sui 3 assi rilevati dal sensore selezionato.",
      temperature: "Grafico rappresentante l’andamento della temperatura durante le ultime 24 ore.",
    },
    /* Titoli e descrizioni grafici sezione Eventi */
    graphTitlesDetails: {
      deckTimeResponse: "Time response dello spostamento",
      deckFreqResponse: "Frequency Response dello spostamento",
      accTimeResponse: "Time Response dell’accelerazione",
      accFreqResponse: "Frequency Response dell’accelerazione",
      tilt: "Variazione angolare",
      analogNode: "Canali del Nodo Analogico",
      analogNodeV2: "Analog Node",
      temperature: "Andamento delle temperature",
      PPV: "PPV - Velocità di picco puntuale",
      PCPV: "PCPV - Velocità di picco di una componente puntuale",
      peakAmplitude: "Ampiezze del picco dello spettro",
      peakFrequency: "Frequenza del picco dello spettro",
    },
    graphSubTitlesDetails: {
      deckTimeResponse: "Andamento dello spostamento dinamico di un singolo campionamento selezionato.",
      deckFreqResponse: "Risposta in frequenza dell’oscillazione relativa all’evento selezionato.",
      accTimeResponse: "Andamento dell’accelerazione sui tre assi di un singolo evento selezionato.",
      accFreqResponse: "Risposta in frequenza dell’oscillazione relativa all’evento selezionato.",
      tilt: "Andamento di tutti gli angoli rilevati dal sensore selezionato.",
      analogNode: "Andamento dei dati campionati da ogni sensore collegato al Nodo Analogico selezionato.",
      temperature: "Andamento della temperatura rilevata dal sensore selezionato.",
      PPV: "Andamento nel tempo del modulo del vettore velocità.",
      PCPV: "Andamento nel tempo del valore assoluto di ciascuna componente della velocità.",
      peakAmplitude: "Andamento nel tempo del picco per ciascuna componente.",
      peakFrequency: "Andamento nel tempo della frequenza del picco per ciascuna componente.",
    },
    /* Titoli e descrizioni grafici sezione Trend */
    graphTitlesTrend: {
      tx: "",
      temperature: "",
      displacement: "",
    },
    graphSubTitlesTrend: {
      tx: "",
      temperature: "",
      displacement: "",
    },
    /* Titoli e descrizioni grafici sezione Statistiche */
    graphTitlesStats: {
      densProb: "Densità di Probabilità degli spostamenti Pk-Pk",
      anomaly: "Rivelatore della anomalie di spostamento",
      FDD: "Statistiche delle Frequenze Modali",
      clustering: "Clustering delle Frequenze Modali",
    },
    graphSubTitlesStats: {
      densProb: "Confronta le frequenze di apparizioni dei valori di spostamento di due sensori e la relativa curva gaussiana.",
      anomaly: "Seleziona uno o due differenti sensori per visualizzare gli eventi di spostamento definiti Anomali.",
      FDD: "Seleziona un gruppo di sensori per visualizzare le frequenze modali dell’intervallo temporale selezionato.",
      clustering: "",
    },
    dataType: {
      accelerations: "Accelerazioni",
      extensions: "Estensioni",
      tilts: "Angoli",
      displacements: "Displacement",
      vibrations: "Vibrazioni",
      acceleration: "Accelerazione",
      crack: "Estensione",
      tilt: "Tilt",
      temperature: "Temperatura",
      displacement: "Displacement",
      amplitudeSpectrum: "Spettro di ampiezza",
      frequency: "Frequenza",
    },
    message: {
      /* Warning */
      irreversibleWarning: "Questa azione non può essere annullata. Sei sicuro di voler procedere? ",
      compensationWarning: "Disattivando la funzionalità si avranno i dati assoluti letti dallo strumento senza alcuna compensazione relativa alla modalità di installazione.",
      deviceselect: "Seleziona il device",
      multiDeckSelect: "Seleziona i molteplici device Deck:",
      deviceselectinfo:
        "In questa sezione vengono visualizzati i dati relativi all’ultima trasmissione effettuata ed al livello della batteria del dispositivo, è inoltre possibile selezionare uno specifico sensore Deck di cui vuoi approfondire le informazioni.",
      bridgescroll: "Scorri il ponte per visualizzare tutti i sensori",
      railscroll: "Scorri i binari per selezionare molteplici inclinometri",
      selectDevicesToViewData: "Seleziona i sensori per visualizzare i dati",
      coordinates: "Coordinate",
      monitoredSpans: "campate monitorate",
      installedDevices: "device installati",
      installDate: "Data di installazione",
      startDate: "Data di inizio",
      endDate: "Data di fine",
      dateRange: "Intervallo di tempo",
      notEnoughSensorsOnSpan: "Min. 2 sensori per campata richiesti",
      someTiltmeterWithoutBaseTilt: "Effettuare il reset degli offset",
      /* Dialog */
      delete: "Elimina",
      edit: "Modifica",
      undo: "Annulla",
      update: "Aggiorna",
      loading: "Caricamento",
      noDataInRange: "Nessun dato disponibile per il range scelto",
      noDataForSensor: "Nessun dato disponibile per il device selezionato",
      activatedF: "Attivata",
      deactivatedF: "Disattivata",
      activatedM: "Attivato",
      deactivatedM: "Disattivato",
      activeF: "Attiva",
      inactiveF: "Non attiva",
      selectAll: "Seleziona Tutto",
      deselectAll: "Deseleziona Tutto",
      reset: "Resetta",
      resetAll: "Resetta tutto",
      noData: "Nessun dato disponibile",
      saveChanges: "Salva modifiche",
      logInCaption: "Bentornato! Per favore accedi per continuare.",
      helpMe: "Bisogno di assistenza?",
      helpDesk: "Supporto tecnico",
      /* Date */
      last7Days: "Ultimi 7 giorni",
      last15Days: "Ultimi 15 giorni",
      last30Days: "Ultimi 30 giorni",
      last12Weeks: "Ultime 12 settimane",
      last6Months: "Ultimi 6 Mesi",
      last12Months: "Ultimi 12 Mesi",
      date: "Data",
      /* Devices */
      sensor: "Sensore",
      deck: "Deck",
      vibrometer: "Vibrometro",
      tiltmeter: "Inclinometro",
      accelerometer: "Accelerometro",
      crackmeter: "Estensimetro",
      analogNode: "Nodo Analogico",
      decks: "Deck",
      tiltmeters: "Inclinometri",
      accelerometers: "Accelerometri",
      crackmeters: "Estensimetri",
      analogNodes: "Nodi Analogici",
      vibrometers: "Vibrometri",
      channel: "Canale",
      /* Options */
      dashboard: "Dashboard",
      events: "Eventi",
      statistics: "Statistiche",
      settings: "Opzioni",
      statusMonitor: "Stato Sistema",
      notifications: "Notifiche",
      manage: "Gestisci",
      summary: "Riepilogo",
      alert: "Allarme",
      alertList: "Lista degli allarmi",
      structure: "Struttura",
      spans: "Campate",
      span: "Campata",
      axis: "Asse",
      sleeper: "Traversina",
      sleepers: "Traversine",
      numberOfSleepers: "Numero di traversine",
      sleepersLength: "Lunghezza traversine",
      sleepersHeight: "Altezza traversine",
      add: "Aggiungi",
      name: "Nome",
      editMode: "Modalità di modifica",
      addSpan: "Aggiungi Nuova Campata",
      setSpans: "Imposta le campate del ponte",
      setSensorSpan: "Imposta il posizionamento del sensore sulla campata",
      positioning: "Posizionamento",
      mailNotifications: "Mail di notifica",
      mailAddresses: "Indirizzi Mail",
      mailAddressesExplain: "Ricevi notifiche di allarme per superamento della soglia",
      setSampleRate: "Imposta la frequenza di campionamento dei seguenti dispositivi",
      setCadence: "Imposta la cadenza di campionamento dei seguenti dispositivi",
      setResolution: "Imposta la risoluzione dei seguenti dispositivi",
      setStructInfos: "Personalizza le informazioni generali riguardanti la struttura selezionata",
      manageDevices: "Personalizza e rinomina i seguenti dispositivi",
      resetCompensation: "Resetta l'offset di compensazione dei seguenti dispositivi",
      absoluteValue: "Valore assoluto",
      compensated: "Compensazione eseguita",
      notCompensated: "Compensazione non eseguita",
      setActivationThreshold: "Imposta la soglia minima di attivazione per il campionamento dati dei seguenti dispositivi",
      threshold: "Soglia",
      alertDevices: "Al superamento del valore impostato (Picco-Picco) verrà inviata un’email di notifica. Imposta la soglia d’allarme dei seguenti dispositivi",
      alertTiltmeterDevices: "Al superamento del valore impostato verrà inviata un’email di notifica. Imposta la soglia d’allarme dei seguenti dispositivi",
      globalStatus: "Stato globale del sistema",
      newMailAddress: "Inserisci un nuovo indirizzo mail",
      logIn: "Accedi",
      groupSelection: "Gruppo selezionato",
      mesUnitSelect: "Seleziona l'unità di misura",
      users: "Utenti",
      manageUsers: "Gestisci gli utenti ospite",
      addUser: "Inserisci un nuovo utente ospite",
      editPsw: "Inserisci/Modifica password",
      modifyPsw: "Modifica password",
      insertPsw: "Inserisci password",
      descending: "Decrescente",
      ascending: "Crescente",
      allSensors: "Tutti i sensori",
      selSensor: "Sens. selezionato",
      regulation: "Normativa",
      /* Graphs */
      displacementGraph: "Grafico del Displacement",
      accelerationsGraph: "Grafico delle Accelerazioni",
      tiltGraph: "Grafico degli Angoli",
      tilt3dGraph: "Angolo di variazione 3D",
      crackGraph: "Grafico delle Estensioni",
      temperatureGraph: "Grafico delle Temperature",
      densProb: "Densità di probabilità",
      freqApp: "Frequenza di apparizione",
      deflectionGraph: "Deformata",
      deflectionDesc: "Grafico di abbassamento della struttura",
      /* Infos */
      general: "Generale",
      dataOf: "Dati relativi a",
      dataOfSelectedDevice: "Dati relativi al singolo device selezionato",
      deviceInfo: "Informazioni sul device",
      dateSorting: "Ordina per data",
      last24Data: "Dati delle ultime 24h",
      lastTemperature: "Ultima temperatura registrata",
      lastTX: "Ultima TX",
      signalQuality: "Qualità del segnale",
      samples: "Campionamenti",
      cadence: "Cadenza",
      deviceData: "Dati relativi a Dev_EUI",
      deckMultiData: "Dati relativi a tutti i device Deck selezionati",
      maxPeak: "Picco massimo",
      minPeak: "Picco minimo",
      peakToPeak: "Picco Picco",
      maxAcceleration: "Accelerazione massime",
      maxCrack: "Estensione massima",
      maxTilt: "Angolo massimo",
      minTemp: "Temperatura minima",
      maxTemp: "Temperatura massima",
      dailyAverageEvents: "Media eventi giornalieri",
      counterEvents: "Numero totale eventi",
      thresholdCrossingCount: "Superamenti della soglia",
      selectedDevices: "Dispositivi selezionati",
      acqType: "Tipo acquisizione",
      value: "Valore",
      meanValue: "Valore medio",
      minValue: "Valore min",
      maxValue: "Valore max",
      variance: "Varianza",
      stdDeviation: "Deviazione Std.",
      /* Functions */
      dataCompensation: "Compensazioni dati",
      sampleRate: "Frequenza di campionamento",
      resolution: "Risoluzione",
      activationThreshold: "Soglia di attivazione",
      compensation: "Compensazione",
      mesUnit: "Unità di misura",
    },
  },
};

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale: navigator.language,
  silentFallbackWarn: true,
  fallbackLocale: {
    /* 1 */ "it-IT": ["it"],
    /* 6 */ default: ["en"],
  },
  messages, // set locale messages
});

console.log("Setto lingua -> " + navigator.language);

//    this.$root.$i18n.locale = "en"
