<template>
  <div class="m-container" >
    <!-- Wrapper -->
    <div class="m-content" :style="{width: width, maxWidth: width, height: height, maxHeight: height}">
      <!-- Header -->
      <div v-if="!simple" class="header">
        <slot name="header"></slot>
      </div>
      <!-- content -->
      <div class="content scroll" :class="{simple: simple}">
        <slot></slot>
      </div>
      <!-- Footer -->
      <div v-if="!simple" class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "MoveModal",
  props: {
    width: {
      type: String,
      default: 'none',
    },
    height: {
      type: String,
      default: 'none',
    },
    simple: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onDecline() {
      this.$emit("decline");
    },
    onConfirm() {
      this.$emit("confirm");
    },
  },
  mounted(){
    // Remove body-scroll
    $(document.body).addClass("modal-open");
  },
  beforeDestroy() {
    // add body-scroll
    $(document.body).removeClass("modal-open");
  },
};
</script>
<style scoped>

.m-container {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999;
}

.m-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  min-width: 300px;
  max-width: 60vw;

  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  border: solid 0.1em white;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  z-index: 999999999;

}

.m-content >>> .scroll{
  overflow-y: scroll;
}



.content {
  min-height: 100px;
  width: 100%;
  height: 100%;

  max-height: 60vh;
}

.content.simple{
  max-height: none;
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
}
</style>
