<template>
  <div class="cirle-chip" v-bind:style="{ backgroundColor: backgroundColor }">
    <slot />
  </div>
</template>

<script>
export default {
    name: "MoveCircleChip",
    props:{
        backgroundColor: {
            type: String,
            default: '#fff'
        }
    }
};
</script>

<style scoped>
.cirle-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 12px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-right: 8px;
  font-size: 1.2em;
  font-weight: bold;
}


</style>
