const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
const API_ENDPOINT_V3 = process.env.VUE_APP_API_ENDPOINT_V3;
const DECKTASK_ENDPOINT = process.env.VUE_APP_DECKTASK_ENDPOINT;
const DECKPOST_ENDPOINT = process.env.VUE_APP_DECKPOST_ENDPOINT;
const FFT_ENDPOINT = process.env.VUE_APP_FFT_ENDPOINT;
const AUTH_ENDPOINT = API_ENDPOINT;
const DP_ENDPOINT = process.env.VUE_APP_DP_ENDPOINT;
const EULA_LINK = process.env.VUE_APP_EULA_LINK;
const NEW_PLATFORM_WEBSITE_URL = process.env.VUE_APP_NEW_PLATFORM_WEBSITE_URL;
const NEW_PLATFORM_WEBAPP_URL = process.env.VUE_APP_NEW_PLATFORM_WEBAPP_URL;
const DISABLE_SENSORS_SETTINGS = process.env.VUE_APP_DISABLE_SENSORS_SETTINGS;

module.exports = {
  API_ENDPOINT,
  DECKTASK_ENDPOINT,
  DECKPOST_ENDPOINT,
  AUTH_ENDPOINT,
  FFT_ENDPOINT,
  EULA_LINK,
  DP_ENDPOINT,
  API_ENDPOINT_V3,
  NEW_PLATFORM_WEBSITE_URL,
  NEW_PLATFORM_WEBAPP_URL,
  DISABLE_SENSORS_SETTINGS
};
