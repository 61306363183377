import Vuex from "vuex";
import Vue from "vue";
import densProb1 from "./modules/densProb1";
import densProb2 from "./modules/densProb2";
import anomaly from "./modules/anomaly";
import structure from "./modules/structure";
import FDD from "./modules/FDD";
import trend from "./modules/trend";
import ppv from "./modules/ppv";
import auth from "./modules/auth";
import user from "./modules/user";
import eventdetails from "./modules/eventdetails";
import lastevents from "./modules/lastevents";
import rails from "./modules/rails";
import railsV2 from "./modules/railsV2";
import admin from "./modules/admin";
import group from "./modules/group"
import overlays from "./modules/overlays"
import settings from "./modules/settings"

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    densProb1,
    densProb2,
    anomaly,
    structure,
    FDD,
    lastevents,
    trend,
    ppv,
    auth,
    user,
    settings,
    eventdetails,
    rails,
    admin,
    group,
    railsV2,
    overlays
  },
});
