import api from "../../services/api";
const namespaced = true;

const state = {
  sensorsPool: [], // Pool di sensori su cui chiamare le API
  checkedSensors: [], // Elenco di sensori selezionati
  dateRange: [], // Date Range selezionato
  stats: [], // Elenco di oggetti trend ricevuti dalle API
  filteredStats: [], // Elenco di oggetti trend dopo filtraggio per Sensori
  APILoaded: false, // Stato di caricamento
};

const getters = {
  getDateRange: (state) => state.dateRange,
  getStats: (state) => state.filteredStats,
  getTotalStats: (state) => state.stats,
  getSensorPool: (state) => state.sensorsPool,
  getLoadingStatus: (state) => state.APILoaded,
  getCheckedSensors: (state) => state.checkedSensors,
};

const actions = {
  /* Setta il pool di sensori su cui chiamare le API */
  async setSensorsPool({ commit }, sensors) {
    commit("setSensorsPool", sensors);
  },
  /* Setta il Date Range */
  async setDateRange({ commit }, values) {
    let firstDate = new Date(values[0]);
    let lastDate = new Date(values[1]);
    commit("setDateRange", [firstDate, lastDate]);
  },
  /* Filtra per elenco di sensori */
  async filterBySensor({ commit, state }, sensors) {
    commit("setLoadingStatus", false);
    commit("setCheckedSensors", sensors);
    let tempStats = [];
    for (let selSensor of sensors) {
      for (let globalTrace of state.stats) {
        if (globalTrace.length > 0) {
          // Controllo che ci sia almeno un elemento per il dato sensore
          if (globalTrace[0].eui == selSensor.eui) {
            tempStats.push(globalTrace);
          }
        }
      }
    }
    commit("setFilteredStats", tempStats);
    commit("setLoadingStatus", true);
  },
  /* API Call */
  async fetchTrendAPI({ commit, state }) {
    commit("setLoadingStatus", false);
    commit("setCheckedSensors", state.sensorsPool);
    let euis = state.sensorsPool.map((item) => item && item.eui);
    let trends = await api.getMultipleTrends(
      euis,
      state.dateRange[0],
      state.dateRange[1]
    );
    commit("setStats", trends);
    commit("setFilteredStats", trends);
    commit("setLoadingStatus", true);
  },
};

const mutations = {
  setDateRange: (state, values) => (state.dateRange = values),
  setStats: (state, stats) => (state.stats = stats),
  setFilteredStats: (state, stats) => (state.filteredStats = stats),
  setSensorsPool: (state, sensors) => (state.sensorsPool = sensors),
  setLoadingStatus: (state, APILoaded) => (state.APILoaded = APILoaded),
  setCheckedSensors: (state, sensors) => (state.checkedSensors = sensors),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
