<template>
    <div class="main-container" v-bind="$attrs" v-on="$listeners">
      <div class="heat-bar"></div>
      <div class="indicator d-flex justify-content-center align-items-center" :style="{left: positionLeft}">
        <slot name="indicator"/>
      </div>
      <slot/>
    </div>
</template>

<script>
export default {
  name: "MoveProgressBar",
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    positionLeft() {
      const p = this.value;
      return `calc(${p}% - 32px)`;
    }
  }
};
</script>

<style scoped>
.main-container {
  width: 100%;
  position: relative;
}

.heat-bar {
  background: linear-gradient(90deg, #82defa 0%, #ff5050 100%);
  border-radius: 8px;
  position: relative;
  top:0px;
  height: 24px;
}


.indicator{
  width: 64px;
  height: 64px;
  top: -32px;
  position:absolute;
}

</style>
