<template>
  <div v-if="active"  class="m-wrapper">
    <div class="m-container">
      <!-- Wrapper -->
      <div class="m-content">
        <!-- content -->
        <div class="content">
          <move-text> <font-awesome-icon size="3x" icon="fa-solid fa-spinner" spin /> </move-text>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MoveModal",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>

.m-wrapper{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
}
.m-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  position: fixed
}

.content {
  width: 100%;
}

</style>
