import api from "../../services/api";
const namespaced = true;

const state = {
  sensorsPool: null, // Pool di sensori su cui chiamare le API
  checkedSensors: [], // Elenco di sensori selezionati
  dateRange: [], // Date Range selezionato
  stats: null, // Elenco di oggetti trend ricevuti dalle API
  filteredStats: null, // Elenco di oggetti trend dopo filtraggio per Sensori
  APILoaded: false, // Stato di caricamento
};

const getters = {
  getDateRange: (state) => state.dateRange,
  //getStats: (state) => state.filteredStats,
  getTotalStats: (state) => state.stats,
  getSensorPool: (state) => state.sensorsPool,
  getLoadingStatus: (state) => state.APILoaded,
  getCheckedSensors: (state) => state.checkedSensors,
};

const actions = {
  /* Setta il pool di sensori su cui chiamare le API */
  async setSensorsPool({ commit }, sensors) {
    commit("setSensorsPool", [sensors]);
  },
  /* Setta il Date Range */
  async setDateRange({ commit }, values) {
    let firstDate = new Date(values[0]);
    let lastDate = new Date(values[1]);
    commit("setDateRange", [firstDate, lastDate]);
  },
  /* API Call */
  async fetchPPV_API({ commit, state, rootGetters }) {
    commit("setLoadingStatus", false);
    commit("setCheckedSensors", state.sensorsPool || []);
    const userInterface = rootGetters['user/getInterface'];
    const velocity = userInterface && userInterface.dimensions && userInterface.dimensions.velocity 
      ? userInterface.dimensions.velocity 
      : 'mm/s';
    if (state.sensorsPool) {
      let euis = state.sensorsPool.map((item) => item && item.eui);
      let ppvs = await Promise.all(euis.map((eui) => api.getPPVs(eui, state.dateRange[0], state.dateRange[1], false, velocity )));
      //commit("setFilteredStats", ppvs[0]);
      commit("setStats", ppvs[0]);
    }
    commit("setLoadingStatus", true);
  },
};

const mutations = {
  setDateRange: (state, values) => (state.dateRange = values),
  setStats: (state, stats) => (state.stats = stats),
  //setFilteredStats: (state, stats) => (state.filteredStats = stats),
  setSensorsPool: (state, sensors) => (state.sensorsPool = sensors),
  setLoadingStatus: (state, APILoaded) => (state.APILoaded = APILoaded),
  setCheckedSensors: (state, sensors) => (state.checkedSensors = sensors),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
