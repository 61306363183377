import axios from "axios";
import config from "../../config";

const namespaced = true;

const state = {
  structureList: [],
  selectedStructure: null,
  selectedSensor: null,
  dateRange: [],
  diffPeaks: [],
  loaded: false,
};

const getters = {
  getDateRange: (state) => state.dateRange,
  getDiffPeaks: (state) => state.diffPeaks,
  getSelectedSensor: (state) => state.selectedSensor,
  getLoadingStatus: (state) => state.loaded,
};

const actions = {
  async setSelectedSensor({ commit }, sensor) {
    commit("setSelectedSensor", sensor);
  },
  async setInitialSensor({ commit, rootGetters }) {
    let sensors = rootGetters["structure/getSensors"];
    commit("setSelectedSensor", sensors[1]);
  },
  async setDateRange({ commit }, values) {
    let firstDate = new Date(values[0]);
    let lastDate = new Date(values[1]);

    commit("setDateRange", [firstDate, lastDate]);
  },
  async fetchDiffPeaks({ commit, state }) {
    commit("setLoadingStatus", false);
    let response;
    let trace = [];
    if (state.selectedSensor && state.selectedSensor.type === "deck") {
      const workID = await axios.post(config.DECKTASK_ENDPOINT + "displacementdensity", {
        eui: state.selectedSensor.eui,
        firstDate: new Date(state.dateRange[0]),
        lastDate: new Date(state.dateRange[1]),
      });
      console.log("My WorkID", workID.data.taskID);
      const pollingInterval = setInterval(async function() {
        try {
          const pollingResponse = await axios.get(config.DECKTASK_ENDPOINT + "displacementdensity/" + workID.data.taskID);
          if (pollingResponse.status === 204) {
            console.log("Work not done");
          } else {
            const myTrace = pollingResponse.data.peaksList;
            console.log("Work Done", pollingResponse);
            clearInterval(pollingInterval);
            commit("setDiffPeaks", myTrace);
            commit("setLoadingStatus", true);
          }
        } catch (err) {
          console.log("Error", err);
          clearInterval(pollingInterval);
        }
      }, 5000);
    } else if (state.selectedSensor && state.selectedSensor.type === "accelerometer") {
      response = await axios.get(config.API_ENDPOINT + "sensor_data/accelerations", {
        params: {
          eui: state.selectedSensor.eui,
          firstDate: new Date(state.dateRange[0]),
          lastDate: new Date(state.dateRange[1]),
        },
      });
      response.data.forEach((element) => {
        trace.push(element.acceleration.xPeak);
      });
      commit("setDiffPeaks", trace);
      commit("setLoadingStatus", true);
    }
  },
};

const mutations = {
  setDateRange: (state, values) => (state.dateRange = values),
  setDiffPeaks: (state, diffPeaks) => (state.diffPeaks = diffPeaks),
  setSelectedSensor: (state, sensor) => (state.selectedSensor = sensor),
  setLoadingStatus: (state, status) => (state.loaded = status),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
