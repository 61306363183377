<template>
  <div class="checkbox-container">
    <label class="container">
      <input type="radio" @click="onClick" :checked="option === value"/>
      <span class="checkmark"></span>
    </label>
    <label class="value">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "MoveRadioButton",
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    option: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },
  methods: {
    onClick(){
      this.$emit('input', this.option)
    }
  }
};
</script>

<style scoped>
.checkbox-container{
  display: grid;
  padding-left:8px;
  padding-right: 8px;
  justify-items: center;
}
.container{
  position: relative;
  display: contents;
  cursor: pointer;
  font-size: 22px;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}


.container input {
  position: absolute;
  top: 50vh;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
  left: 0;
  top: 0;
  height: 14px;
  position: relative;
  width: 14px;
  border-radius: 50%;
  border: solid 3px white;
  z-index: 9;
}
.checkmark.disabled {
  border: solid 3px gray;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: rgb(80, 84, 105);
}
/* When the radio button is checked, add a blue background */
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
label.disabled {
  color: gray;
}
.container .checkmark.disabled:after {
  background: gray;
}

</style>
