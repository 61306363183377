import api from "../../services/api";

const namespaced = true;

const state = {
  FDDList: [],
  selectedGroupID: null,
  freqClusterDateRange: [],
  selectedAxis: null,
  FDDDateRange: [],
  // Asse dell'fdd
};

const getters = {
  getFDD: (state) => state.FDDList,
  getSelectedGroupID: (state) => state.selectedGroupID,
  getFreqClusterDateRange: (state) => state.freqClusterDateRange,
  getFDDDateRange: (state) => state.FDDDateRange,
  getSelectedAxis: (state) => state.selectedAxis,
};

const actions = {
  async fetchFDD({ commit, rootGetters }) {
    let selStructure = rootGetters["structure/getSelectedStructure"];
    if (selStructure) {
      let fromFDD = await api.getFDDList(selStructure._id, 0, state.freqClusterDateRange[0], state.freqClusterDateRange[1]);
      commit("setFDD", fromFDD);
    }
  },
  async setSelectedGroupID({ commit }, group) {
    commit("selectGroupID", group);
  },
  async setFreqClusterDateRange({ commit }, range) {
    let firstDate = new Date(range[0]);
    let lastDate = new Date(range[1]);

    commit("setFreqClusterDateRange", [firstDate, lastDate]);
  },
  async setFreqFDDDateRange({ commit }, range) {
    let firstDate = new Date(range[0]);
    let lastDate = new Date(range[1]);

    commit("setDateRansetFreqFDDDateRangege", [firstDate, lastDate]);
  },
  setSelectedAxis({ commit }, axis) {
    commit("selectAxis", axis);
  },
};

const mutations = {
  setFDD: (state, FDD) => (state.FDDList = FDD),
  selectGroupID: (state, group) => (state.selectedGroupID = group),
  setFreqClusterDateRange: (state, range) => (state.freqClusterDateRange = range),
  setFreqFDDDateRange: (state, range) => (state.FDDDateRange = range),
  selectAxis: (state, axis) => (state.selectedAxis = axis),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
