<template>
  <move-modal >
    <!-- HEADER -->
    <!-- ------------------------------------------------------------------------- -->
    <template #header>
      <move-text h2 class="mb32" :style="{width: width}">{{ title }}</move-text>
    </template>

    <!-- CONTENT -->
    <!-- ------------------------------------------------------------------------- -->
    <div class="mb32" :style="{width: width}">
      <move-text p>{{ msg }}</move-text>
    </div>

    <!-- FOOTER -->
    <!-- ------------------------------------------------------------------------- -->
    <template #footer>
      <div class="text-align-right" :style="{width: width}">
        <!-- Button confirm -->
        <move-btn primary rounded outlined @click="$emit('confirm')">
          <move-text p bold>{{ btnText }}</move-text>
        </move-btn>
      </div>
    </template>
  </move-modal>
</template>
<script>
/**************************************************************** ****************************************************************/
/* COMPONENT */
/**************************************************************** ****************************************************************/
export default {
  name: "modal-delete",

  /**************************************************************** ****************************************************************/
  /* PROPS */
  /**************************************************************** ****************************************************************/
  props: {
    title: {
      type: String,
      default: "",
    },
    msg: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "Ok",
    },
    width: {
      type: String,
      default: "auto"
    },
  },
};
</script>
<style scoped>
</style>
