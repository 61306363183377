<template>
  <div class="s-main-container">
    <v-select class="t-select" v-bind="$attrs" v-on="$listeners" :options="options" />
  </div>
</template>

<script>
export default {
  name: "MoveSelectMultiple",
  props: {
    options: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.s-main-container {
  background: var(--secondary-color-dark);
  color: var(--input-placeholder-color);
  border-radius: 4px;
  padding: 2px 12px;
  min-height: 40px;
  height: 100%;
}

.t-select {
  width: 100%;
  height: 100%;
}
.t-select >>> .vs__clear {
  fill: var(--text-color);
}
.t-select >>> .vs__open-indicator {
  fill: var(--text-color);
}

.t-select >>> .vs__clear {
  visibility: hidden;
  display: none;
}

.t-select >>> .vs__search::placeholder {
  padding: 0px !important;
  /* background: var(--secondary-color-dark); */
  color: var(--input-placeholder-color) !important;
}
.t-select >>> .vs__dropdown-toggle {
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
  border: none;
  height: 100%;
}
.t-select >>> .vs__dropdown-menu {
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
}

.t-select >>> .vs__dropdown-menu >>> .vs__dropdown-option--highlight {
  /* background: #ccc !important; */
  background: var(--secondary-color-light-2);
  color: var(--text-color) !important;
}

.t-select >>> .vs__selected {
  background: var(--text-color);
  color: var(--secondary-color-dark) !important;
  border: 1px solid #ccc;
  border-radius: 16px;
  background-color: #ccc;
  /* margin: 0px !important;
  margin-left: 2px 
  margin-top: 4px !important; */
  padding: 4px 8px !important;
  font-weight: 600;
}  
.t-select >>> .vs__deselect {
  fill: var(--text-color);
  background-color: var(--secondary-color-light);
  padding: 6px;
  border-radius: 50%;
}

.t-select >>> .vs__dropdown-option:hover {
  background: var(--secondary-color-light);
  color: var(--text-color) !important;
}

.t-select >>> .vs__dropdown-option {
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
}

.t-select >>> .vs__search {
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
  outline: none !important;
  border: 1px solid transparent !important;
  border-radius: 4px;
  padding: 0px !important;
}

.t-select >>> .vs__dropdown-menu {
  width: 100% !important;
}
.t-select >>> .vs__dropdown-toggle {
  width: 100% !important;
}

.t-select >>> .vs--open {
  width: 100% !important;
}

.t-select >>> ul::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Track */
.t-select >>> ul::-webkit-scrollbar-track {
  border-radius: 0px;
}
 
/* Handle */
.t-select >>> ul::-webkit-scrollbar-thumb {
  background: var(--secondary-color-light); 
  border-radius: 0px;
}

.t-select >>> ul >>> li:hover{
  background-color: #ccc;
}

</style>
