const namespaced = true;
import api from "../../services/api"
import math from "../../helpers/math"
import {round} from "lodash"
const DEGREES = 'degrees';
const MM_M = 'mm/m';
const TILTMETER = 'tiltmeter';

const randomColor = require('randomcolor'); // import the script

function getPhiAngle(mesUnit = DEGREES, value){
  const phiAngle = mesUnit === MM_M ? math.degreesToMM(value) : value;
  return round(phiAngle, 6)
} 

function getThetaAngle(mesUnit = DEGREES, value){
  const thetaAngle = mesUnit === MM_M ? math.degreesToMM(value) : value;
  return round(thetaAngle, 6)
} 

const state = {
  dateRange: [new Date(new Date().getTime() - 1000*60*60*24*3), new Date() ],
  tiltData: {
    phiAngle: {
      traces: []
    },
    thetaAngle: {
      traces: []
    }
  },
  loading: false,
  maxDateRange: 1000*60*60*24*365,
  maxSensorsSelected: 20,
};


const getters = {
  /****************************************************************/
  /* ROOT GETTERS */
  /****************************************************************/
  getStructureID: (_state, _getters, _rootState, rootGetters) => {
    const structure = rootGetters['structure/getSelectedStructure']
    return structure && structure._id ? structure._id : null;
  },
  getTiltmeters(_state, _getters, _rootState, rootGetters){
    return rootGetters['structure/getSensorsDetails']
      .filter((sensor) => {
        return sensor.type === TILTMETER
      })
  },
  getMesUnit(_state, _getters, _rootState, rootGetters){
    const userInterface = rootGetters['user/getInterface'];
    return userInterface && userInterface.dimensions ? userInterface.dimensions : null
  },

  /****************************************************************/
  /* STATE GETTERS */
  /****************************************************************/
  getLoading(state){
    return state.loading;
  },
  getDateRange(state){
    return state.dateRange
  },
  getTiltData(state){
    return state.tiltData;
  },
  getTiltsUnit(_state, getters){
    return getters.getMesUnit && getters.getMesUnit.degrees && getters.getMesUnit.degrees === MM_M ? MM_M : DEGREES
  },
  getMaxDateRange(state){
    return state.maxDateRange
  },
  getMaxSensorsSelected(state){
    return state.maxSensorsSelected
  }

};
const actions = {
  setDateRange({commit}, value){
    commit('setDateRange',value)
  },
  setLoading({commit}, value){
    commit('setLoading', value)
  },
  async fetchTiltData({commit, getters} ,payload = { tiltmeters: [], dateRange: [null, null] }){
    const tiltData = {
      phiAngle: {
        traces: []
      },
      thetaAngle: {
        traces: []
      }
    };
    for (const eui of payload.tiltmeters) {
      try {
        const response = await api.getTiltsData({url: {eui}, params: {firstDate: payload.dateRange[0], lastDate: payload.dateRange[1]} })
        const color = randomColor();
        const phiAngleTrace = {
          eui,
          color,
          x: response.data.values.map(v => new Date(v.timestamp || v.date)),
          y: response.data.values.map(v => getPhiAngle(getters.getTiltsUnit, v.phiAngle)),
        }
        const thetaAngleTrace = {
          eui,
          color,
          x: response.data.values.map(v => new Date(v.timestamp || v.date)),
          y: response.data.values.map(v => getThetaAngle(getters.getTiltsUnit, v.thetaAngle))
        }
        tiltData.phiAngle.traces.push(phiAngleTrace)
        tiltData.thetaAngle.traces.push(thetaAngleTrace)
        commit('setTiltData', tiltData)
      } catch (e) {
        console.warn(`API FETCH TILTS DATA (${e.code}) ${e.message}`)
      }
    }
  }

};
const mutations = {
  setDateRange: (state, value) => (state.dateRange = value),
  setTiltData: (state, value) => (state.tiltData = value),
  setLoading: (state, value) => (state.loading = value)
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};