import axios from "axios";
import config from "../../config";

const namespaced = true;

const state = {
  structureList: [],
  selectedStructure: null,
  selectedSensor: null,
  dateRange: [],
  peaks: {},
  traces: {},
};

const getters = {
  getDateRange: (state) => state.dateRange,
  getDiffPeaks: (state) => state.diffPeaks,
  getSelectedSensor: (state) => state.selectedSensor,
  getTraces: (state) => state.traces,
};

const actions = {
  async setSelectedSensor({ commit }, sensor) {
    commit("setSelectedSensor", sensor);
  },
  async setInitialSensor({ commit, rootGetters }) {
    let sensors = rootGetters["structure/getSensors"];
    commit("setSelectedSensor", sensors[1]);
  },
  async setDateRange({ commit }, values) {
    let firstDate = new Date(values[0]);
    let lastDate = new Date(values[1]);

    commit("setDateRange", [firstDate, lastDate]);
  },
  async getAnomalyTraces({ commit, state, rootGetters }) {
    let sensor1 = rootGetters["densProb1/getSelectedSensor"];
    let sensor2 = rootGetters["densProb2/getSelectedSensor"];
    if (sensor1 && sensor2) {
      const Peaks1 = await axios.get(
        config.API_ENDPOINT + "sensor_data/vibrations",
        {
          params: {
            eui: sensor1.eui,
            firstDate: new Date(state.dateRange[0]),
            lastDate: new Date(state.dateRange[1]),
          },
        }
      );

      const Peaks2 = await axios.get(
        config.API_ENDPOINT + "sensor_data/vibrations",
        {
          params: {
            eui: sensor2.eui,
            firstDate: new Date(state.dateRange[0]),
            lastDate: new Date(state.dateRange[1]),
          },
        }
      );

      let diffPeaks1 = [];
      let diffPeaks2 = [];
      let ids1 = [];
      let ids2 = [];

      Peaks1.data.forEach((element) => {
        diffPeaks1.push(element.payload.piccoMax - element.payload.piccoMin);
        ids1.push(element._id);
      });

      Peaks2.data.forEach((element) => {
        diffPeaks2.push(element.payload.piccoMax - element.payload.piccoMin);
        ids2.push(element._id);
      });

      commit("setPeaks", [diffPeaks1, diffPeaks2]);

      const minArrayLen = Math.min(diffPeaks1.length, diffPeaks2.length);
      const minDiffPeaks1 = diffPeaks1.slice(0, minArrayLen);
      const minDiffPeaks2 = diffPeaks2.slice(0, minArrayLen);

      const jsonPost = {
        diffPeaks: [
          {
            eui: sensor1.eui,
            values: minDiffPeaks1,
            ids: ids1,
          },
          {
            eui: sensor2.eui,
            values: minDiffPeaks2,
            ids: ids2,
          },
        ],
      };

      const bountyResponse = await axios.post(
        config.DECKPOST_ENDPOINT + "hunter/vibration",
        jsonPost
      );

      commit("setTraces", bountyResponse.data);
    }
    //craft json
    //API req
    //Set Traces
  },
};

const mutations = {
  setDateRange: (state, values) => (state.dateRange = values),
  setPeaks: (state, peaks) => (state.peaks = peaks),
  setTraces: (state, traces) => (state.traces = traces),
  setSelectedSensor: (state, sensor) => (state.selectedSensor = sensor),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
