<template>
  <div class="m-container">
    <img
      class="pointer "
      src="../../../public/assets/icons/help.svg"
      alt="drag"
      @mouseover="visible = true"
      @mouseleave="visible = false"
    />
    <div :style="{ position: 'absolute', width: width }" :class="`arrow-${arrow}`">
      <transition name="fade">
        <div
          :style="{width: `calc(${width} - 90px)`}"
          v-if="visible"
          :class="'triangle-border ' + arrow"
          v-click-outside="onClickOutside"
        >
          <!-- Intestazione -->
          <slot name="header"></slot>
          <!-- Contenuto -->
          <slot name="content"></slot>
          <!-- Laterale -->
          <slot name="side"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
/* 
      arrowDirection: top | bottom | right | left
  */
export default {
  name: "move-ballon",
  props: {
    arrow: {
      type: String,
      default: "left",
    },
    blackBackground: Boolean,
    width: {
      type: String,
      default: "200px"
    }
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>

.arrow-right{
  right: 24px;
}
.arrow-left{
  margin-left: 16px;
}

.arrow-top{
  top: 24px;
}


.arrow-bottom{
  bottom: 24px;
}



#black-back {
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  z-index: 23;
}
/* THE SPEECH BUBBLE
  ------------------------------------------------------------------------------------------------------------------------------- */

.triangle-border {
  position: absolute;
  z-index: 24;
  padding: 10px 15px;
  margin: 1em 0 3em;
  border: 2px solid white;
  color: #333;
  background: rgb(40, 42, 58);
  /* css3 */
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: -42px;
  color: white;
}

/* Variant : for left positioned triangle
  ------------------------------------------ */

.triangle-border.left {
  margin-left: 30px;
}

/* Variant : for right positioned triangle
  ------------------------------------------ */

.triangle-border.right {
  margin-right: 30px;
}

/* THE TRIANGLE
  ------------------------------------------------------------------------------------------------------------------------------- */

.triangle-border:before {
  content: "";
  position: absolute;
  bottom: -20px; /* value = - border-top-width - border-bottom-width */
  left: 40px; /* controls horizontal position */
  border-width: 0;
  border-style: solid;
  border-color: white transparent;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
}

/* creates the smaller  triangle */
.triangle-border:after {
  content: "";
  position: absolute;
  bottom: -16px; /* value = - border-top-width - border-bottom-width */
  left: 44px; /* value = (:before left) + (:before border-left) - (:after border-left) */
  border-width: 0;
  border-style: solid;
  border-color: rgb(40, 42, 58) transparent;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
}

/* Variant : bottom
  ------------------------------------------ */

/* creates the larger triangle */
.triangle-border.bottom:before {
  bottom: -20px; /* value = - border-top-width - border-bottom-width */
  left: 40px; /* controls horizontal position */
  border-width: 20px 20px 0;
}

/* creates the smaller  triangle */
.triangle-border.bottom:after {
  bottom: -16px; /* value = - border-top-width - border-bottom-width */
  left: 44px; /* value = (:before left) + (:before border-left) - (:after border-left) */
  border-width: 16px 16px 0;
}

/* Variant : top
  ------------------------------------------ */

/* creates the larger triangle */
.triangle-border.top:before {
  top: -20px; /* value = - border-top-width - border-bottom-width */
  bottom: auto;
  left: auto;
  right: 44px; /* controls horizontal position */
  border-width: 0 20px 20px;
}

/* creates the smaller  triangle */
.triangle-border.top:after {
  top: -16px; /* value = - border-top-width - border-bottom-width */
  bottom: auto;
  left: auto;
  right: 48px; /* value = (:before right) + (:before border-right) - (:after border-right) */
  border-width: 0 16px 16px;
}

/* Variant : left
  ------------------------------------------ */

/* creates the larger triangle */
.triangle-border.left:before {
  top: 13px; /* controls vertical position */
  bottom: auto;
  left: -25px; /* value = - border-left-width - border-right-width */
  border-width: 12px 25px 12px 0;
  border-color: transparent white;
}

/* creates the smaller  triangle */
.triangle-border.left:after {
  top: 16px; /* value = (:before top) + (:before border-top) - (:after border-top) */
  bottom: auto;
  left: -21px; /* value = - border-left-width - border-right-width */
  border-width: 9px 21px 9px 0;
  border-color: transparent rgb(40, 42, 58);
}

/* Variant : right
  ------------------------------------------ */

/* creates the larger triangle */
.triangle-border.right:before {
  top: 13px; /* controls vertical position */
  bottom: auto;
  left: auto;
  right: -25px; /* value = - border-left-width - border-right-width */
  border-width: 12px 0 12px 25px;
  border-color: transparent white;
}

/* creates the smaller  triangle */
.triangle-border.right:after {
  top: 16px; /* value = (:before top) + (:before border-top) - (:after border-top) */
  bottom: auto;
  left: auto;
  right: -21px; /* value = - border-left-width - border-right-width */
  border-width: 9px 0 9px 21px;
  border-color: transparent rgb(40, 42, 58);
}
* {
  color: white;
  text-align: left;
  vertical-align: sub;
}
</style>
