<template>
  <div
    class="m-container"
    :style="{ borderBottom: this.lineStyle }"
  ></div>
</template>

<script>
export default {
  name: "MoveHorizontalDivider",
  props: {
    lineStyle: {
      type: String,
      default: "2px solid var(--secondary-border-color)",
    },
  },
};
</script>

<style scoped>

</style>
