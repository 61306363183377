import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VMdDateRangePicker from "v-md-date-range-picker";
import VueVideoPlayer from "vue-video-player";
// require videojs style
import "video.js/dist/video-js.css";
import VCalendar from "v-calendar";
import store from "./store";
import interceptors from "./services/interceptors";
import { i18n } from "./plugins/i18n";
import vClickOutside from "v-click-outside";
import vSelect from "vue-select";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import Popover from 'vue-js-popover'
import OpenIndicator from "./components/ui/OpenIndicator.vue";

// Componenti UI globali
import MoveHeatBar from "./components/ui_V2/MoveHeatBar.vue"
import MoveInfo from "./components/ui_V2/MoveInfo.vue"
import MoveBanner from "./components/ui_V2/MoveBanner"
import MoveRadioButton from "./components/ui_V2/MoveRadioButton"
import MoveBallon from "./components/ui_V2/MoveBallon"
import MoveSortActions from "./components/ui_V2/MoveSortActions"
import MoveMenuBtn from "./components/ui_V2/MoveMenuBtn.vue"
import MoveSpinner from "./components/ui_V2/MoveSpinner.vue"
import MoveTable from "./components/ui_V2/MoveTable.vue"
import MoveHorizontalDivider from "./components/ui_V2/MoveHorizontalDivider.vue"
import MoveGraph from "./components/ui_V2/MoveGraph.vue"
import MoveLed from "./components/ui_V2/MoveLed.vue"
import MoveConfirmModal from "./components/ui_V2/MoveConfirmModal.vue"
import MoveModal from "./components/ui_V2/MoveModal.vue"
import MoveTabs from "./components/ui_V2/MoveTabs.vue";
import MoveStep from "./components/ui_V2/MoveStep.vue";
import MoveBtn from "./components/ui_V2/MoveBtn.vue";
import MoveSwitch from "./components/ui_V2/MoveSwitch.vue";
import MoveVerticalDivider from "./components/ui_V2/MoveVerticalDivider.vue";
import MoveCircleChip from "./components/ui_V2/MoveCircleChip.vue";
import MoveListGroup from "./components/ui_V2/MoveListGroup.vue";
import MoveListItem from "./components/ui_V2/MoveListItem.vue";
import MoveSelect from "./components/ui_V2/MoveSelect.vue";
import MoveSelectMultiple from "./components/ui_V2/MoveSelectMultiple.vue";
import MovePopUp from "./components/ui_V2/MovePopUp.vue";
import MoveInput from "./components/ui_V2/MoveInput.vue";
import MoveInputNumber from "./components/ui_V2/MoveInputNumber.vue";
import MoveText from "./components/ui_V2/MoveText.vue";
import MoveTextLink from "./components/ui_V2/MoveTextLink";
import MoveCard from "./components/ui_V2/MoveCard.vue";
import MoveCheckbox from "./components/ui_V2/MoveCheckbox.vue";
import MoveCheckboxV2 from "./components/ui_V2/MoveCheckboxV2.vue";
import MoveDatePicker from "./components/ui_V2/MoveDatePicker.vue";
import MoveProgressBar from "./components/ui_V2/MoveProgressBar.vue";
import MoveBtnItem from "./components/ui_V2/MoveBtnItem.vue";
import MoveBtnList from "./components/ui_V2/MoveBtnList.vue";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faFileArrowDown, faAngleRight, faAngleLeft, faCalendar, faEllipsis, faEllipsisVertical, faPen, faXmark, faTrash, faMagnifyingGlass, faArrowRight, faArrowDown, faChevronRight, faPlus, faSquarePlus, faAngleDown, faAngleUp, faGear, faSpinner, faCircleNotch, faArrowLeft, faArrowUp, faArrowsLeftRightToLine, faCircle, faCheck, faPause, faCircleInfo, faCircleQuestion, faLocationPin, faUpRightFromSquare,faWarning} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.use(Popover)

Vue.component('MoveHeatBar', MoveHeatBar)
Vue.component('MoveInfo', MoveInfo)
Vue.component('MoveRadioButton', MoveRadioButton)
Vue.component('MoveBanner', MoveBanner)
Vue.component('MoveBallon', MoveBallon)
Vue.component('MoveSortActions', MoveSortActions)
Vue.component('MoveMenuBtn', MoveMenuBtn)
Vue.component('MoveSpinner', MoveSpinner)
Vue.component('MoveTable', MoveTable)
Vue.component('MoveHorizontalDivider', MoveHorizontalDivider)
Vue.component('MoveGraph', MoveGraph)
Vue.component('MoveLed', MoveLed)
Vue.component('MoveConfirmModal', MoveConfirmModal)
Vue.component('MoveModal', MoveModal)
Vue.component('MoveTabs', MoveTabs)
Vue.component('MoveStep', MoveStep);
Vue.component('MoveBtn', MoveBtn);
Vue.component('MoveTextLink', MoveTextLink);
Vue.component('MoveSwitch', MoveSwitch);
Vue.component('MoveVerticalDivider', MoveVerticalDivider);
Vue.component('MoveCircleChip', MoveCircleChip);
Vue.component('MoveListGroup', MoveListGroup);
Vue.component('MoveListItem', MoveListItem);
Vue.component('MoveTable', MoveTable);
Vue.component('MovePopUp', MovePopUp);
Vue.component('MoveInput', MoveInput);
Vue.component('MoveInputNumber', MoveInputNumber);
Vue.component('MoveText', MoveText);
Vue.component('MoveCard', MoveCard);
Vue.component('MoveCheckbox', MoveCheckbox);
Vue.component('MoveCheckboxV2', MoveCheckboxV2);
Vue.component('MoveDatePicker', MoveDatePicker);
Vue.component('MoveProgressBar', MoveProgressBar);
Vue.component('MoveSelect', MoveSelect);
Vue.component('MoveSelectMultiple', MoveSelectMultiple);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('VueSlider', VueSlider);
Vue.component('MoveBtnItem', MoveBtnItem);
Vue.component('MoveBtnList', MoveBtnList);
library.add(faCoffee, faFileArrowDown, faAngleRight, faEllipsisVertical, faAngleLeft, faAngleDown, faAngleUp, faCalendar, faEllipsis, faPen, faXmark, faTrash, faMagnifyingGlass, faArrowRight, faArrowLeft, faArrowDown, faChevronRight, faPlus, faSquarePlus, faGear,faSpinner, faCircleNotch, faArrowUp, faArrowsLeftRightToLine, faCircle, faCheck, faPause, faCircleInfo, faCircleQuestion, faLocationPin, faUpRightFromSquare, faWarning)


// Redirect to new domain
/* if (window.location.hostname === "prodbetamove.herokuapp.com") {
  window.location.replace("https://platform.movesolutions.it");
} */

import ImageUploader from "vue-image-upload-resize";
Vue.use(ImageUploader);

interceptors.check500Error();
interceptors.checkExpiredToken();
interceptors.check401Error();
interceptors.globalErrorHandling();
interceptors.setBearerToken();

/* Vue.component(VueCropper); */
Vue.component("v-select", vSelect);
vSelect.props.components.default = () => ({ OpenIndicator });
Vue.use(vClickOutside);
Vue.use(VMdDateRangePicker);
Vue.use(VueVideoPlayer /* {
  options: global default options,
  events: global videojs events
} */);

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  locale: "nl-NL",
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(Popover)


Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");