<template>
  <div>
    <move-btn noWrap icon v-popover.left="{ name: id }" :disabled="disabled">
      <font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
    </move-btn>
    <move-pop-up :name="id" :ref="id">
      <div v-for="(opt, i) in options" :key="getOptionRef(i)">
        <move-btn
          block
          noWrap
          text
          class="text-align-left"
          @click="onClickOption(id, opt.event, value)"
        >
          <font-awesome-icon v-if="opt.icon" :icon="opt.icon" class="mr4" />
          {{ opt.label }}
        </move-btn>
      </div>
    </move-pop-up>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "MoveMenuBtn",
  props: {
    options: {
      type: Array,
      default:() =>  []
    },
    value: {
      type: null,
      default: null
    },
    disabled: {
      type: null,
      default: false
    }
  },
  data() {
    return {
      id:uuidv4(),
    };
  },
  methods: {
    getOptionRef(i = -1){
      return `${this.id}-${i}`
    },
    onClickOption(ref, event, value){
      this.$refs[ref].hide();
      this.$emit(event, value)
    }
  },
};
</script>

<style scoped>
</style>
