import api from "../../services/api";
import {checkIfOnline, getShortEui} from "../../helpers/sensors"
const namespaced = true;

const state = {
  /*********** STRUCTURE & SENSORS *****************/
  structures: [],
  //selectedStructure: null,
  selectedStructureID: localStorage.getItem("selectedStructureID"),
  structureDetails: null,
  sensors: [],
  sensorsDetails: [],
  fetchingSensorsDetailsID: null,
  period: 30000 //ms
};

const getters = {
  /*********** STRUCTURE & SENSORS *****************/
  getStructures: (state) => state.structures,
  getSelectedStructure: (state) => state.structures.find((st) => st._id && st._id === state.selectedStructureID) || null,
  getStructureDetails: (state) => state.structureDetails,
  getSensors: (state) => (state.structureDetails ? state.structureDetails.sensors : []),
  getSensorsDetails: (state) => (state.sensorsDetails),
  getGroups: (state) => (state.structureDetails ? state.structureDetails.groups : []),
};

const actions = {
  /*********** STRUCTURE & SENSORS *****************/
  // Fetch
  async fetchStructures({ commit }) {
    const structuresList = await api.getStructuresList();
    commit("setStructures", structuresList);
  },

  async fetchStructureData({dispatch }) {
    await dispatch('fetchSensorsDetails')
  },

 /*  async refreshSensorsStats({ commit }) {
    const sensorsFromApi = JSON.parse(JSON.stringify(await api.getSensorsSettings(state.selectedStructureID)));
    commit("REFRESH_SENSOR_STATUS", sensorsFromApi);
  }, */

  // Select
  async selectStructure({ commit }, structure) {
    commit("setSelectedStructure", structure);
  },
  async fetchSensorsDetails({state, commit}){
    if (state.structures.length > 0 && state.selectedStructureID) {
      let structureDetails = await api.getStructureDetails(state.selectedStructureID);
      const sensors = structureDetails.sensors
      structureDetails.sensors = sensors
      const sensorsDetails = sensors.map(sensor => {
        sensor.online = checkIfOnline(sensor);
        sensor.shortEui = getShortEui(sensor.eui);
        return sensor;
      })
      localStorage.setItem('railEnabled',structureDetails.railEnabled )
      localStorage.setItem('overlaysEnabled',structureDetails.overlaysEnabled )
      commit("setStructureDetails", structureDetails);
      commit("setSensorsDetails", sensorsDetails);
    }
  },
  fetchSensorsDetailsPeriodically({state, commit, dispatch}){
    if (state.fetchingSensorsDetailsID) {
      return;
    }
    const fetchingSensorsDetailsID = setInterval(async () => {
      console.log('EXEC PERIODIC ROUTINE > fetch sensors and structure details')
      await dispatch('fetchSensorsDetails');
    }, state.period)
    commit('setFetchingSensorsDetailsID', fetchingSensorsDetailsID)
  }
};

const mutations = {
  /*********** STRUCTURE & SENSORS *****************/
  setStructures: (state, structures) => (state.structures = structures),
  setStructureDetails: (state, details) => (state.structureDetails = details),
  setSelectedStructure: (state, structure) => {
    if (structure && structure._id) {
      localStorage.setItem("selectedStructureID", structure._id);
      state.selectedStructureID = structure._id;
    }
  },
  REFRESH_SENSOR_STATUS: (state, sensors) => (state.structureDetails.sensors = sensors),
  setFetchingSensorsDetailsID: (state, value) => (state.fetchingSensorsDetailsID = value),
  setSensorsDetails: (state, sensors) => (state.sensorsDetails = sensors),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
