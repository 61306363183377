<template>
  <div
    v-bind="$attrs"
    v-on="$listeners"
    :class="[
      this.noWrap ? 'noWrap' : '',
      this.bold || this.b ? 'bold' : '',
      this.paragraph || this.p ? 'paragraph' : '',
      this.caption ? 'caption' : '',
      this.h2 ? 'h2' : '',
      this.h3 ? 'h3' : '',
      this.monospace ? 'monospace' : '',
      'text',
    ]"
    :style="{ color: getColor }"

  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "MoveText",
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "var(--text-color-primary)",
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    b: {
      type: Boolean,
      default: false,
    },
    monospace: {
      type: Boolean,
      default: false,
    },
    paragraph: {
      type: Boolean,
      default: false,
    },
    p: {
      type: Boolean,
      default: false,
    },
    caption: {
      type: Boolean,
      default: false,
    },
    h2: {
      type: Boolean,
      default: false,
    },
    h3: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getColor() {
      if (this.primary) return "var(--text-color-primary)";
      if (this.secondary) return "var(--text-secondary-color)";
      return this.color;
    },
  },
};
</script>

<style scoped>
.noWrap {
  white-space: nowrap;
}
.paragraph {
  font-size: 1rem;
}
.caption{
  font-size: 0.8rem;
  font-weight: 400;
}
.bold {
  font-weight: 600;
}
.h2 {
  font-size: 1.4rem;
}
.h3 {
  font-size: 1.2rem;
}
.monospace {
  font-family: var(--font-family-monospace);
}
</style>
