<template>
  <div class="main-container" :style="{borderLeft: this.lineStyle }">
  </div>
</template>

<script>
export default {
    name: "MoveVerticalDivider",
    props: {
        lineStyle:{
            type: String,
            default: "1px solid #fff"
        }
    }
}
</script>

<style>
</style>