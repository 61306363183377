<template>
  <div
    class='item'
    :style="{ backgroundColor: getBackground }"
    @click="$emit('input', item)"
  >
    <slot />
  </div>
</template>

<script>
import _ from "lodash"
export default {
  name: "MoveListItem",
  props: ['item', 'value'],
  computed: {
    getBackground() {
      return this.item && _.isEqual(this.item, this.value) ? "var(--primary-color)" : "var(--secondary-color-dark)";
    }
  }
};
</script>

<style scoped>
.item:hover {
  cursor: pointer;
}
.item {
  border-radius: 4px;
  padding: 4px 12px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
}
</style>
