<template>
  <vc-date-picker
    v-bind="$attrs"
    v-on="$listeners"
    class="date-picker"
    :color="actualColor"
    :errorColor="errorColor"
    :input-debounce="inputDebounce"
    :locale="this.$root.$i18n.locale"
    v-model="range"
    :is-dark="isDark"
    :is-range="isRange"
    :is-inline="true"
    :rows="1"
    :max-date="new Date()"
    :min-date="minDate"
    :mode="mode"
    :is24hr="is24hr"
    :is-expanded="isExpanded"
  />
</template>

<script>

export default {
  name: "MoveDatePicker",
  props: {
    dateRange: Array,
    minDate: { type: Date, default: null },
    isDark: {
      type: Boolean,
      default: true
    },
    isRange: {
      type: Boolean,
      default: true
    },
    inputDebounce: {
      type: Number,
      default: 500
    },
    color: {
      type: String,
      default: "blue"
    },
    mode: {
      type: String,
      default: "date"
    },
    is24hr: {
      type: Boolean,
      default: true
    },
    isExpanded: {
      type: Boolean,
      default: true
    },
    absoluteMaxRange: {
      type: Number,
      default: 1000 * 3600 * 24 * 365
    },
    errorColor: {
      type: String,
      default: "red"
    }
  },
  data() {
    return {
      range: {
        start: new Date(this.dateRange[0]), // Jan 16th, 2018
        end: new Date(this.dateRange[1]) // Jan 19th, 2018,
      },
      rangeValid: true
    };
  },
  methods: {
    onClickOutside() {
      this.$emit("close");
    },
    closeModal: function() {
      this.$emit("close");
    },
    updateDateRange: function() {
      this.$emit("update");
    }
  },
  computed: {
    actualColor: function(){
      return this.rangeValid ? this.color : this.errorColor
    }
  },
  watch: {
    range: {
      handler: function(val) {
        const startDate = new Date(val.start);
        const endDate = new Date(val.end);
        if (endDate.getTime() - startDate.getTime() > this.absoluteMaxRange) {
          this.rangeValid = false
        }else{
          this.rangeValid = true;
        }
        this.$emit("rangeSelected", {
          range: [val.start, val.end],
          valid: this.rangeValid
        });
      }
    }
  }
};
</script>

<style>
.vc-date-time {
  margin-left: auto !important;
  margin-right: auto;
}

.vc-date-time .vc-date {
  justify-content: center;
}
</style>

<style scoped>
.date-picker {
  background: var(--card-bg-color);
}
.vc-container {
  border: none;
}
</style>
