<template>
  <span class="m-container" v-bind:style="{ backgroundColor: color, height: actualSize, width: actualSize, minWidth: actualSize, minHeight: actualSize}"></span>
</template>

<script>
export default {
  name: "MoveLed",
  props: {
    color: {
      type: String,
      default: "#fff",
    },
    size: {
      type: String,
      default: 'm'
    }
  },
  computed:{
    actualSize(){
      switch (this.size) {
        case 'xs':return '8px';
        case 's': return '12px';
        case 'm': return '16px';
        case 'l': return '20px';
        case 'xl': return '24px';
        default:  return '16px'
      }
    }
  }
};
</script>

<style scoped>
.m-container {
  border-radius: 50%;
  display: inline-block;
}
</style>
