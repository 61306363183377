<i18n>
{
  "en": {},
  "it": {}
}
</i18n>
<template>
  <div class="tabs-container d-flex">
    <button
      v-for="t in tabs"
      :key="t"
      class="reset-btn tab mr64"
      :class="value === t ? 'active': ''"
      @click=onClick(t)
    >
      <move-text h3 b :class="value === t ? 'active': ''">{{t}}</move-text>
    </button>
  </div>
</template>
<script>
export default {
  name: "move-tabs",
  props: {
    tabs:{
      type: Array,
      default: () => []
    },
    value:{
      type: String,
      default: null
    }
  },
  methods:{
    onClick(t){
      this.$emit('input', t)
    }
  }
};
</script>
<style scoped>
.tabs-container{
  border-bottom: 2px solid #3E4254;
  box-shadow: 1px 8px 10px 0px rgb(0 0 0 / 20%) !important
}
.reset-btn{
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--text-color);
}
 .h3 {
  font-size: 1.2rem;

}
.tab {

  padding: 32px 32px 8px 32px;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 4px solid transparent;
}
.tab:hover {
  background-color: rgba(204, 204, 204, 0.2);
}

.tab.active {
  border-bottom: 4px solid var(--primary-color);
  color: var(--primary-color);
}

.active {
  color: var(--primary-color) !important;
}

</style>
