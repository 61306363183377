import Vue from "vue";
import Router from "vue-router";
import tokenHelper from "./helpers/tokenHelper";

const Dashboard = () => import("./views/V2/Dashboard.vue");
const Stat = () => import("./views/V2/Stats.vue");
const Login = () => import("./views/Login.vue");
const Admin = () => import("./views/V2/Admin.vue");
const RailsV2 = () => import("./views/V2/RailsV2.vue");
const Overlays = () => import("./views/V2/Overlays.vue");
const Trend = () => import("./views/V2/Trend.vue");
const Vibrational = () => import("./views/V2/Vibrational.vue");
const EventDetails = () => import("./views/V2/Events.vue");
const Deflection = () => import("./views/V2/Deflection.vue");
const Download = () => import("./views/V2/Download.vue");



function isLoggedIn() {
  return localStorage.getItem("token") && localStorage.getItem("refresh_token");
}

function isAdmin() {
  const token = localStorage.getItem("token");
  return token && tokenHelper.isAdmin(token);
}

function hasRailTool(){
  return localStorage.getItem('railEnabled') === "true";
}

function hasOverlaysTool(){
  return localStorage.getItem('overlaysEnabled') === "true"
}

// Props a partire dai params injectati
function dynamicPropsFn(route) {
  return {
    selectedStructureID: route.params.selectedStructureID,
    selectedSensorID: route.params.selectedSensorID,
  };
}

/* function getSelectedProps(from, to){

} */

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/dashboard",
      name: "home",
      component: Dashboard,
      props: dynamicPropsFn,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      redirect: "/dashboard",
    },
    {
      path: "/stats",
      name: "stats",
      component: Stat,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/download",
      name: "download",
      component: Download,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/trend",
      name: "trend",
      component: Trend,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/deflection",
      name: "deflection",
      component: Deflection,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/vibrational",
      name: "vibrational",
      component: Vibrational,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
    {
      path: "/details",
      name: "details",
      component: EventDetails,
      props: dynamicPropsFn,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/rails",
      component: RailsV2,
      name: "rails",
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/overlays",
      component: Overlays,
      name: "overlays",
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if ((!isLoggedIn() || !isAdmin()) && to.matched.some((record) => record.meta.requiresAdmin)) {
    next({
      name: "home",
    });
  } else if (isAdmin() && to.name !== "admin") {
    next({
      name: "admin",
    });
  } else if (!isLoggedIn() && to.matched.some((record) => record.meta.requiresAuth)) {
    next({
      name: "login",
    });
  } else if (to.name === "login" && isLoggedIn()) {
    // Redirect user to homepage
    return next({ path: "/" });
  } 
  //Tools
  else if (to.name === "rails") {
    //if has rail tool
    if (hasRailTool()) {
      next()
    }else{
      next({name: "home"});
    }
  } else if (to.name === "overlays") {
    //if has overlays tool
    if (hasOverlaysTool()) {
      next()
    }else{
      next({name: "home"});
    }
  }
  else {
    // In base alla route -> inject di struttura e sensore selezionato
    (to.params.selectedStructureID = from.name), (to.params.selectedSensorID = from.name);
    next(to.params);
  }
});

export default router;
