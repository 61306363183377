<template>
  <div>
    <label class="container">
      <input type="checkbox" v-bind="$attrs" v-on="$listeners" />
      <div class="checkmark"></div>
      <div class="elem-inj"><slot /></div>
    </label>
  </div>
</template>

<script>
export default {
  name: "MoveCheckbox",
};
</script>

<style scoped>
/* CUSTOM CHECKBOX */
.elem-inj {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container {
  display: inline-flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  display: inline-block;
  position: relative;
  top: 0px;
  left: 0;
  height: 16px;
  width: 16px;
  min-width: 16px;
  background-color: transparent;
  border: 2px solid #fefefe;
  border-radius: 4px;
}
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--primary-color);
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 3px;
  width: 2px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
