const namespaced = true;

const LINEAR_LINEAR_DIGIT_FORMULA = "linear-in-linear-digit-formula";
const LINEAR_DIGIT_FORMULA = "linear-digit-formula";
const QUADRATIC_FORMULA = "quadratic-formula";

import * as api from "../../services/api";
import _ from "lodash";

const state = {
  actualSensor: {},
  tempSensor: {},
  tempSensorValidation: {},
  sensorsConfig: [],
  sensorsValidation: [],
  loading: {
    resetCompensation: false,
    fetchAvailableMappedConfigurations: false,
    undoResetCompensation: false
  },
  manifestList: [],
  updateModalScroll: 0
};

const getters = {
  /****************************************************************/
  /* ROOT GETTERS */
  /****************************************************************/
  getStructureID: (_state, _getters, _rootState, rootGetters) => {
    const structure = rootGetters["structure/getSelectedStructure"];
    return structure && structure._id ? structure._id : null;
  },
  /****************************************************************/
  /* INTERNAL GETTERS */
  /****************************************************************/
  getIsUpdateModalScroll(state){
    return state.updateModalScroll
  },
  getIsMappedMode(_state, getters){
    return getters.getMode === 'mapped'
  },
  getIsVibratingWire(_state, getters){
    return getters.getSensorType === 'vibrating-wire'
  },
  getManifetList(state){
    return state.manifestList
  },
  getSelectedManifest(_state, getters){
    if (!getters.getManufacturer || !getters.getModelNumber) return null
    return getters.getManifetList.find(
      manifest => manifest.manufacturer === getters.getManufacturer && manifest.modelNumber === getters.getModelNumber
    )
  },
  getIsSensorChanged(state) {
    return !_.isEqual(state.tempSensor, state.actualSensor);
  },
  getAnalogNodeV2Validation(state) {
    return state.sensorsValidation;
  },
  getLoading(state) {
    return state.loading;
  },
  //sensor
  getTempSensor(state) {
    return state.tempSensor;
  },
  //sensor.eui
  getEui(_state, getters) {
    return getters.getTempSensor && getters.getTempSensor.eui;
  },
  //sensor.userConfig
  getTempSensorUserConfig(_state, getters) {
    return getters.getTempSensor && getters.getTempSensor.userConfig;
  },
  //sensor.userConfig.channels[0]
  getTempSensorChannel0(_state, getters) {
    return getters.getTempSensorUserConfig &&
      getters.getTempSensorUserConfig.channels &&
      getters.getTempSensorUserConfig.channels.length
      ? getters.getTempSensorUserConfig.channels[0]
      : null;
  },
  //sensor.userConfig.channels[0].nativeType
  getSensorType(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.nativeType
    );
  },
  //sensor.userConfig.channels[0].color
  getColor(_state, getters) {
    return (
      getters.getTempSensorUserConfig && getters.getTempSensorUserConfig.color
    );
  },
  //sensor.userConfig.channels[0].manufacturer
  getManufacturer(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.manufacturer
    );
  },
  //sensor.userConfig.channels[0].manufacturer
  getModelNumber(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.modelNumber
    );
  },
  //sensor.userConfig.accThreshold
  getCadence(_state, getters) {
    return (
      getters.getTempSensorUserConfig && getters.getTempSensorUserConfig.cadence
    );
  },
  //sensor.userConfig.accThreshold
  getAccThreshold(_state, getters) {
    return (
      getters.getTempSensorUserConfig &&
      getters.getTempSensorUserConfig.accThreshold
    );
  },
  //sensor.userConfig.accThreshold
  getAccTriggerMode(_state, getters) {
    return (
      getters.getTempSensorUserConfig &&
      getters.getTempSensorUserConfig.accTriggerMode
    );
  },
  //sensor.userConfig.channels[0].name
  getProbeName(_state, getters) {
    return getters.getTempSensorChannel0 && getters.getTempSensorChannel0.name;
  },
  //sensor.userConfig.channels[0].mode
  getMode(_state, getters) {
    return getters.getTempSensorChannel0 && getters.getTempSensorChannel0.mode;
  },
  //sensor.userConfig.channels[0].mesUnit
  getMesUnit(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.mesUnit
    );
  },
  //sensor.userConfig.channels[0].warmupTime
  getWarmupTime(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.warmupTime
    );
  },
  //sensor.userConfig.channels[0].externalNtc
  getExternalNTC(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.externalNtc
    );
  },

  //sensor.userConfig.channels[0].conversionFormula
  getConversionFormula(_state, getters) {
    return (
      getters.getTempSensorChannel0 &&
      getters.getTempSensorChannel0.conversionFormula
    );
  },

  //sensor.userConfig.channels[0].minFreq
  getMinFreq(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.minFreq
    );
  },

  //sensor.userConfig.channels[0].maxFreq
  getMaxFreq(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.maxFreq
    );
  },

  //sensor.userConfig.channels[0].probeType
  getProbeType(_state, getters) {
    return (
      getters.getTempSensorChannel0 && getters.getTempSensorChannel0.probeType
    );
  },

  //sensor.userConfig.channels[0].probeType
  getAlerts(_state, getters) {
    return getters.getTempSensorChannel0 &&
      getters.getTempSensorChannel0.alerts &&
      getters.getTempSensorChannel0.alerts.length
      ? getters.getTempSensorChannel0.alerts
      : [];
  },

  //sensor.userConfig.channels[0].responseType
  getResponseType(_state, getters) {
    return (
      getters.getTempSensorChannel0 &&
      getters.getTempSensorChannel0.responseType
    );
  },

  //AUX
  //Conversion formula mode: Linear in linear digit || Linear digit || Quadratic
  getVWConversionParameterMode(_state, getters) {
    const G = getters.getConversionFormula && getters.getConversionFormula.G
    const ABC =
      getters.getConversionFormula &&
      (getters.getConversionFormula.A !== undefined ||
        getters.getConversionFormula.B !== undefined ||
        getters.getConversionFormula.C !== undefined)
        ? true
        : false;
    if (G === null) {
      return LINEAR_DIGIT_FORMULA;
    }
    if (typeof G === "number" || G === '') {
      return LINEAR_LINEAR_DIGIT_FORMULA;
    }
    if (ABC) {
      return QUADRATIC_FORMULA;
    }
    return null;
  },

  //AUX
  //vibrating wire
  getResetCompensationDateChannel0(state) {
    if (!state.tempSensor || !state.tempSensor.eui) {
      return null;
    }
    const actualSensor = state.sensorsConfig.find(
      s => s.eui === state.tempSensor.eui
    );

    const lastBaseRawValueResetDate =
      actualSensor &&
      actualSensor.userConfig &&
      actualSensor.userConfig.channels &&
      actualSensor.userConfig.channels.length &&
      actualSensor.userConfig.channels[0] &&
      actualSensor.userConfig.channels[0].lastBaseRawValueResetDate
        ? actualSensor.userConfig.channels[0].lastBaseRawValueResetDate
        : null;

    return lastBaseRawValueResetDate;
  }
};

const actions = {

  updateModalScroll({ commit }, evt){
    commit('setUpdateModalScroll', evt.timeStamp);
  },

  setLoading({ state, commit }, loading = { 
    resetCompensation: false,
    fetchAvailableMappedConfigurations: false
  }){
    commit('setLoading', {...state.loading, ...loading})
  },

  /* Set temp sensor */
  /***************************************************************************/
  setTempSensor({ state, commit }, sensor) {
    commit("setTempSensor", { ...state.tempSensor, ...sensor });
  },
  /* Clean temp sensor */
  /***************************************************************************/
  cleanTempSensor({ commit }) {
    commit("setTempSensor", {});
  },
  setActualSensor({ commit }, sensor) {
    commit("setActualSensor", sensor);
  },

  /* Update user config in temp sensor */
  /***************************************************************************/
  updateUserConfig({ state, dispatch }, fields) {
    const tempSensor = state.tempSensor;
    let userConfig = { ...tempSensor.userConfig, ...fields };
    tempSensor.userConfig = userConfig;
    dispatch("setTempSensor", tempSensor);
  },

  /* Update rsc config in temp sensor */
  /***************************************************************************/
  updateRscConfig({ state, dispatch, getters }, fields) {
    const tempSensor = state.tempSensor;
    let rscConfig = { ...getters.getTempSensorChannel0, ...fields };
    tempSensor.userConfig.channels[0] = rscConfig;
    dispatch("setTempSensor", tempSensor);
  },
  /* Update rsc config in temp sensor */
  /***************************************************************************/
  deleteRscConfigFields({ state, dispatch, getters }, fields = []) {
    const tempSensor = state.tempSensor;
    let rscConfig = getters.getTempSensorChannel0;
    for (let i = 0; i < fields.length; i++) {
      delete rscConfig[fields[i]]
    }
    tempSensor.userConfig.channels[0] = rscConfig;
    dispatch("setTempSensor", tempSensor);
  },

  /* Update validation of temp sensor */
  /***************************************************************************/
  updateValidationTempSensor(
    { state, commit, getters },
    payload = { field: "", valid: false }
  ) {
    const obj = {};
    obj[payload.field] = payload.valid;
    const sensorsValidation = state.sensorsValidation;
    const eui = getters.getEui;
    const idx = sensorsValidation.findIndex(s => s.eui === eui);
    if (idx < 0) {
      return;
    }
    sensorsValidation[idx].validation = {
      ...sensorsValidation[idx].validation,
      ...obj
    };
    commit("setSensorsValidation", sensorsValidation);
  },

  /* Update validation when in raw mode */
  /***************************************************************************/
  updateValidationRawMode({ state, commit, getters }) {
    const sensorsValidation = state.sensorsValidation;
    const eui = getters.getEui;
    const idx = sensorsValidation.findIndex(s => s.eui === eui);
    if (idx < 0) {
      return;
    }
    delete sensorsValidation[idx].validation.dataConversion4Params;
    delete sensorsValidation[idx].validation.dataConversionMVV;
    delete sensorsValidation[idx].validation.dataConversionNTC;
    delete sensorsValidation[idx].validation.dataConversionVW;
    delete sensorsValidation[idx].validation.dataConversionYMinYMax;
    delete sensorsValidation[idx].validation.ntc;
    delete sensorsValidation[idx].validation.mesUnit;
    delete sensorsValidation[idx].validation.mappedData;
    commit("setSensorsValidation", sensorsValidation);
  },
  updateValidationCustomFromMappedMode({ state, commit, getters }) {
    const sensorsValidation = state.sensorsValidation;
    const eui = getters.getEui;
    const idx = sensorsValidation.findIndex(s => s.eui === eui);
    if (idx < 0) {
      return;
    }
    delete sensorsValidation[idx].validation.mappedData;
    delete sensorsValidation[idx].validation.dataConversion4Params;
    delete sensorsValidation[idx].validation.dataConversionMVV;
    delete sensorsValidation[idx].validation.dataConversionNTC;
    delete sensorsValidation[idx].validation.dataConversionVW;
    delete sensorsValidation[idx].validation.dataConversionYMinYMax;
    delete sensorsValidation[idx].validation.ntc;
    delete sensorsValidation[idx].validation.mesUnit;
    commit("setSensorsValidation", sensorsValidation);
  },
  setSensorsValidation({ commit }, sensorsValidation = []) {
    commit("setSensorsValidation", sensorsValidation);
  },
  setSensorsConfig({ commit }, sensorsConfig = []) {
    commit("setSensorsConfig", sensorsConfig);
  },

  /* Execute reset compensation */
  /***************************************************************************/
  async resetCompensation({ getters }) {
    try {
      const eui = getters.getEui;
      await api.resetOffset([eui]);
    } catch (error) {
      alert(error.message);
    }
  },
  async setResetCompensationLoading({ commit }, value) {
    commit("setResetCompensationLoading", value);
  },
  async setUndoResetCompensationLoading({ commit }, value) {
    commit("setUndoResetCompensationLoading", value);
  },

    /* Execute reset compensation */
  /***************************************************************************/
  async undoCompensation({ getters }) {
    try {
      const eui = getters.getEui;
      await api.undoOffset([eui]);
    } catch (error) {
      alert(error.message);
    }
  },

  /* fetch sensors */
  /***************************************************************************/
  async fetchSensors({ getters, commit }, { resetValidation = true }) {
    try {
      const structureId = getters.getStructureID;
      const response = await api.getSensorsSettings(structureId);
      const analogNodesV2 = response.filter(s => s.rev === "2.0");
      if (resetValidation) {
        const sensorsValidation = analogNodesV2.map(s => {
          return {
            eui: s.eui,
            validation: {}
          };
        });
        commit("setSensorsValidation", sensorsValidation);
      }
      commit("setSensorsConfig", analogNodesV2);
    } catch (error) {
      alert(error.message);
    }
  },

  /* fetch available mapperd configurations */
  /***************************************************************************/
  async fetchAvailableMappedConfigurations({ getters, commit }) {
    try {
      const rscType = getters.getSensorType;
      const response = await api.getManifests({rscType, manifestType: 'rsc'})
      commit("setManifestList", response)
    } catch (error) {
      alert(error.message);
    }
  },
  setSelectedManifest({ getters, commit }, {_id = null}){
    const selectedManifest = getters.getManifetList.find(manifest => manifest._id === _id);
    commit("setSelectedManifest", selectedManifest)
  }

};

const mutations = {
  setTempSensor: (state, sensor) => (state.tempSensor = sensor),
  setSensorsConfig: (state, sensors) => (state.sensorsConfig = sensors),
  setLoading: (state, loading) => (state.loading = loading),
  setResetCompensationLoading: (state, value) =>
    (state.loading.resetCompensation = value),
  setUndoResetCompensationLoading: (state, value) =>
  (state.loading.undoResetCompensation = value),
  setSensorsValidation: (state, sensorsValidation) =>
    (state.sensorsValidation = sensorsValidation),
  setTempSensorValidation: (state, validation) =>
    (state.tempSensorValidation = validation),
  setActualSensor: (state, sensor) => (state.actualSensor = sensor),
  setManifestList: (state, manifestList) => (state.manifestList = manifestList),
  setUpdateModalScroll: (state, updateModalScroll) => (state.updateModalScroll = updateModalScroll)
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
