<template>
  <div class="move-btn-list-container" :class="{ large, small, rounded }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "move-btn-list",
  props: {
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.move-btn-list-container {
  display: grid;
  grid-auto-flow: column;
  height: 35px;
  background-color: var(--secondary-color-dark);
  cursor: pointer;
  box-sizing: border-box;
  column-gap: 3px;
  padding: 3px;
  border-radius: 4px;
}
.move-btn-list-container.large {
  height: 45px;
  min-height: 45px;
}
.move-btn-list-container.small {
  height: 35px;
}
.move-btn-list-container.rounded {
  border-radius: 32px;
}
</style>
