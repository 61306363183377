import api from "../../services/api";

const namespaced = true;

const state = {
  selectedSensor: null,
  dateRange: [],
  dateRangeEnergyConsumptions: [],
  temperaturesList: [],
  displacementList: [],
  tilts: [],
  cracks: [],
  accelerations: [],
  analogs: [],
  PPVs: null,
  displacementDetails: {},
  accelerationDetails: {},
  dispFFT: {},
  accFFT: {},
  firstCrack: null,
  firstTilt: null,
  tempLoaded: false,
  relativeMode: true,
  dispLoaded: false,
  eventsLoaded: false,
  loadingStatus: true,
  eventsCounter: 0,
  absolute3D: true,
  getEnergyConsumptionNodeV2: [],
};

const getters = {
  getDateRange: (state) => state.dateRange,
  getSelectedSensor: (state) => state.selectedSensor,
  getDisplacementList: (state) => state.displacementList,
  getDisplacementDetails: (state) => state.displacementDetails,
  getFFT: (state) => state.dispFFT,
  getAccelerationsFFT: (state) => state.accFFT,
  getTempLoaded: (state) => state.tempLoaded,
  getDispLoaded: (state) => state.dispLoaded,
  getEventsLoaded: (state) => state.eventsLoaded,
  getTilts: (state) =>
    state.tilts.filter((a) => state.selectedSensor && a.eui === state.selectedSensor.eui).map((t) => ({ ...t, extraAngle: state.absolute3D ? Math.abs(t.extraAngle) : t.extraAngle })),
  getCracks: (state) => state.cracks,
  getAnalogReadings: (state) => state.analogs,
  getPPVs: (state) => state.PPVs,
  getAccelerations: (state) => state.accelerations,
  getAccelerationDetails: (state) => state.accelerationDetails,
  getRelativeMode: (state) => state.relativeMode,
  getBaseValue: (state) => (state.selectedSensor ? state.selectedSensor.baseValue : 0),
  getEventsCounter: (state) => state.eventsCounter,
  getTemperaturesList: (state) => state.temperaturesList,
  getLoadingStatus: (state) => state.loadingStatus,
  getSensorType: (state) => (state.selectedSensor ? state.selectedSensor.type : null),
  getIsThereData: (state) => {
    let check = false;
    if (state.selectedSensor && state.selectedSensor.type === "deck" && state.displacementList && state.displacementList.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "accelerometer" && state.accelerations && state.accelerations.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "tiltmeter" && state.tilts && state.tilts.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "crackmeter" && state.cracks && state.cracks.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "analog-node" && state.analogs && state.analogs.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "vibrometer" && state.PPVs && state.PPVs.values.length > 0) check = true;
    return check;
  }
};

const actions = {
  async setSelectedSensor({ commit }, sensor) {
    commit("setSelectedSensor", sensor);
  },
  async setRelativeMode({ commit, dispatch }, mode) {
    commit("setRelativeMode", mode);
    dispatch("fetchEventList");
  },
  async setDateRange({ commit }, values) {
    let firstDate = new Date(values[0]);
    let lastDate = new Date(values[1]);
    commit("setDateRange", [firstDate, lastDate]);
  },
  async setLoadingStatus({ commit }, status) {
    commit("setLoadingStatus", status);
  },
  /* Event List Fetch */
  async fetchEventList({ commit, state, rootGetters }, opt) {
    commit("setLoadingStatus", false);
    commit("setTempLoaded", false);

    let temperatures;

    const userInterface = rootGetters['user/getInterface'];
    const velocity = userInterface && userInterface.dimensions && userInterface.dimensions.velocity 
      ? userInterface.dimensions.velocity 
      : 'mm/s';

    // Chiamo le API e genero le trace a secondo del tipo di sensore
    if (state.selectedSensor) {
      switch (state.selectedSensor.type) {
        case "analog-node": {
          const analogPromise = api.getAnalogReadings(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1], !state.relativeMode);
          const analogs = await analogPromise;
          commit("setAnalogs", analogs);
          commit("setEventsCounter", analogs.length);
          break;
        }
        case "tiltmeter": {
          const tiltsPromise = api.getTiltsList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1], !state.relativeMode, state.selectedSensor.beamFormat);
          const tilts = await tiltsPromise;
          commit("setTilts", tilts);
          commit("setEventsCounter", tilts.length);
          break;
        }
        case "deck": {
          const dispPromise = api.getDisplacementsList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1] /* , opt.itemsPerPage, opt.page */);
          console.log(opt);
          const disps = await dispPromise;
          commit("setDisplacementList", disps);
          commit("setEventsCounter", disps.length);
          break;
        }
        case "accelerometer": {
          const accelerationsPromies = api.getAccelerationsList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1]);
          const accs = await accelerationsPromies;
          commit("setAccelerations", accs);
          commit("setEventsCounter", accs.length);
          break;
        }
        case "crackmeter": {
          const cracksPromise = api.getCracksList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1]);
          const cracks = await cracksPromise;
          commit("setCracks", cracks);
          commit("setEventsCounter", cracks.length);
          break;
        }
        case "vibrometer": {
          const ppvPromise = api.getPPVs(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1], true, velocity);
          const ppvs = await ppvPromise;
          commit("setPPVs", ppvs);
          commit("setEventsCounter", ppvs.length);
          break;
        }
      }
      // Temperature Fetching uguale per tutti i tipi di sensori tranne nodo analogico e vibrometero
      if (!["analog-node"].includes(state.selectedSensor.type)) {
        const tempPromise = api.getTemperaturesList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1]);
        const responseTemperature = await tempPromise;
        if (responseTemperature[0]) temperatures = responseTemperature;
        commit("setTemperaturesList", temperatures);
      }
      commit("setTempLoaded", true);
      commit("setLoadingStatus", true);
    }
  },
  /* Displacement Details Fetch */
  async fetchDispDetails({ commit }, id) {
    commit("setDispLoaded", false);
    commit("setLoadingStatus", false);
    let dispDetails;
    if (id) {
      const dispDetailsPromise = api.getDisplacementsDetails(id, state.selectedSensor.eui);
      dispDetails = await dispDetailsPromise;
    } else {
      dispDetails = null;
    }
    commit("setDisplacementDetails", dispDetails);
    commit("setDispLoaded", true);
    commit("setLoadingStatus", true);
  },

  /* Acceleration Details Fetch */
  async fetchAccDetails({ commit }, id) {
    commit("setLoadingStatus", false);
    let accDetails;
    if (id) {
      const accDetailsPromise = api.getAccelerationDetails(id, state.selectedSensor.eui);
      accDetails = await accDetailsPromise;
    } else {
      accDetails = null;
    }
    commit("setAccelerationDetails", accDetails);
    commit("setLoadingStatus", true);
  },
  setAbsolute3dAngle({ commit }, value) {
    commit("set3dAngle", value);
  },
  /* FFT */
  async fetchFFT({ commit, state }) {
    if (state.selectedSensor.type === "deck" && state.displacementDetails.payload) {
      const fftResult = await api.getFFT(state.displacementDetails);
      commit("setDispFFT", fftResult);
    } else if (state.selectedSensor.type === "accelerometer") {
      const fftResult = await api.getFFT(state.accelerationDetails);
      commit("setAccFFT", {
        freqsX: fftResult.freqsX,
        magnX: fftResult.magnX,
        freqsY: fftResult.freqsY,
        magnY: fftResult.magnY,
        freqsZ: fftResult.freqsZ,
        magnZ: fftResult.magnZ,
      });
    }
  },
};

const mutations = {
  setDateRange: (state, values) => (state.dateRange = values),
  setDisplacementList: (state, dispList) => (state.displacementList = dispList),
  setDisplacementDetails: (state, details) => (state.displacementDetails = details),
  setAccelerationDetails: (state, details) => (state.accelerationDetails = details),
  setSelectedSensor: (state, sensor) => (state.selectedSensor = sensor),
  setDispFFT: (state, fft) => (state.dispFFT = fft),
  setAccFFT: (state, fft) => (state.accFFT = fft),
  setTempLoaded: (state, status) => (state.tempLoaded = status),
  setDispLoaded: (state, status) => (state.dispLoaded = status),
  setEventsLoaded: (state, status) => (state.eventsLoaded = status),
  setTilts: (state, tilts) => (state.tilts = tilts),
  setCracks: (state, cracks) => (state.cracks = cracks),
  setPPVs: (state, PPVs) => (state.PPVs = PPVs),
  setAccelerations: (state, accelerations) => (state.accelerations = accelerations),
  setAnalogs: (state, readings) => (state.analogs = readings),
  setRelativeMode: (state, mode) => (state.relativeMode = mode),
  setEventsCounter: (state, count) => (state.eventsCounter = count),
  setTemperaturesList: (state, temps) => (state.temperaturesList = temps),
  setLoadingStatus: (state, loaded) => (state.loadingStatus = loaded),
  set3dAngle: (state, value) => (state.absolute3D = value),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
