<template>
  <div v-on="$listeners" class="item" :class="{ active, rounded, small, large, bordered }">
      <slot />
</div>
</template>
<script>
export default {
  name: "move-btn-item",
  props: {
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped>
.item {
  cursor: pointer;
  background: var(--secondary-color-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.item.bordered{
  border: 1px solid var(--secondary-color-light-2);
}

.item.active:hover {
  background: var(--primary-color);
}
.item:hover {
  background-color: var(--secondary-color-light);
}
.active {
  background: var(--primary-color);
  transition: background 0.5s;
}
.rounded {
  border-radius: 32px;
}

.item.small {
  padding-left: 8px;
  padding-right: 8px;
}

.item.large {
  padding-left: 16px;
  padding-right: 16px;
}

</style>
