import api from "../../services/api";
import axios from "axios";
import interceptorModule from "../../helpers/interceptor";
const namespaced = true;

const state = {
  users: [], // Lista dei clienti
  selectedUserID: null, // ID del cliente selezionato
  structures: [], // Lista delle strutture
  selectedStructureID: null, // ID della struttura selezionata
  loadingCount: 0,
  availableSensors: null, // Sensori disponibili per l'assegnazione al cliente
  selectedAvailableSensors: [], // Lista sensori disponibili selezionati
  availableSensorDetails: {}, // Dettagli del sensore disponibile selezionato,
  gateways: [], // Lista dei gateway (per adesso presi da loriot)
  nsUsage: null,
};

const getters = {
  getUsers: (state) => state.users,
  getSelectedUser: (state) => state.users.find((s) => s._id === state.selectedUserID) || null,
  getStructures: (state) => state.structures,
  getSelectedStructure: (state) => state.structures.find((s) => s._id === state.selectedStructureID || null),
  getLoadingStatus: (state) => state.loadingCount > 0,
  getAvailableDevices: (state) => state.availableSensors,
  getSelectedAvailableSensors: (state) => state.selectedAvailableSensors,
  getAvailableSensorDetails: (state) => state.availableSensorDetails,
  getGateways: (state) => state.gateways,
  getNsUsage: (state) => state.nsUsage,
};

const actions = {
  async loginAs({ commit }, username) {
    try {
      commit("setLoadingStatus", true);
      const resp = await api.loginAs(username);
      const token = resp.token;
      const refreshToken = resp.refreshToken;
      const userID = resp.id;
      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("userID", userID);
      localStorage.setItem("username", username);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      interceptorModule();
      commit("setLoadingStatus", false);
    } catch (error) {
      localStorage.removeItem("token");
      commit("setLoadingStatus", false);
      throw error;
    }
  },
  async fetchUsers({ commit }) {
    const users = await api.getUsers();
    commit("setUsers", users);
  },
  async fetchNsUsage({ commit }) {
    console.log("[ADMIN] Fetch NS Usage");
    const usage = await api.getNsUsage();
    commit("setNsUsage", usage);
  },
  async fetchAvailableDevices({ commit }, searchQuery) {
    const sensors = await api.getAvailableDevices(searchQuery.type, searchQuery.eui, searchQuery.rev, searchQuery.region);
    commit("setAvailableSensors", sensors);
  },
  async fetchAvailableSensorDetails({ commit }, sensorID) {
    if (sensorID) {
      const sensorDetails = await api.getAvailableSensorDetails(sensorID);
      commit("setAvailableSensorDetails", sensorDetails);
    } else {
      commit("setAvailableSensorDetails", {});
    }
  },
  async setSelectedAvailableSensors({ commit }, sensors) {
    commit("setSelectedAvailableSensorsList", sensors);
  },
  /* Assegna un dato sensore ad una struttura */
  async assignSensorsToStructure({ dispatch }, sensorsParams) {
    console.log("Assegno", sensorsParams.sensorIDs, " a ", state.selectedStructureID, " customer ", state.selectedUserID, " bridgeSpan", sensorsParams.bridgeSpans, " bridgeSide", sensorsParams.bridgeSides);
    await api.addSensorsFromProvisioning(state.selectedUserID, state.selectedStructureID, sensorsParams.sensorIDs, sensorsParams.bridgeSpans || [], sensorsParams.bridgeSides || []);
    let selectedStructure = state.selectedStructureID;
    await dispatch("fetchStructures", state.selectedUserID);
    await dispatch("selectStructure", selectedStructure);
  },
  async assignGatewayToStructure({ dispatch }, gatewayID) {
    await api.addGateway(state.selectedUserID, state.selectedStructureID, gatewayID);
    await dispatch("fetchGateways");
  },
  /* Seleziona l'utente dato il suo ID */
  async selectUser({ commit }, userID) {
    //const existingUser = state.users.find((s) => s._id === userID);
    //commit("selectUser", existingUser);
    commit("selectUser", userID);
  },
  /* Esegue il fetching di tutte le strutture */
  async fetchStructures({ commit }, userID) {
    const structures = await api.getStructures(userID);
    commit("setStructures", structures);
    //commit("selectStructure", null);
  },
  /* Esegue il fetching di tutti i gws */
  async fetchGateways({ commit }) {
    if (state.selectedUserID && state.selectedStructureID) {
      const gws = await api.getGateways(state.selectedUserID, state.selectedStructureID);
      commit("setGateways", gws);
    } else {
      commit("setGateways", []);
    }
  },
  /* Esegue la put di tutti i gateways */
  // eslint-disable-next-line no-unused-vars
  async putGateways({ commit }, gws) {
    if (state.selectedUserID && state.selectedStructureID && gws) {
      await api.putGateways(state.selectedUserID, state.selectedStructureID, gws);
      //await dispatch("fetchGateways", state.selectedUserID);
    }
  },
  /* Esegue la delete di un gateway */
  // eslint-disable-next-line no-unused-vars
  async deleteGateway({ commit }, gatewayID) {
    if (state.selectedUserID && state.selectedStructureID && gatewayID) {
      await api.deleteGateway(state.selectedUserID, state.selectedStructureID, gatewayID);
      //await dispatch("fetchGateways", state.selectedUserID);
    }
  },
  /* Dato l'ID di una struttra esistente, la seleziona */
  async selectStructure({ commit }, structureID) {
    commit("selectStructure", structureID);
  },
  async insertStructure({ /* commit,  */ dispatch }, structure) {
    await api.insertStructure(state.selectedUserID, structure);
    await Promise.all([dispatch("fetchNsUsage"), dispatch("fetchStructures", state.selectedUserID)]);
  },
  async editStructure({ dispatch }, structure) {
    const userID = state.selectedUserID;
    const structureID = structure._id;
    const structureInfo = {
      name: structure.name,
      address: structure.address,
      spans: structure.spans,
      installDate: structure.installDate,
      fddEnabled: structure.fddEnabled,
      railEnabled: structure.railEnabled,
      overlaysEnabled: structure.overlaysEnabled,
      loraRegion: structure.loraRegion,
      type: structure.type,
      coordinates: {
        lat: structure.coordinates.lat,
        lng: structure.coordinates.lng,
      },
    };
    await api.editStructure(userID, structureID, structureInfo);
    let selectedStructure = state.selectedStructureID;
    await dispatch("fetchStructures", state.selectedUserID);
    await dispatch("selectStructure", selectedStructure);
  },
  async deleteStructureImage({ dispatch }, imageID) {
    await api.deleteImageFromStructure(state.selectedUserID, state.selectedStructureID, imageID);
    await dispatch("fetchStructures", state.selectedUserID);
  },
  async addStructureImage({ dispatch }, image) {
    let selectedStructure = state.selectedStructureID;
    await api.addImageToStructure(state.selectedUserID, state.selectedStructureID, image);
    await dispatch("fetchStructures", state.selectedUserID);
    await dispatch("selectStructure", selectedStructure);
  },
  async deleteStructure({ dispatch /* , commit */ }, structure) {
    await api.deleteStructure(state.selectedUserID, structure._id);
    await Promise.all([dispatch("fetchNsUsage"), dispatch("fetchStructures", state.selectedUserID)]);
  },
  async deleteOldEvents({ dispatch }, structure) {
    let selectedStructure = state.selectedStructureID;
    await api.deleteOldEvents(state.selectedUserID, structure._id);
    await dispatch("selectStructure", selectedStructure);
  },
  async editSensorPosition({ dispatch }, newSensorPosition) {
    let selectedStructure = state.selectedStructureID;
    await api.adminSaveSensorsPosition(state.selectedUserID, { sensors: newSensorPosition });
    await dispatch("fetchStructures", state.selectedUserID);
    await dispatch("selectStructure", selectedStructure);
  },
  async deleteCustomer({ dispatch }, customerID) {
    await api.deleteUser(customerID);
    if ((customerID === state.selectedUserID) && state.users.some(us => us.type === "standard")) {
      await dispatch("selectUser", state.users.filter(us => us.type === "standard").find((u) => u._id !== customerID)._id);
    } else {
      await dispatch("selectUser", null);
    }
    await Promise.all([dispatch("fetchNsUsage"), dispatch("fetchUsers")]);
    // Se ho eliminato l'utente selezionato, ne scelgo uno nuovo
  },
  async addCustomer({dispatch}, customer) {
    console.log("dispatch", dispatch);
    let inserted = await api.addUser(customer);
    await dispatch("selectUser", inserted._id);
    await dispatch("fetchUsers");
  },
  async editCustomer({ dispatch }, { customerID, customerInfo }) {
    await api.editUser(customerID, customerInfo);
    await dispatch("fetchUsers");
  },
  async deleteSensor({ dispatch }, sensorEUI) {
    await api.deleteSensor(state.selectedStructureID, state.selectedUserID, sensorEUI);
    let selectedStructure = state.selectedStructureID;
    await Promise.all([dispatch("fetchNsUsage"), dispatch("fetchStructures", state.selectedUserID)]);
    await dispatch("selectStructure", selectedStructure);
  },
  async setLoadingStatus({ commit }, status) {
    commit("setLoadingStatus", status);
  },
  async addSensor({ dispatch }, sensor) {
    await api.addSensors(state.selectedUserID, state.selectedStructureID, [sensor]);
    let selectedStructure = state.selectedStructureID;
    await Promise.all([dispatch("fetchNsUsage"), dispatch("fetchStructures", state.selectedUserID)]);
    await dispatch("selectStructure", selectedStructure);
  },
  async updateSensor({ dispatch }, sensorObject) {
    await api.updateSensor(state.selectedUserID, sensorObject.eui, sensorObject.sensorInfo);
    let selectedStructure = state.selectedStructureID;
    await dispatch("fetchStructures", state.selectedUserID);
    await dispatch("selectStructure", selectedStructure);
  },
  async updateFDDGroups({ dispatch }, { sensorType, sensorGroups }) {
    await api.updateFDDGroups(state.selectedUserID, state.selectedStructureID, sensorType, { sensorGroups: sensorGroups });
    let selectedStructure = state.selectedStructureID;
    await dispatch("fetchStructures", state.selectedUserID);
    await dispatch("selectStructure", selectedStructure);
  },
};

const mutations = {
  setUsers: (state, users) => (state.users = users),
  selectUser: (state, user) => (state.selectedUserID = user),
  setNsUsage: (state, usage) => (state.nsUsage = usage),
  setStructures: (state, structures) => (state.structures = structures),
  selectStructure: (state, structure) => (state.selectedStructureID = structure),
  setLoadingStatus: (state, status) => (state.loadingCount = status === true ? state.loadingCount + 1 : state.loadingCount - 1),
  setAvailableSensors: (state, sensors) => (state.availableSensors = sensors),
  setSelectedAvailableSensorsList: (state, sensors) => (state.selectedAvailableSensors = sensors),
  setAvailableSensorDetails: (state, sensorDetails) => (state.availableSensorDetails = sensorDetails),
  setGateways: (state, gws) => (state.gateways = gws),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
