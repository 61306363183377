<template>
  <div>
    <slot/>
  </div>
</template>

<script>
export default {
    name: "MoveListGroup"
};
</script>

<style scoped>
</style>
