const axios = require("axios");
const config = require("../config");
/******************* ******************* ******************* ******************* *******************/
/* AUTH */
/******************* ******************* ******************* ******************* *******************/

/* Login */
async function login(username, password) {
  const response = await axios.post(config.AUTH_ENDPOINT + "login", {
    username: username,
    password: password
  });
  return response.data;
}

/* Logout */
async function logout(refresh_token) {
  const response = await axios.delete(config.AUTH_ENDPOINT + "refresh_token", {
    data: { token: refresh_token }
  });
  return response.data;
}

/* Refresh Token */
async function refreshToken(userID, refresh_token) {
  const response = await axios.post(config.AUTH_ENDPOINT + "refresh_token", {
    id: userID,
    refreshToken: refresh_token
  });
  return response.data;
}

/******************* ******************* ******************* ******************* *******************/
/* ADMIN */
/******************* ******************* ******************* ******************* *******************/

/* Login as */
async function loginAs(username) {
  const response = await axios.post(config.AUTH_ENDPOINT + "admin/login", {
    username: username
  });
  return response.data;
}

/* Restituisce la lista degli utenti */
async function getUsers() {
  const response = await axios.get(config.API_ENDPOINT + "admin");
  return response.data;
}

/* Restituisce le quote di utilizzo sul Network Server */
async function getNsUsage() {
  const response = await axios.get(config.API_ENDPOINT + "admin/usage");
  return response.data;
}

async function deleteUser(customerID) {
  const response = await axios.delete(config.API_ENDPOINT + "admin/", {
    params: {
      customerID: customerID
    }
  });
  return response.data;
}

async function addUser(user) {
  const response = await axios.post(config.API_ENDPOINT + "admin/", user);
  return response.data;
}

async function editUser(customerID, customerInfo) {
  const response = await axios.put(
    config.API_ENDPOINT + "admin/",
    customerInfo,
    {
      params: {
        customerID: customerID
      }
    }
  );
  return response.data;
}

async function getStructures(customerID) {
  const response = await axios.get(config.API_ENDPOINT + "admin/structures", {
    params: { customerID: customerID }
  });
  return response.data;
}

async function getGateways(customerID, structureID) {
  const response = await axios.get(
    config.API_ENDPOINT +
      "admin/customers/" +
      customerID +
      "/structures/" +
      structureID +
      "/gateways"
  );
  return response.data;
}

async function putGateways(customerID, structureID, gateways) {
  const body = {
    gateways: gateways
  };
  const response = await axios.put(
    config.API_ENDPOINT +
      "admin/customers/" +
      customerID +
      "/structures/" +
      structureID +
      "/gateways",
    body
  );
  return response.data;
}

async function deleteGateway(customerID, structureID, gatewayID) {
  const response = await axios.delete(
    config.API_ENDPOINT +
      "admin/customers/" +
      customerID +
      "/structures/" +
      structureID +
      "/gateways/" +
      gatewayID
  );
  return response.data;
}

async function addGateway(customerID, structureID, gatewayID) {
  const body = {
    gatewayID: gatewayID
  };
  const response = await axios.post(
    config.API_ENDPOINT +
      "admin/customers/" +
      customerID +
      "/structures/" +
      structureID +
      "/gateways",
    body
  );
  return response.data;
}

async function insertStructure(customerID, structure) {
  const response = await axios.post(config.API_ENDPOINT + "admin/structures", {
    customerID: customerID,
    structure: structure
  });
  return response.data;
}

async function editStructure(customerID, structureID, structureInfo) {
  const response = await axios.put(
    config.API_ENDPOINT + "admin/structures",
    structureInfo,
    {
      params: {
        customerID: customerID,
        structureID: structureID
      }
    }
  );
  return response.data;
}

async function deleteImageFromStructure(customerID, structureID, imageID) {
  const response = await axios.delete(
    config.API_ENDPOINT + "admin/structures/image",
    {
      params: {
        customerID: customerID,
        structureID: structureID,
        imageID: imageID
      }
    }
  );
  return response.data;
}

async function addImageToStructure(customerID, structureID, imageFile) {
  var bodyFormData = new FormData();
  bodyFormData.append("image", imageFile);
  const response = await axios({
    method: "post",
    url: config.API_ENDPOINT + "admin/structures/image",
    data: bodyFormData,
    params: {
      customerID: customerID,
      structureID: structureID
    },
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
}

async function deleteStructure(customerID, structureID) {
  const response = await axios.delete(
    config.API_ENDPOINT + "admin/structures",
    {
      params: {
        customerID: customerID,
        structureID: structureID
      }
    }
  );
  return response.data;
}

async function deleteSensor(structID, customerID, sensorEUI) {
  const response = await axios.delete(config.API_ENDPOINT + "admin/sensors", {
    params: {
      structureID: structID,
      customerID: customerID,
      eui: sensorEUI
    }
  });
  return response.data;
}

async function resetSensorStats(structID, customerID, sensorEUI) {
  const response = await axios.delete(
    config.API_ENDPOINT + "admin/sensors/stats",
    {
      params: {
        structureID: structID,
        customerID: customerID,
        eui: sensorEUI
      }
    }
  );
  return response.data;
}

async function addSensors(customerID, structureID, sensorArray) {
  const response = await axios.post(config.API_ENDPOINT + "admin/sensors", {
    customerID: customerID,
    structureID: structureID,
    sensors: sensorArray
  });
  return response.data;
}

/* Consente di aggiungere un sensore collaudato ad una struttura */
async function addSensorsFromProvisioning(
  customerID,
  structureID,
  ids,
  bridgeSpans,
  bridgeSides
) {
  const response = await axios.post(
    config.API_ENDPOINT + "admin/devices/provisioning",
    {
      customerID: customerID,
      structureID: structureID,
      ids: ids,
      bridgeSpans: bridgeSpans || [],
      bridgeSides: bridgeSides || []
    }
  );
  return response.data;
}

async function updateSensor(customerID, eui, sensorInfo) {
  const response = await axios.put(
    config.API_ENDPOINT + "admin/sensors",
    sensorInfo,
    {
      params: {
        customerID: customerID,
        eui: eui
      }
    }
  );
  return response.data;
}

// Cancella gli eventi precedenti alla data d'installazione
async function deleteOldEvents(customerID, structID) {
  const response = await axios.delete(
    config.API_ENDPOINT +
      "admin/users/" +
      customerID +
      "/structures/" +
      structID
  );
  return response.data;
}

/* Aggiorna i gruppi FDD del tipo selezionato */
async function updateFDDGroups(customerID, structureID, type, sensorGroups) {
  const response = await axios.put(
    config.API_ENDPOINT + "admin/sensors/groups",
    sensorGroups,
    {
      params: {
        type: type,
        customerID: customerID,
        structureID: structureID
      }
    }
  );
  return response.data;
}

/* Save Sensor Settings by Admin*/
async function adminSaveSensorsPosition(customerID, sensorPosition) {
  const response = await axios.put(
    config.API_ENDPOINT + "admin/sensors/position",
    sensorPosition,
    {
      params: {
        customerID: customerID
      }
    }
  );
  return response.data;
}

/* Ritorna i sensori disponibli da provisoning */
async function getAvailableDevices(type, euiSearchQuery, rev, region) {
  let params = { type: type, rev: rev, region: region };
  if (euiSearchQuery !== null && euiSearchQuery !== undefined) {
    params.eui = euiSearchQuery;
  }
  const response = await axios.get(
    config.API_ENDPOINT + "admin/devices/provisioning",
    {
      params: params
    }
  );
  return response.data;
}

/* Ritorna i dettagli di un sensore disponibile in provisioning */
async function getAvailableSensorDetails(eui) {
  const response = await axios.get(
    config.API_ENDPOINT + "admin/devices/provisioning/" + eui
  );
  return response.data;
}

/******************* ******************* ******************* ******************* *******************/
/* USER */
/******************* ******************* ******************* ******************* *******************/

/* Manifests list */
/**
 * @async
 * @function getManifests
 * @description Get manifests.
 * @param {{deviceType?: string, deviceRev?: string, manifestType?: string, rscType?: string}} params 
 * @returns {Promise<[]>} Response of http request.
 * - response.data -> contains an array of objects of all request
 */
async function getManifests(params = {deviceType: null, deviceRev: null, manifestType: null, rscType: null}) {
  const responseData = await axios.get(config.API_ENDPOINT + "manifests", {params});
  return responseData.data;
}

/* Structures List */
async function getStructuresList() {
  const responseData = await axios.get(config.API_ENDPOINT + "structures");
  return responseData.data;
}

/* Gateway List */
async function getGatewayList(structID) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "structures/" + structID + "/gateways"
  );
  return responseData.data;
}

/* Structure Details */
async function getStructureDetails(structID) {
  const responseData = await axios.get(config.API_ENDPOINT + "structures", {
    params: {
      id: structID
    }
  });
  return responseData.data;
}

/* Patch Structure */
async function patchStructure(structID, patchStructObj) {
  const response = await axios.patch(
    config.API_ENDPOINT + "config/structure/" + structID,
    patchStructObj
  );
  return response.data;
}

/* Delete Vibration by ID */
async function deleteVibrationEvent(eventID) {
  const responseData = await axios.delete(
    config.API_ENDPOINT + "sensor_data/vibrations/" + eventID
  );
  return responseData.data;
}

/* Delete Acceleration by ID */
async function deleteAccelerationEvent(eventID) {
  const responseData = await axios.delete(
    config.API_ENDPOINT + "sensor_data/accelerations/" + eventID
  );
  return responseData.data;
}

/* Temperatures List */
async function getTemperaturesList(eui, firstDate, lastDate) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/temperatures",
    {
      params: {
        eui: eui,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate)
      }
    }
  );
  return responseData.data;
}

/* Temperatures List */

/* Displacements List */
async function getDisplacementsList(
  eui,
  firstDate,
  lastDate,
  itemsPerPage,
  pageNumber
) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/vibrations",
    {
      params: {
        eui: eui,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate),
        limit: itemsPerPage,
        page: pageNumber
      }
    }
  );
  return responseData.data;
}

/* Displacements Details */
async function getDisplacementsDetails(id, eui) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/vibrations",
    {
      params: {
        id: id,
        eui: eui
      }
    }
  );
  return responseData.data;
}

/* Acceleration Details */
async function getAccelerationDetails(id, eui) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/accelerations",
    {
      params: {
        id: id,
        eui: eui
      }
    }
  );
  return responseData.data;
}

/* Tilts List */
async function getTiltsList(
  eui,
  firstDate,
  lastDate,
  absoluteMode,
  beamFormat
) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/tilts",
    {
      params: {
        eui: eui,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate),
        absoluteMode: absoluteMode,
        beamFormat: beamFormat
      }
    }
  );
  return responseData.data;
}

/* Cracks List */
async function getCracksList(eui, firstDate, lastDate) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/cracks",
    {
      params: {
        eui: eui,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate)
      }
    }
  );
  //await timeout(8000);

  return responseData.data;
}

/* Analog Node Readings List */
async function getAnalogReadings(eui, firstDate, lastDate, absoluteMode = false) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/analog-node",
    {
      params: {
        eui: eui,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate),
        absoluteMode
      }
    }
  );
  return responseData.data;
}

/* PPVs List (allFormat === true) -> ritorna i dati in un unico array (eventualmente solo i max) senza distinguere under/over threshold */
async function getPPVs(
  eui,
  firstDate,
  lastDate,
  allFormat = false,
  mesUnit = "mm/s"
) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/ppvs",
    {
      params: {
        eui: eui,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate),
        all: allFormat,
        mesUnit: mesUnit
      }
    }
  );
  return responseData.data;
}

/* Accelerations List */
async function getAccelerationsList(eui, firstDate, lastDate) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/accelerations",
    {
      params: {
        eui: eui,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate)
      }
    }
  );
  return responseData.data;
}

/* Temperatures List */
async function getFDDList(structureID, limit, firstDate, lastDate) {
  const responseData = await axios.get(config.API_ENDPOINT + "structures/fdd", {
    params: {
      structureID: structureID,
      limit: limit,
      firstDate: new Date(firstDate),
      lastDate: new Date(lastDate)
    }
  });
  return responseData.data;
}

async function getAlertList(
  structureID,
  itemsPerPage,
  pageNumber /* , firstDate, lastDate */
) {
  const responseData = await axios.get(
    config.API_ENDPOINT + "sensor_data/alerts",
    {
      params: {
        structureID: structureID,
        limit: itemsPerPage,
        page: pageNumber /* ,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate) */
      }
    }
  );
  return responseData.data;
}

/* Single Trend */
async function getTrend(eui, firstDate, lastDate) {
  const response = await axios.get(config.API_ENDPOINT + "sensor_data/trend", {
    params: {
      eui: eui,
      firstDate: new Date(firstDate),
      lastDate: new Date(lastDate)
    }
  });
  return response.data;
}

/* Multiple Trend */
async function getMultipleTrends(euis, firstDate, lastDate) {
  const promises = euis.map(eui => getTrend(eui, firstDate, lastDate));
  return await Promise.all(promises);
}

/* FFT */
async function getFFT(data) {
  const response = await axios.post(config.FFT_ENDPOINT, data);
  return response.data;
}

/* DECKPOST POST-PROCESSING */

async function getDeflection(payload) {
  const response = await axios.post(
    config.DECKPOST_ENDPOINT + "deflection/deflectionFinder",
    payload
  );
  return response.data;
}

async function getFreqCluster(
  firstDate,
  lastDate,
  structureID,
  fddType,
  groupNumber,
  axis
) {
  const body = {
    fddType: fddType,
    structureID: structureID,
    group: groupNumber,
    startDate: firstDate,
    endDate: lastDate,
    axis: axis
  };
  const response = await axios.post(
    config.DECKPOST_ENDPOINT + "frequencyclustering/clustering",
    body
  );
  return response.data;
}

async function getBeamPolyline(angles, lengths) {
  const body = {
    angles: angles,
    lengths: lengths
  };
  const response = await axios.post(
    config.DECKPOST_ENDPOINT + "beampolyline/polyline",
    body
  );
  return response.data;
}

/* Get Sub Users */
async function getSubUsers() {
  const response = await axios.get(config.API_ENDPOINT + "users");
  return response.data;
}

/* Insert a New Sub User */
async function insertNewSubUser(username, password) {
  const response = await axios.post(config.AUTH_ENDPOINT + "users", {
    username: username,
    password: password
  });
  return response.data;
}

/* Validate new sub user */
async function validateSubUser(username = "") {
  const request = axios.create();
  return request.get(config.API_ENDPOINT + `users/check/${username}`);
}

/* Update an exsisting Sub User */
async function updateSubUser(subUserID, username, password) {
  console.log(subUserID, username, password);
  const response = await axios.put(
    config.AUTH_ENDPOINT + "users",
    {
      username: username,
      password: password
    },
    { params: { subUserID: subUserID } }
  );
  return response.data;
}

/* Delete an exsisting Sub User */
async function deleteSubUser(subUserID) {
  const response = await axios.delete(config.AUTH_ENDPOINT + "users", {
    params: { subUserID: subUserID }
  });
  return response.data;
}

/* Get Sensor Settings by StructureID */
async function getSensorsSettings(structID) {
  const response = await axios.get(config.API_ENDPOINT + "config/sensors", {
    params: { structID: structID }
  });
  return response.data;
}

/* Get User Settings by StructureID */
async function getUserSettings() {
  const response = await axios.get(config.API_ENDPOINT + "config/user");
  return response.data;
}

/* Save User settings */
async function saveUserSettings(userSettings) {
  const response = await axios.put(
    config.API_ENDPOINT + "config/user",
    userSettings
  );
  return response.data;
}

/* Patch User settings */
async function patchUserSettings(userSettings) {
  const response = await axios.patch(
    config.API_ENDPOINT + "config/user",
    userSettings
  );
  return response.data;
}

/* Save Sensor Settings */
async function saveSensorsSettings(sensorSettings) {
  const settings = sensorSettings;
  const response = await axios.put(
    config.API_ENDPOINT + "config/sensors",
    settings
  );
  return response.data;
}

/* Save Sensor Settings */
async function saveSensorsPosition(sensorPosition) {
  const response = await axios.put(
    config.API_ENDPOINT + "config/sensors/position",
    sensorPosition
  );
  return response.data;
}

/* Save Struct Settings */
async function saveStructSettings(structSettings) {
  const response = await axios.put(
    config.API_ENDPOINT + "config/structure",
    structSettings
  );
  return response.data;
}

/* Reset Sensor Base Value */
async function resetOffset(euis) {
  const response = await axios.put(
    config.API_ENDPOINT + "config/sensors/basevalue",
    { euis: euis }
  );
  return response.data;
}

/* Reset Sensor Base Value */
async function undoOffset(euis) {
  const response = await axios.delete(
    config.API_ENDPOINT + "config/sensors/basevalue",
    {data: { euis }}
  );
  return response.data;
}

async function deleteImageFromStructureUser(customerID, structureID, imageID) {
  const response = await axios.delete(
    config.API_ENDPOINT + "structures/image",
    {
      params: {
        customerID: customerID,
        structureID: structureID,
        imageID: imageID
      }
    }
  );
  return response.data;
}

async function addImageToStructureUser(customerID, structureID, imageFile) {
  var bodyFormData = new FormData();
  bodyFormData.append("image", imageFile);
  const response = await axios({
    method: "post",
    url: config.API_ENDPOINT + "structures/image",
    data: bodyFormData,
    params: {
      customerID: customerID,
      structureID: structureID
    },
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
}

/******************* ******************* ******************* ******************* *******************/
/* GROUPS ADMIN */
/******************* ******************* ******************* ******************* *******************/
//GET GROUPS FROM ADMIN
async function getGroups(
  data = {
    url: {
      customerID: "",
      structureID: ""
    },
    params: {
      limit: 0,
      page: 0
    }
  }
) {
  const response = await axios({
    method: "get",
    url: `${config.API_ENDPOINT_V3}customers/${data.url.customerID}/structures/${data.url.structureID}/groups`,
    params: data.params
  });
  return response.data;
}
//CREATE GROUP FROM ADMIN
async function createGroup(
  data = {
    url: {
      customerID: "",
      structureID: ""
    },
    body: {
      type: "",
      notes: "",
      name: "",
      devices: []
    }
  }
) {
  const response = await axios({
    method: "post",
    url: `${config.API_ENDPOINT_V3}customers/${data.url.customerID}/structures/${data.url.structureID}/groups`,
    data: data.body
  });
  return response.data;
}
//PUT GROUP FROM ADMIN
async function putGroup(
  data = {
    url: {
      customerID: "",
      structureID: "",
      groupID: ""
    },
    body: {
      _id: "",
      type: "",
      notes: "",
      name: "",
      devices: []
    }
  }
) {
  const response = await axios({
    method: "put",
    url: `${config.API_ENDPOINT_V3}customers/${data.url.customerID}/structures/${data.url.structureID}/groups/${data.url.groupID}`,
    data: data.body
  });
  return response.data;
}
//DELETE GROUP FROM ADMIN
async function deleteGroup(
  data = {
    url: {
      customerID: "",
      structureID: "",
      groupID: ""
    }
  }
) {
  const response = await axios({
    method: "delete",
    url: `${config.API_ENDPOINT_V3}customers/${data.url.customerID}/structures/${data.url.structureID}/groups/${data.url.groupID}`
  });
  return response.data;
}
//VALIDATE GROUP NAME FROM ADMIN
async function validateGroupName(
  data = {
    url: {
      customerID: "",
      structureID: "",
      name: "",
      type: ""
    },
    params: {
      type: null
    }
  }
) {
  const params = data.params.type ? data.params : {};
  const response = await axios({
    method: "get",
    url: `${config.API_ENDPOINT_V3}customers/${data.url.customerID}/structures/${data.url.structureID}/groups/check/${data.url.name}`,
    params: params
  });
  return response.data;
}

async function getTiltsData(
  data = {
    url: {
      eui: ""
    },
    params: {
      firstDate: new Date(),
      lastDate: new Date(),
      decimation: "date-range"
    }
  }
) {
  data.params.decimation = "date-range";
  return axios.get(
    `${config.API_ENDPOINT_V3}sensors/${data.url.eui}/measurements/tilts`,
    { params: data.params }
  );
}

/******************* ******************* ******************* ******************* *******************/
/* GROUPS USER */
/******************* ******************* ******************* ******************* *******************/
//GET FDD GROUPS FROM USER
async function getFddGroupsFromUser(
  data = {
    url: {
      structureID: ""
    },
    params: {
      limit: 0,
      page: 0,
      type: "fdd"
    }
  }
) {
  const response = await axios({
    method: "get",
    url: `${config.API_ENDPOINT_V3}/structures/${data.url.structureID}/groups`,
    params: data.params
  });
  return response.data;
}
//GET RAIL SECTIONS FROM USER
async function getRailSectionsFromUser(
  data = {
    url: {
      structureID: ""
    },
    params: {
      limit: 0,
      page: 0
    }
  }
) {
  const railParams = { ...data.params, ...{ type: "rail-section" } };
  console.log("railParams", railParams);
  return axios({
    method: "get",
    url: `${config.API_ENDPOINT_V3}/structures/${data.url.structureID}/groups`,
    params: railParams
  });
}
//CREATE FDD GROUP FROM USER
async function createFddGroupFromUser(
  data = {
    url: {
      structureID: ""
    },
    body: {
      type: "fdd",
      notes: "",
      name: "",
      devices: []
    }
  }
) {
  return axios({
    method: "post",
    url: `${config.API_ENDPOINT_V3}/structures/${data.url.structureID}/groups`,
    data: data.body
  });
}
//CREATE RAIL SECTION FROM USER
async function createRailSectionFromUser(
  data = {
    url: {
      structureID: ""
    },
    body: {
      enabled: false,
      sleepersLength: 0,
      sectionLength: 0,
      direction: [],
      order: 0,
      notes: "",
      name: "",
      sleepers: [],
      beamSections: []
    }
  }
) {
  const railBody = { ...data.body, ...{ type: "rail-section" } };
  return axios({
    method: "post",
    url: `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/groups`,
    data: railBody
  });
}
//PUT GROUP FROM USER
async function putFddGroupFromUser(
  data = {
    url: {
      structureID: "",
      groupID: ""
    },
    body: {
      type: "fdd",
      notes: "",
      name: "",
      devices: []
    }
  }
) {
  return axios({
    method: "put",
    url: `${config.API_ENDPOINT_V3}/structures/${data.url.structureID}/groups/${data.url.groupID}`,
    data: data.body
  });
}
//PUT RAIL SECTION FROM USER
async function putRailSectionFromUser(
  data = {
    url: {
      structureID: "",
      groupID: ""
    },
    body: {
      enabled: false,
      sleepersLength: 0,
      sectionLength: 0,
      direction: [],
      order: 0,
      notes: "",
      name: "",
      sleepers: [],
      beamSections: []
    }
  }
) {
  const railBody = { ...data.body, ...{ type: "rail-section" } };
  return axios.put(
    `${config.API_ENDPOINT_V3}/structures/${data.url.structureID}/groups/${data.url.groupID}`,
    railBody
  );
}
//DELETE GROUP FROM USER
async function deleteGroupFromUser(
  data = {
    url: {
      structureID: "",
      groupID: ""
    }
  }
) {
  return await axios({
    method: "delete",
    url: `${config.API_ENDPOINT_V3}/structures/${data.url.structureID}/groups/${data.url.groupID}`
  });
}
//VALIDATE GROUP NAME FROM USER
async function validateGroupNameFromUser(
  data = {
    url: {
      structureID: "",
      name: ""
    },
    params: {
      type: null
    }
  }
) {
  const params = data.params.type ? data.params : {};
  const response = await axios({
    method: "get",
    url: `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/groups/check/${data.url.name}`,
    params: params
  });
  return response.data;
}

async function moveSections(
  data = {
    url: {
      structureID: "",
      groupID: ""
    },
    params: {
      direction: "up"
    }
  }
) {
  return axios.post(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/groups/${data.url.groupID}/move`,
    null,
    { params: data.params }
  );
}

/******************* ******************* ******************* ******************* *******************/
/* RAIL */
/******************* ******************* ******************* ******************* *******************/

/* CANT */
/******************* ******************* ******************* ******************* *******************/
async function getCantEvents(
  data = {
    url: {
      structureID: ""
    },
    params: {
      group: null,
      firstDate: new Date(),
      lastDate: new Date(),
      decimation: "date-range"
    }
  }
) {
  data.params.decimation = "date-range";
  return axios.get(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/tools/rail/cant`,
    { params: data.params }
  );
}

async function getCantSpace(
  data = {
    url: {
      structureID: "",
      eventID: ""
    }
  }
) {
  return axios.get(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/tools/rail/cant/${data.url.eventID}`
  );
}

async function getCantTimeDomain(
  data = {
    url: {
      structureID: ""
    },
    params: {
      group: null,
      eui: null,
      firstDate: new Date(),
      lastDate: new Date(),
      decimation: "date-range"
    }
  }
) {
  data.params.decimation = "date-range";
  return axios.get(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/tools/rail/cant`,
    { params: data.params }
  );
}

async function getTemperatures(
  data = {
    url: {
      eui: ""
    },
    params: {
      firstDate: new Date(),
      lastDate: new Date(),
      decimation: "date-range"
    }
  }
) {
  data.params.decimation = "date-range";
  return axios.get(
    `${config.API_ENDPOINT_V3}sensors/${data.url.eui}/measurements/temperatures`,
    { params: data.params }
  );
}

/* TWIST */
/******************* ******************* ******************* ******************* *******************/
async function getTwistEvents(
  data = {
    url: {
      structureID: ""
    },
    params: {
      group: null,
      firstDate: new Date(),
      lastDate: new Date(),
      decimation: "date-range"
    }
  }
) {
  data.params.decimation = "date-range";
  return axios.get(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/tools/rail/twist`,
    { params: data.params }
  );
}

async function getTwistSpace(
  data = {
    url: {
      structureID: "",
      eventID: ""
    }
  }
) {
  return axios.get(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/tools/rail/twist/${data.url.eventID}`
  );
}

async function getTwistTimeDomain(
  data = {
    url: {
      structureID: ""
    },
    params: {
      group: null,
      euis: [],
      firstDate: new Date(),
      lastDate: new Date(),
      decimation: "date-range"
    }
  }
) {
  data.params.decimation = "date-range";
  return axios.get(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/tools/rail/twist`,
    { params: data.params }
  );
}

/* BEAM POLYLINE */
/******************* ******************* ******************* ******************* *******************/
async function getBeamPolylineEvents(
  data = {
    url: {
      structureID: ""
    },
    params: {
      group: null,
      firstDate: new Date(),
      lastDate: new Date(),
      decimation: "date-range"
    }
  }
) {
  data.params.decimation = "date-range";
  return axios.get(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/tools/rail/beampolyline`,
    { params: data.params }
  );
}

async function getBeamPolylineSpaceDomain(
  data = {
    url: {
      structureID: "",
      eventID: ""
    }
  }
) {
  return axios.get(
    `${config.API_ENDPOINT_V3}structures/${data.url.structureID}/tools/rail/beampolyline/${data.url.eventID}`
  );
}

/******************* ******************* ******************* ******************* *******************/
/* EULA */
/******************* ******************* ******************* ******************* *******************/
async function acceptUserEula() {
  return axios.post(config.API_ENDPOINT + "eula");
}

/******************* DOWNLOAD PAGE *******************/

/**
 * @async
 * @function dpGetState
 * @description Get the state as array of object of all request.
 * @param {string} auth_token the authorization token
 * @param {{structure: string}} formdata the form-data of post request
 * - structure -> the structure id
 * @returns {Promise<[]>} Response of http request.
 * - response.data -> contains an array of objects of all request
 */
async function dpGetState(auth_token = "", formdata = { structure: "" }) {
  const bodyFormData = new FormData();

  for (const [key, value] of Object.entries(formdata)) {
    bodyFormData.append(key, value);
  }

  return await axios({
    method: "post",
    url: config.DP_ENDPOINT + "/getstate/",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: auth_token
    }
  });
}

/**
 * @async
 * @function dpDeleteItem
 * @description Delete a item
 * @param {string} auth_token the authorization token
 * @param {{_id: string}} params The params of get request
 * @returns {Promise<[]>} Response of http request.
 * - response.data -> contains the item deleted
 */
async function dpDeleteItem(auth_token = "", url = { _id: "" }) {
  return await axios({
    method: "get",
    url: `${config.DP_ENDPOINT}/deletehistory/${url._id}/`,
    headers: {
      Authorization: auth_token
    }
  });
}

/**
 * @async
 * @function dpRequestFile
 * @description Start a request of file
 * @param {string} auth_token the authorization token.
 * @param {{start_date: string, end_date: string, overwrite_history: boolean, dir_name: string, eui_list: [], structure: string}} formdata the form-data of post request
 * @param {{format: string}} url the url of post request
 * - structure -> the structure id
 * @returns {Promise<[]>} Response of http request.
 * - response.data -> contains the object requested
 */
async function dpRequestFile(
  auth_token = "",
  formdata = {
    start_date: "",
    end_date: "",
    dir_name: "",
    structure: "",
    overwrite_history: true,
    eui_list: []
  },
  url = {
    format: "csv"
  }
) {
  const bodyFormData = new FormData();

  for (const [key, value] of Object.entries(formdata)) {
    if (key === "eui_list") {
      formdata.eui_list.forEach(eui => {
        bodyFormData.append("eui_list[]", eui);
      });
      continue;
    }
    bodyFormData.append(key, value);
  }

  console.log("formdata", formdata);

  return await axios({
    method: "post",
    url: `${config.DP_ENDPOINT}/getfile/${url.format}/`,
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: auth_token
    }
  });
}

/**
 * @async
 * @function getFileUrl
 * @description Get the url to download the file. Note that the link is valid until one hour
 * @param {string} auth_token the authorization token
 * @param {{workerID: string}} url The url of get request
 * @returns {Promise<[]>} Response of http request.
 * - response.data -> contains the url and token
 */
async function getFileUrl(auth_token = "", url = { workerID: "" }) {
  return await axios({
    method: "get",
    url: `${config.DP_ENDPOINT}/download/${url.workerID}/`,
    withCredentials: true,
    headers: {
      Authorization: auth_token
    }
  });
}

/**
 * @async
 * @function dpDownloadFile
 * @description Download file
 * @param {url} url the authorization token
 * @param {{workerID: string}} url The url of get request
 * @returns {Promise<[]>} Response of http request.
 * - response.data -> contains the attached file requested
 */
async function dpDownloadFile(url = "", options = { cookie: "" }) {
  console.log(options, url);

  const requestDownload = axios.create();

  delete requestDownload.defaults.headers.common["Authorization"];
  requestDownload.defaults.withCredentials = true;

  return await requestDownload.get(url, {
    headers: {
      Accept: "*/*"
    },
    withCredentials: true,
    responseType: "blob"
  });
}

module.exports = {
  getManifests,
  getStructuresList,
  getGatewayList,
  getStructureDetails,
  patchStructure,
  deleteVibrationEvent,
  deleteAccelerationEvent,
  getTemperaturesList,
  getDisplacementsList,
  getDisplacementsDetails,
  getAccelerationDetails,
  getTiltsList,
  getTiltsData,
  getCracksList,
  getAnalogReadings,
  getPPVs,
  getAccelerationsList,
  getMultipleTrends,
  getFFT,
  getDeflection,
  getFreqCluster,
  getBeamPolyline,
  getFDDList,
  getAlertList,
  getUserSettings,
  getSensorsSettings,
  saveUserSettings,
  patchUserSettings,
  saveSensorsSettings,
  saveSensorsPosition,
  saveStructSettings,
  resetOffset,
  undoOffset,
  login,
  logout,
  refreshToken,
  getSubUsers,
  insertNewSubUser,
  validateSubUser,
  updateSubUser,
  deleteSubUser,
  /* ADMIN */
  loginAs,
  getUsers,
  getNsUsage,
  getStructures,
  getGateways,
  putGateways,
  deleteGateway,
  addGateway,
  insertStructure,
  editStructure,
  deleteStructure,
  deleteImageFromStructure,
  deleteImageFromStructureUser,
  addImageToStructure,
  addImageToStructureUser,
  deleteUser,
  addUser,
  editUser,
  deleteSensor,
  addSensors,
  addSensorsFromProvisioning,
  updateSensor,
  resetSensorStats,
  adminSaveSensorsPosition,
  updateFDDGroups,
  deleteOldEvents,
  getAvailableDevices,
  getAvailableSensorDetails,
  acceptUserEula,
  /* GROUPS ADMIN */
  getGroups,
  createGroup,
  putGroup,
  deleteGroup,
  validateGroupName,
  /* GROUPS USER */
  validateGroupNameFromUser,
  deleteGroupFromUser,
  putFddGroupFromUser,
  putRailSectionFromUser,
  createRailSectionFromUser,
  createFddGroupFromUser,
  getFddGroupsFromUser,
  getRailSectionsFromUser,
  moveSections,
  /* RAIL */
  getCantEvents,
  getTwistEvents,
  getTwistSpace,
  getTwistTimeDomain,
  getCantSpace,
  getCantTimeDomain,
  getTemperatures,
  getBeamPolylineEvents,
  getBeamPolylineSpaceDomain,
  /* DOWNLOAD PAGE*/
  dpGetState,
  dpDeleteItem,
  dpRequestFile,
  dpDownloadFile,
  getFileUrl
};
