import axios from "axios";
/* import config from "../../config"; */
import tokenHelper from "../../helpers/tokenHelper";
import api from "../../services/api";

import interceptorModule from "../../helpers/interceptor";
const namespaced = true;

const state = {
  status: "",
  token: localStorage.getItem("token") || "",
  isAdmin: false,
  refreshing: false,
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  isAdmin: (state) => state.isAdmin,
  authStatus: (state) => state.status,
  isRefreshing: (state) => state.refreshing,
};

const actions = {
  async login({ commit, dispatch }, user) {
    try {
      commit("authRequest");
      const resp = await api.login(user.username, user.password);
      const token = resp.token;
      const refreshToken = resp.refreshToken;
      const userID = resp.id;
      const isAdmin = tokenHelper.isAdmin(token)
      localStorage.setItem("token", token);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("userID", userID);
      localStorage.setItem("username", user.username);
      dispatch("user/setUserStatus", { firstAccess: resp.firstAccess, passwordChanged: resp.passwordChanged }, { root: true });
      //localStorage.setItem("firstAccess", resp.firstAccess);        // Primo accesso
      //localStorage.setItem("passwordChanged", resp.passwordChanged);// Password di default cambiata
      //axios.defaults.headers.common["Authorization"] = token;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      commit("setAdminFlag", isAdmin);
      interceptorModule();
      commit("authSuccess", token, user.username);
    } catch (error) {
      commit("authError");
      localStorage.removeItem("token");
      throw error;
    }
  },
  async logout() {
    let refresh_token = localStorage.getItem("refresh_token");
    try {
      await api.logout(refresh_token);
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("username");
      localStorage.removeItem("userID");
      localStorage.removeItem("customUserLogoURL");
      localStorage.removeItem("hideEula");
      localStorage.removeItem("railEnabled");
      //localStorage.removeItem("skippedPasswordChange");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  },
  refreshToken({ commit }) {
    commit("setRefreshing", true);
    let userID = localStorage.getItem("userID");
    let refresh_token = localStorage.getItem("refresh_token");

    return new Promise((resolve, reject) => {
      return api
        .refreshToken(userID, refresh_token)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("setRefreshing", false);
        });
    });
  },
};

const mutations = {
  setAdminFlag: (state, flag) => (state.isAdmin = flag),
  authRequest: (state) => (state.status = "loading"),
  authSuccess: (state, token, user) => ((state.status = "success"), (state.token = token), (state.user = user)),
  authError: (state) => (state.status = "error"),
  logout: (state) => ((state.status = ""), (state.token = "")),
  setRefreshing: (state, status) => (state.refreshing = status),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
