function vectorsDot(v1, v2) {
  if (v1.length === 3 && v2.length === 3) {
    let sum = 0;
    for (let i = 0; i < 3; i++) {
      sum += v1[i] * v2[i];
    }
    return sum;
  } else {
    throw new Error("Vettori di lunghezza errata!");
  }
}

function vectorsCross(v1, v2) {
  if (v1.length === 3 && v2.length === 3) {
    return [v1[1] * v2[2] - v1[2] * v2[1], v1[2] * v2[0] - v1[0] * v2[2], v1[0] * v2[1] - v1[1] * v2[0]];
  } else {
    throw new Error("Vettori di lunghezza errata!");
  }
}

function get3dAngle(v1, v2, absoluteMode) {
  let vectorCross = vectorsCross(v1, v2);
  let crossSign = Math.sign(vectorCross[2]) < 0 ? -1 : 1;
  let result = Math.asin(Math.hypot(...vectorCross) / (Math.hypot(...v1) * Math.hypot(...v2))) * (180 / Math.PI);
  return {
    tilt: absoluteMode ? Math.abs(result * crossSign) : result * crossSign,
    versor: vectorCross,
  };
}

function getinitPhiDeegres(v1) {
  return Math.atan2(v1[0], Math.hypot(v1[1], v1[2])) * (180 / Math.PI);
}

function getInitThetaDeegres(v1) {
  return Math.atan2(v1[1], Math.hypot(v1[0], v1[2])) * (180 / Math.PI);
}

function getSubAnglePhi(v1, v2) {
  let phi2 = Math.atan2(v2[2], Math.hypot(v2[1], v2[0]));
  let phi1 = Math.atan2(v1[2], Math.hypot(v1[1], v1[0]));
  return (phi2 - phi1) * (180 / Math.PI);
}

function getSubAngleTheta(v1, v2) {
  let theta2 = Math.atan2(v2[1], Math.hypot(v2[0], v2[2]));
  let theta1 = Math.atan2(v1[1], Math.hypot(v1[0], v1[2]));
  return (theta2 - theta1) * (180 / Math.PI);
}

function degreesToMM(angle) {
  return (angle / (180 / Math.PI)) * 1000;
}
function mmTODegrees(angle) {
  return (angle * (180 / Math.PI)) / 1000;
}

function mmToInch(value = 0, decimals = -1){
  return decimals < 0 
    ? value / 25.4
    : round(value / 25.4, decimals);
}

function inchToMm(value = 0, decimals = -1){
  return decimals < 0 
    ? value * 25.4
    : round(value * 25.4, decimals);
}

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

function toFixedIfNecessary(value, dp) {
  return +parseFloat(value).toFixed(dp);
}

function randomNumbers(n = 10, range = [-100, 100]){
  return [...Array(n)].map(() => {
    return Math.floor(Math.random() * (range[1] - range[0] - 1) + range[0])
  });

}

module.exports = {
  vectorsDot,
  getSubAnglePhi,
  getSubAngleTheta,
  getinitPhiDeegres,
  getInitThetaDeegres,
  degreesToMM,
  mmTODegrees,
  get3dAngle,
  round,
  toFixedIfNecessary,
  mmToInch,
  inchToMm,
  randomNumbers
};
