<template>
  <div class="m-container" v-on="$listeners">
    <move-btn :disabled="disableUp" icon small @click="$emit('up')">
      <font-awesome-icon icon="fa-solid fa-angle-up" />
    </move-btn>
    <move-btn :disabled="disableDown" icon small @click="$emit('down')">
      <font-awesome-icon icon="fa-solid fa-angle-down" />
    </move-btn>
  </div>
</template>

<script>
export default {
  name: "MoveSortActions",
  props: {
    disableUp: {
      type: Boolean,
      default: false,
    },
    disableDown: {
      type: Boolean, 
      default: false,
    }
  }
};
</script>

<style scoped>
.m-container{
  display: flex;
  flex-direction: column;
}
</style>
