<template>
  <div v-bind="$attrs" v-on="$listeners" :class="[this.bordered || this.outlined? 'outlined' : '', this.rounded? 'rounded':'', 'card']">
    <slot />
  </div>
</template>

<script>
export default {
  name: "MoveCard",
  props:{
    bordered:{
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    rounded:{
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.card {
  padding: var(--card-padding);
  background-color: var(--card-bg-color);
}
.outlined{
  border: var(--card-border);
}
.rounded{
  border-radius: var(--card-borer-radius);
}
</style>
