/* get sensors eui from all sleepers  */
/* ************************************************************** */
export function getSectionEUIs(section = {
  _id: null,
  enabled: true,
  sleepersLength: 0,
  sectionLength: 0,
  direction: [null, null],
  createdAt: null,
  order: 0,
  notes: '',
  name: '',
  sleepers: [],
  beamSections: []
}) {
  const sleepersEUIs = getSleepersEUIs(section.sleepers)
  const beamSectionsEUIs = getBeamSectionsEUIs(section.beamSections)
  return [...sleepersEUIs, ...beamSectionsEUIs]
}

/* get sensors eui from all sleepers  */
/* ************************************************************** */
export function getSleepersEUIs(sleepers = []) {
  return sleepers
    .map(s => s.device.eui)
}

/* get sensors eui from all beam sections  */
/* ************************************************************** */
export function getBeamSectionsEUIs(beamSections = []) {
  const beams = beamSections
    .map(b => b.beams)
    .flat()

  return (beams
    .map(b => b.device.eui))
}

export function getSectionSensors(sensors = [], section = {
  _id: null,
  enabled: true,
  sleepersLength: 0,
  sectionLength: 0,
  direction: [null, null],
  createdAt: null,
  order: 0,
  notes: '',
  name: '',
  sleepers: [],
  beamSections: []
}){
  const euis = getSectionEUIs(section);
  return sensors.filter(sensor => euis.includes(sensor.eui))
}

export function findSection(sections = [], id = null){
  return sections.find(section => section._id === id)
}

