<i18n>
{
    "en": {},
    "it": {}
}
</i18n>
<template>
  <!-- ------------------------------------------------------------------------- -->
  <!-- Table Container -->
  <!-- ------------------------------------------------------------------------- -->
  <div v-if="rows.length" class="table" :style="{ maxHeight: maxHeight, border: borderStyle }">
    <!-- ------------------------------------------------------------------------- -->
    <!-- Table Header -->
    <!-- ------------------------------------------------------------------------- -->
    <div
      class="table-header"
      :style="{
        gridTemplateColumns: getGridTemplateColumns,
        background: color.header,
        borderBottom: borderStyle,
      }"
    >
      <!-- Reorder items actions title -->
      <div v-if="orderActions">
        <move-text p></move-text>
      </div>

      <!-- Header -->
      <div v-for="item in header" :key="item.value" class="p8 oX">
        <move-text p>{{ item.label }}</move-text>
      </div>

      <!-- Actions title -->
      <div v-if="actions.length" class="p8 oX">
        <move-text p></move-text>
      </div>
    </div>

    <!-- ------------------------------------------------------------------------- -->
    <!-- Table Content -->
    <!-- ------------------------------------------------------------------------- -->
    <div class="table-content scroll">
      <!-- ------------------------------------------------------------------------- -->
      <!-- Table Row -->
      <!-- ------------------------------------------------------------------------- -->
      <div
        v-for="(row, index) in rows"
        :key="`row-${index}`"
        class="table-row"
        :style="{
          gridTemplateColumns: getGridTemplateColumns,
          background: getRowColor({ index, row }),
          borderBottom: index === rows.length - 1 ? 'none' : borderStyle,
        }"
        @mouseover="rowHoverIDX = index"
        @mouseleave="rowHoverIDX = -1"
        @click="onRowClick({ index, row })"
      >
        <!-- Sort row actions -->
        <move-sort-actions
          v-if="orderActions"
          class="cell"
          :disableUp="index === 0"
          :disableDown="index >= rows.length - 1"
          @mouseover="disableRowClickEvent = true"
          @mouseleave="disableRowClickEvent = false"
          @up="$emit('up', { index, row })"
          @down="$emit('down', { index, row })"
        ></move-sort-actions>

        <!-- Items -->
        <div
          v-for="(cell, index) in getCells(getRow(index))"
          :key="`cell-${index}`"
          class="cell"
        >
          <move-text p noWrap>{{ cell }}</move-text>
        </div>

        <move-menu-btn
          v-if="actions.length"
          class="cell"
          :options="actions"
          :value="{ row, index }"
          v-on="$listeners"
        ></move-menu-btn>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
export default {
  name: "move-table",
  props: {
    orderActions: {
      type: Boolean,
      default: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: String,
      default: "400px",
    },
    header: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
    color: {
      type: Object,
      default: () => ({
        header: "transparent",
        row: "transparent",
        rowHover: "transparent",
        rowClick: "transparent",
      }),
    },
    mapFunction: {
      type: Function,
      default: () => this.value,
    },
  },
  data() {
    return {
      rowHoverIDX: -1,
      rowClickIDX: -1,
      disableRowClickEvent: false,
      orderActionsLenght: "minmax(50px, 50px)",
      actionsLength: "minmax(100px, 100px)",
      borderStyle: "2px solid #ccc",
    };
  },
  computed: {
    getGridTemplateColumns() {
      return (
        `${this.orderActions ? this.orderActionsLenght : ""}` +
        this.header.map((h) => h.length).join(" ") +
        `${this.actions.length ? this.actionsLength : ""}`
      );
    },
    getRefActions() {
      return this.actions.map((_v, i) => `${uuidv4()}-${i}`);
    },
  },
  methods: {
    getRow(index) {
      return this.rows.map(this.mapFunction)[index];
    },
    getCells(row) {
      return this.header.map((h) => h.value).map((v) => row[v]);
    },
    getRowColor(payload = { index: -1, row: null }) {
      if (_.isEqual(this.value, payload.row)) {
        return this.color.rowClick;
      }
      if (this.rowHoverIDX === payload.index) {
        return this.color.rowHover;
      }
      return this.color.row;
    },
    onRowClick(payload = { index: -1, row: null }) {
      if (this.disableRowClickEvent) {
        return;
      }
      this.rowClickIDX = payload.index;
      this.$emit("input", payload.row);
      this.$emit("rowclick", payload);
    },
    onClickAction(ref, event, payload = { index: 0, row: null }) {
      this.$refs[ref][0].hide();
      this.$emit(event, payload);
    },
  },
};
</script>
<style scoped>
.table {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-x: auto;
}

.table .table-content {
  overflow-y: overlay;
}

.table .table-header {
  display: grid;
  border-bottom: 2px solid #ccc;
}

.table .table-content .table-row {
  display: grid;
  border-bottom: 2px solid #ccc;
}

.table .table-content .table-row:hover {
  cursor: pointer;
}

.table .table-content .table-row .cell {
  padding: 8px;
  overflow-x: auto;
}

</style>
