import api from "../../services/api";

const namespaced = true;

const state = {
  groupsInfo: {
    page: null,
    perPage: null,
    total: null,
    values: []
  },
  sync: false,
  isLoading: false,
};

const getters = {
  getGroupsInfo: (state) => state.groupsInfo,
  getSync: (state) => state.sync,
  getIsLoading: (state) => state.isLoading,
};

const actions = {
  async fetchGroups({ commit }, { structureID = '', userID = '', params = { limit: 50, page: 0 } } = {}) {
    if (!structureID || !userID) {
      console.warn("Invalid structureID or userID")
      return;
    }
    commit("setIsLoading", true)
    const response = await api.getGroups({ url: { customerID: userID, structureID: structureID }, params: { limit: params.limit, page: params.page } })
    commit("setGroupsInfo", response);
    commit("setSync", true)
    commit("setIsLoading", false)
  },

  async createGroup({ commit }, { structureID = '', userID = '', group = { name: '', type: '', notes: '', devices: [] } } = {}) {
    if (!structureID || !userID) {
      console.warn("Invalid structureID or userID")
      return;
    }
    commit("setIsLoading", true)
    await api.createGroup({ url: { customerID: userID, structureID: structureID }, body: { name: group.name, type: group.type, notes: group.notes, devices: group.devices } })
    commit("setSync", false)
    commit("setIsLoading", false)

  },

  async editGroup({ commit }, { structureID = '', userID = '', group = { _id: '', name: '', type: '', notes: '', devices: [] }} = {}) {
    if (!structureID || !userID) {
      console.warn("Invalid structureID or userID")
      return;
    }
    commit("setIsLoading", true)
    await api.putGroup({ url: { customerID: userID, structureID: structureID, groupID: group._id }, body: { _id: group._id, name: group.name, type: group.type, notes: group.notes, devices: group.devices } })
    commit("setSync", false)
    commit("setIsLoading", false)
  },


  async deleteGroup({ commit }, { structureID = '', userID = '', group = { _id: '' } }) {
    if (!structureID || !userID) {
      console.warn("Invalid structureID or userID")
      return;
    }
    commit("setIsLoading", true)
    await api.deleteGroup({ url: { customerID: userID, structureID: structureID, groupID: group._id } })
    commit("setSync", false)
    commit("setIsLoading", false)
  }
};

const mutations = {
  setGroupsInfo: (state, data) => (state.groupsInfo = data),
  setSync: (state, data) => (state.sync = data),
  setIsLoading: (state, data) => (state.isLoading = data),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
