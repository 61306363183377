<template>
  <div :key="getUuid">
    <div v-if="right" v-popover.right="{ name: id }" class="icon-container"><slot name="icon"></slot></div>
    <div v-else-if="left" v-popover.left="{ name: id }" class="icon-container"><slot name="icon"></slot></div>
    <div v-else-if="top" v-popover.right="{ name: id }" class="icon-container"><slot name="icon"></slot></div>
    <div v-else-if="bottom" v-popover.right="{ name: id }" class="icon-container"><slot name="icon"></slot></div>
    <div v-else v-popover.right="{ name: id }" class="icon-container"><slot name="icon"></slot></div>
    <move-pop-up :name="id" :ref="id" event="hover" :delay="100" transition="fade">
      <slot />
    </move-pop-up>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "MoveMenuBtn",
  props: {
    right: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      id:uuidv4(),
    };
  },
  computed:{
    getUuid(){
      if (this.right || this.left || this.top || this.bottom) {
        return uuidv4()
      }
      return 'id'
    }
  }
};
</script>

<style scoped>
.icon-container{
  display: inline;
  width: fit-content;
  padding-right: 4px;
}
</style>
