import api from "../../services/api";
const namespaced = true;

const state = {
  selectedSensor: null,
  dateRange: [],
  accelerations: [],
  vibrations: [],
  tilts: [],
  cracks: [],
  temperatures: [],
  analogs: [],
  PPVs: null,
  temperature: null,
  firstCrack: null,
  firstTilt: null,
  relativeMode: true,
  APILoaded: false,
  /* absolute3D: true */
};

const getters = {
  getDateRange: (state) => state.dateRange,
  getSelectedSensor: (state) => state.selectedSensor,
  getSensorType: (state) => (state.selectedSensor ? state.selectedSensor.type : null),
  getLoadingStatus: (state) => state.APILoaded,
  getTemperature: (state) => state.temperature,
  getAccelerations: (state) => state.accelerations,
  getVibrations: (state) => state.vibrations,
  getTemperatures: (state) => state.temperatures,
  getAnalogReadings: (state) => state.analogs,
  getPPVs: (state) => state.PPVs,
  getTilts: (state) => state.tilts.filter((a) => a.eui === state.selectedSensor.eui).map((t) => ({ ...t /* , extraAngle: state.absolute3D ? Math.abs(t.extraAngle) : t.extraAngle */ })),
  getCracks: (state) => state.cracks,
  getBaseValue: (state) => (state.selectedSensor ? state.selectedSensor.baseValue : 0),
  getRelativeMode: (state) => state.relativeMode,
  getIsThereData: (state) => {
    let check = false;
    if (state.selectedSensor && state.selectedSensor.type === "deck" && state.vibrations && state.vibrations.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "accelerometer" && state.accelerations && state.accelerations.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "tiltmeter" && state.tilts && state.tilts.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "crackmeter" && state.cracks && state.cracks.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "analog-node" && state.analogs && state.analogs.length > 0) check = true;
    if (state.selectedSensor && state.selectedSensor.type === "vibrometer" && state.PPVs && (state.PPVs.underThreshold.length > 0 || state.PPVs.overThreshold.length > 0)) check = true;
    return check;
  },
};

const actions = {
  async setSelectedSensor({ commit }, sensor) {
    commit("setSelectedSensor", sensor);
  },
  async setRelativeMode({ commit, dispatch }, mode) {
    commit("setRelativeMode", mode);
    dispatch("getEventsList");
  },
  async refreshSelectedSensorInfo({ commit, rootGetters }) {
    let sensors = rootGetters["structure/getSensors"];
    let sensor = sensors.find((x) => x.eui === state.selectedSensor.eui);
    commit("setSelectedSensor", sensor);
  },
  async setInitialSensor({ commit, rootGetters }) {
    let sensors = rootGetters["structure/getSensors"];
    commit("setSelectedSensor", sensors[1]);
  },
  async setDateRange({ commit }, values) {
    let firstDate = new Date(values[0]);
    let lastDate = new Date(values[1]);

    commit("setDateRange", [firstDate, lastDate]);
  },
  /* setAbsolute3dAngle({ commit }, value) {
    commit("set3dAngle", value)
  }, */
  async getEventsList({ commit, state, rootGetters }) {
    commit("setLoadingStatus", false);
    let temperatures;


    const userInterface = rootGetters['user/getInterface'];
    const velocity = userInterface && userInterface.dimensions && userInterface.dimensions.velocity 
      ? userInterface.dimensions.velocity 
      : 'mm/s';

    // Chiamo le API e genero le trace a secondo del tipo di sensore
    if (state.selectedSensor) {
      switch (state.selectedSensor.type) {
        case "analog-node": {
          const analogPromise = api.getAnalogReadings(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1], !state.relativeMode);
          const analogs = await analogPromise;
          commit("setAnalogs", analogs);
          break;
        }
        case "tiltmeter": {
          const tiltsPromise = api.getTiltsList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1], !state.relativeMode, state.selectedSensor.beamFormat);
          const tilts = await tiltsPromise;
          commit("setTilts", tilts);
          break;
        }
        case "deck": {
          const dispPromise = api.getDisplacementsList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1]);
          const disps = await dispPromise;
          commit("setVibrations", disps);
          break;
        }
        case "accelerometer": {
          const accelerationsPromies = api.getAccelerationsList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1]);
          const accs = await accelerationsPromies;
          commit("setAccelerations", accs);
          break;
        }
        case "crackmeter": {
          const cracksPromise = api.getCracksList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1]);
          const cracks = await cracksPromise;
          commit("setCracks", cracks);
          break;
        }
        case "vibrometer": {
          const ppvPromise = api.getPPVs(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1], false, velocity);
          const ppvs = await ppvPromise;
          commit("setPPVs", ppvs);
          break;
        }
      }

      // Temperature Fetching uguale per tutti i tipi di sensori tranne nodo analogico e vibrometer
      if (!["analog-node"].includes(state.selectedSensor.type)) {
        const tempPromise = api.getTemperaturesList(state.selectedSensor.eui, state.dateRange[0], state.dateRange[1]);
        const responseTemperature = await tempPromise;
        if (responseTemperature[0]) temperatures = responseTemperature;
        commit("setTemperatures", temperatures);
      }
    }

    commit("setLoadingStatus", true);
  },
};

const mutations = {
  setDateRange: (state, values) => (state.dateRange = values),
  setSelectedSensor: (state, sensor) => (state.selectedSensor = sensor),
  setLoadingStatus: (state, APILoaded) => (state.APILoaded = APILoaded),
  setTemperature: (state, temp) => (state.temperature = temp),
  setAccelerations: (state, acc) => (state.accelerations = acc),
  setVibrations: (state, vibs) => (state.vibrations = vibs),
  setTilts: (state, tilts) => (state.tilts = tilts),
  setAnalogs: (state, readings) => (state.analogs = readings),
  setCracks: (state, cracks) => (state.cracks = cracks),
  setPPVs: (state, PPVs) => (state.PPVs = PPVs),
  setTemperatures: (state, temps) => (state.temperatures = temps),
  setRelativeMode: (state, mode) => (state.relativeMode = mode),
  /* set3dAngle: (state, value) => (state.absolute3D = value) */
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
