import api from "../../services/api";
const namespaced = true;

const state = {
  mails: [],
  interface: {},
  firstAccess: false,
  passwordChanged: false,
  loaded: false,
  customUserLogoURL: null,
  eulaAccepted: false,
  userType: null
};

const getters = {
  getUserEulaAccepted: (state) => state.eulaAccepted,
  getLoadingStatus: (state) => state.loaded,
  getMails: (state) => state.mails,
  getInterface: (state) => state.interface,
  getUserType: (state) => state.userType,
  getUserFirstAccess: (state) => state.firstAccess,
  getPasswordChanged: (state) => state.passwordChanged,
  getUserID: () => localStorage.getItem("userID"),
  getUserLogoURL: (state) => localStorage.getItem("customUserLogoURL") || state.customUserLogoURL,
};

const actions = {
  async fetchUserSettings({ commit }) {
    commit("setLoadingStatus", false);
    const userSettings = await api.getUserSettings();
    commit("setUserPasswordChanged", userSettings.passwordChanged);
    commit("setUserSettings", userSettings);
    commit("setLoadingStatus", true);
    if (userSettings.customLogoURL) {
      localStorage.setItem("customUserLogoURL", userSettings.customLogoURL);
      commit("setUserCustomLogo", userSettings.customLogoURL);
    } else {
      localStorage.removeItem("customUserLogoURL");
    }
  },
  async saveUserSettings({ commit }, settings) {
    commit("setLoadingStatus", false);
    const response = await api.saveUserSettings(settings);
    const userSettings = await api.getUserSettings();
    commit("setUserSettings", userSettings);
    console.log(response);
    commit("setLoadingStatus", true);
  },
  async setUserStatus({ commit }, userStatus) {
    const firstAccess = userStatus.firstAccess;
    const passwordChanged = userStatus.passwordChanged;
    commit("setUserFirstAccess", firstAccess);
    commit("setUserPasswordChanged", passwordChanged);
  },
  async acceptUserEula({commit}){
    commit("setLoadingStatus", false);
    await api.acceptUserEula();
    const userSettings = await api.getUserSettings();
    commit("setUserSettings", userSettings);
    commit("setLoadingStatus", true);
  }
};

const mutations = {
  setUserSettings: (state, settings) => {
    state.mails = settings.mails;
    state.userType = settings.type;
    state.interface = settings.interface;
    state.eulaAccepted = settings.eulaAccepted
    if (state.interface && state.interface.dimensions && !state.interface.dimensions.velocity) {
      state.interface.dimensions.velocity = 'mm/s';
    }
  },
  setLoadingStatus: (state, status) => (state.loaded = status),
  setUserFirstAccess: (state, firstAccess) => (state.firstAccess = firstAccess),
  setUserPasswordChanged: (state, pswChanged) => (state.passwordChanged = pswChanged),
  setUserCustomLogo: (state, userLogoUrl) => (state.customUserLogoURL = userLogoUrl),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
