<template>
  <div class="main-container height rounded" v-bind="$attrs" v-on="$listeners" :class="[this.large ? 'large' : '']">
    <div class="progress height rounded" v-bind:style="{ width: width }"></div>
  </div>
</template>

<script>
export default {
  name: "MoveProgressBar",
  props: {
    large: Boolean,
    progress: {
      type: [Number, String],
    },
  },
  computed: {
    width() {
      const p = Number(this.progress);
      return ((p < 0 || p > 100) ? 0 : p.toFixed(1).toString()) + '%'
    },
  },
};
</script>

<style scoped>
.main-container {
  width: 100%;
  background: var(--progress-bar-bg);
  border: var(--progress-bar-border);
  min-width: var(--progress-bar-min-width);
}
.progress {
  background: var(--primary-color);
}
.rounded{
  border-radius: var(--progress-bar-border-radius);
}
.height{
  height: var(--progress-bar-height);
}

.large{
  width: 200px;
}

</style>
