<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="this.disabled"
    :class="[
      this.disabled ? 'disabled' : 'enabled',
      this.large ? 'large' : '', 
      this.small ? 'small' : '',
      this.outlined ? 'btn-outlined' : '',
      this.rounded ? 'btn-rounded' : '',
      this.icon ? 'btn-icon' : '',
      this.text ? 'btn-text' : '',
      this.primary ? 'btn-primary' : '',
      this.secondary ? 'btn-secondary' : '',
      this.secondary ? 'btn-secondary' : '',
      this.tertiary ? 'btn-tertiary': '',
      this.block ? 'btn-block': '',
      'btn',
    ]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "MoveBtn",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped>
.btn {
  outline: none;
  border: none;
  cursor: pointer;
  padding: var(--btn-padding);
}
.btn.large {
  padding: var(--btn-padding-large);
}
.btn.small {
  padding: 1px;
}
.btn-block{
  width: 100%
}
.btn-outlined {
  border: var(--btn-border);
}
.btn-rounded {
  border-radius: var(--btn-border-radius);
}
.btn-primary{
  color: var(--btn-color);
  background-color: var(--btn-bg-color);
  opacity: 1;
}
.btn-secondary{
  color: var(--btn-color);
  background-color: transparent;
  opacity: 1;
}
.btn-tertiary{
  color: var(--btn-color);
  background-color: #9699A2;
  opacity: 1;
}
.btn-text{
  color: var(--btn-color);
  background-color: transparent;
}
.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: transparent;
  color: var(--btn-color);
  border-radius: 50%;
  height: var(--btn-icon-size);
  width: var(--btn-icon-size);
}


.btn-icon.small {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: transparent;
  color: var(--btn-color);
  border-radius: 100%;
  height: 15px;
  width: 15px;
}
.disabled {
  opacity: 0.3;
  cursor: inherit;
}
.btn-icon.disabled{
  opacity: 0.6;
  background-color: transparent;
}
.enabled.btn-primary:hover {
  opacity: 1;
}
.enabled.btn-secondary:hover {
  opacity: 1;
}
.enabled.btn-secondary:active {
  transform: translateY(1px);
}
.enabled.btn-primary:active {
  transform: translateY(1px);
}
.enabled.btn-tertiary:hover {
  opacity: 1;
}
.enabled.btn-tertiary:active {
  transform: translateY(1px);
}
.enabled.btn-text:hover {
  background-color: var(--btn-bg-color-text-hover);
}

.enabled.btn-icon:hover {
  background-color: var(--btn-icon-bg-color-hover);
}
</style>