/* import api from "../../services/api"; */
import axios from "axios";
import config from "../../config";
/* import api from "../../services/api";
 */
const namespaced = true;

const state = {
  selectedDateRange: [],
  selectedEventsIDs: [],
  availableEvents: [],
  selectedSensors: [],
  singleDeflectionDate: new Date(),
  polylines: [],
  //loadingCount: 0,
  loadingCountPolyline: 0
};

const getters = {
  getDateRange: (state) => state.selectedDateRange,
  getSelectedEvents: (state) => state.selectedEventsIDs,
  getSelectedSensors: (state) => state.selectedSensors,
  getSingleDeflectionDate: (state) => state.singleDeflectionDate,
  getEvents: (state) => state.availableEvents,
  getPolylines: (state) => state.polylines,
  getLoadingCount: (state) => state.loadingCountPolyline, // + altri eventuali loading
  getLoadingCountPolyline: (state) => state.loadingCountPolyline
};

const actions = {
  async setDateRange({ commit }, dateRange) {
    commit("selectDateRange", dateRange);
  },
  async setSingleDeflectionDate({ commit }, date) {
    commit("setSingleDeflectionDate", date);
  },
  async setSelectedEvents({ commit }, events) {
    commit("selectEvents", events);
  },
  async setSelectedSensors({ commit }, sensors) {
    commit("selectSensors", sensors);
  },
  /* Ritorna la lista degli eventi disponibili */
  async fetchAvailableBeamDeflections({ commit }) {
    const euis = state.selectedSensors.map((sensor) => sensor.eui);
    // Se non ho euis è inutile che faccia la chiamata API
    if (euis.length === 0) {
      commit("setEvents", []);
      return;
    }
    const firstDate = new Date(state.singleDeflectionDate);
    firstDate.setHours(0, 0, 0, 0);
    const lastDate = new Date(state.singleDeflectionDate);
    lastDate.setHours(23, 59, 59, 999);
    const timeWindow = 100; // Offset massimo tra eventi in secondi      commit("setLoadingCount", state.loadingCount + 1);
    if (state.loadingCountPolyline === 0) { // Mi assicuro di non avere lavori in sospeso
      commit("setLoadingCountPolyline", state.loadingCountPolyline + 1);
      const workID = await axios.post(config.DECKTASK_ENDPOINT + "beampolyline", {
        euis: euis,
        firstDate: new Date(firstDate),
        lastDate: new Date(lastDate),
        timeWindow: timeWindow,
      });
      console.log("My WorkID", workID.data.taskID);
      if (state.loadingCountPolyline === 1) { // Mi assicuro di avere soltanto la prima chiamata 
        const pollingInterval = setInterval(async function() {
          try {
            const pollingResponse = await axios.get(config.DECKTASK_ENDPOINT + "beampolyline/" + workID.data.taskID);
            if (pollingResponse.status === 204) {
              console.log("Work not done");
            } else {
              console.log("Work Done", pollingResponse.data.Result.length);
              commit(
                "setEvents",
                pollingResponse.data.Result.filter((ev) => ev.Count === state.selectedSensors.length)
              );
              // Se non ho eventi buoni svuoto il buffer della spezzata
              if (state.availableEvents.length === 0) {
                commit("setPolylines", []);
              }
              commit("setLoadingCountPolyline", state.loadingCountPolyline - 1);
              clearInterval(pollingInterval);
            }
          } catch (err) {
            console.log("Error", err);
            commit("setLoadingCountPolyline", state.loadingCountPolyline - 1);
            clearInterval(pollingInterval);
          }
        }, 500);
      }
    }
  },
  /* Ritorna la spezzata vera e propria */
  async computePolyline({ commit }, keyDate) {
    const euis = state.selectedSensors.map((sensor) => sensor.eui);
    const firstDate = new Date(state.singleDeflectionDate);
    firstDate.setHours(0, 0, 0, 0);
    const lastDate = new Date(state.singleDeflectionDate);
    lastDate.setHours(23, 59, 59, 999);
    const timeWindow = 100; // Offset massimo tra eventi in secondi
    if (state.loadingCountPolyline === 0) { // Mi assicuro di non avere lavori in sospeso
      commit("setLoadingCountPolyline", state.loadingCountPolyline + 1);
      const workID = await axios.post(
        config.DECKTASK_ENDPOINT + "beampolyline",
        {
          euis: euis,
          firstDate: new Date(firstDate),
          lastDate: new Date(lastDate),
          timeWindow: timeWindow,
        },
        {
          params: {
            keyDate: keyDate,
          },
        }
      );
      console.log("My WorkID Event", workID.data.taskID);
      if (state.loadingCountPolyline === 1) { // Mi assicuro di avere soltanto la prima chiamata 
        const pollingInterval = setInterval(async function() {
          try {
            const pollingResponse = await axios.get(config.DECKTASK_ENDPOINT + "beampolyline/" + workID.data.taskID);
            if (pollingResponse.status === 204) {
              console.log("Work not done Event");
            } else {
              console.log("Work Done Event", pollingResponse.data.Result.length);
              commit("setPolylines", pollingResponse.data.Result);
              clearInterval(pollingInterval);
              commit("setLoadingCountPolyline", state.loadingCountPolyline - 1);
            }
          } catch (err) {
            console.log("Error", err);
            clearInterval(pollingInterval);
            commit("setLoadingCountPolyline", state.loadingCountPolyline - 1);
          }
        }, 500);
      }
    }
  },
};

const mutations = {
  selectDateRange: (state, range) => (state.selectDateRange = range),
  selectEvents: (state, IDs) => (state.selectedEventsIDs = IDs),
  selectSensors: (state, sensors) => (state.selectedSensors = sensors),
  setEvents: (state, events) => (state.availableEvents = events),
  setSingleDeflectionDate: (state, date) => (state.singleDeflectionDate = date),
  setPolylines: (state, lines) => (state.polylines = lines),
  //setLoadingCount: (state, count) => (state.loadingCount = count),
  setLoadingCountPolyline: (state, count) => (state.loadingCountPolyline = count),
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
