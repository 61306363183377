var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("label"),_c('div',{class:[
      _vm.large ? 'large' : '',
      _vm.small ? 'small' : '',
      'inpt-container',
      _vm.disabled ? 'disabled' : ''
    ],style:({
      borderColor: _vm.valid ? 'transparent' : _vm.invalidColor,
      backgroundColor: _vm.getBackgroundColor
    })},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.inputVal),expression:"inputVal",modifiers:{"number":true}}],class:['inpt'],style:({ color: this.color, backgroundColor: _vm.getBackgroundColor }),attrs:{"type":"number","step":"any","disabled":_vm.disabled},domProps:{"value":(_vm.inputVal)},on:{"input":function($event){if($event.target.composing){ return; }_vm.inputVal=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._t("default")],2),_c('div',{class:[
      'hint',
      _vm.disabled ? 'disabled' : ''
    ]},[_vm._t("hint")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }