<template>
  <div>
    <slot name="label"></slot>
    <div
      :class="[
        large ? 'large' : '',
        small ? 'small' : '',
        'inpt-container',
        disabled ? 'disabled' : ''
      ]"
      :style="{
        borderColor: valid ? 'transparent' : invalidColor,
        backgroundColor: getBackgroundColor
      }"
    >
      <input
        :class="['inpt']"
        :style="{ color: this.color, backgroundColor: getBackgroundColor }"
        type="number"
        step="any"
        v-model.number="inputVal"
        :disabled="disabled"
      />
        <slot class="maxWidth"/>
    </div>
    <div :class="[
        'hint',
        disabled ? 'disabled' : ''
      ]">
      <slot name="hint"></slot>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "MoveInput",
  data() {
    return {
      uuid: uuidv4()
    };
  },
  props: {
    color: {
      type: String,
      default: "#fff"
    },
    invalidColor: {
      type: String,
      default: "var(--error-color)"
    },
    inputValue: String,
    type: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    value: [String, Number],
    maxlength: {
      type: String,
      default: '10e'
    },
    secondary: {
      type: Boolean,
      default: false
    },
    tertiary: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    step: {
      type: String,
      default: "any"
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    getBackgroundColor() {
      if (this.tertiary) {
        return "var(--secondary-color-dark)";
      }
      if (this.secondary) {
        return "transparent";
      }
      return "var(--secondary-color-light)";
    }
  }
};
</script>

<style scoped>
.inpt-container.disabled {
  opacity: 0.6;
}
.inpt-container {
  box-sizing: border-box;
  display: flex;

  color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: center;
  border: 1px solid transparent;
  height: 35px;
  width: 100%;
}

.inpt-container .maxWidth {
  display: flex;
  max-width: 50px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: noWrap;
  margin-left: auto;
}

/* width */
.inpt-container .maxWidth::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: var(--secondary-color-dark);
  border-radius: 0px;

}

/* Track */
.inpt-container .maxWidth::-webkit-scrollbar-track {
  background: var(--secondary-color-dark);
  border-radius: 0px;

}
 
/* Handle */
.inpt-container .maxWidth::-webkit-scrollbar-thumb {
  background: var(--secondary-color-light); 
  border-radius: 0px;
}

.inpt {
  color: #fff;
  outline: none;
  border: none;
  width: 100%;
}

.inpt-container.small {
  padding: 2px 4px;
  height: 35px;
}

.inpt-container.large {
  padding: 8px 12px;
  height: 45px;
}

.hint.disabled{
  opacity: 0.4;
}
</style>
