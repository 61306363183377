<template>
  <div class="m-container">
    <!-- Wrapper -->
    <div class="m-content">
        <div class="message">
            <move-text h2>{{message}}</move-text>
        </div>
        <!-- Buttons -->
        <div class="btns">
          <move-btn secondary outlined rounded block @click="onDecline">Annulla</move-btn>
          <move-btn primary outlined rounded block @click="onConfirm">Conferma</move-btn>
        </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "MoveConfirmModal",
  props:{
    message: String
  },
  data() {
    return {
        my:""
    };
  },
  methods: {
    onDecline(){
        this.$emit("decline")
    },
    onConfirm(){
        this.$emit("confirm")
    }
  }
};
</script>
<style scoped>
.m-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.3);
}





.m-content {
  width: 20vw;
  height: 20vh;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  border: solid 0.1em white;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btns{
    display: grid;
    gap: 12px;
    grid-template-columns: 6fr 6fr;
}


</style>
