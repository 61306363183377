import { round, cloneDeep } from "lodash"

export function railSection(clientSection = {}){
    const section = cloneDeep(clientSection)
    const sleepers = section.sleepers.map((sleeper, index, sleepers) => {
        sleeper.distanceFromZero = index > 0 ? round(sleeper.distanceFromPrevious + sleepers[index - 1].distanceFromZero, 3):0;
        return sleeper
    }).map(sleeper => {
        delete sleeper.distanceFromPrevious;
        return sleeper
    })
    return {
        enabled: section.enabled || false,
        sleepersLength: section.sleepersLength || 0,
        sectionLength: section.sectionLength || 0,
        direction: section.direction || [null, null],
        name: section.name || null,
        notes: section.notes || '',
        sleepers,
        beamSections: section.beamSections || [],
    }

}