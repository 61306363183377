<template>
  <div class="line-plot-wrapper">
    <div :ref="this.uuid" :id="this.uuid"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist-min';
import { v4 as uuidv4 } from "uuid";
export default {
  name: "move-graph",
  props: {
    title: {
      type: String,
      default: "",
    },
    traces: {
      type: Array,
      default: () => [],
    },
    activePointOnClick: {
      type: Boolean,
      default: false,
    },
    threshold: Number, //Valore della threshold plottata sul grafico in rosso
    xTitle: String,
    yTitle: String,
    xRange: Array,
    yRange: Array,
    height: Number,
    showLegend: {
      type: Boolean,
      default: false,
    },
    colorway: {
      type: Array,
      default: () => ['#2391fe']
    },
    hovermode: {
      type: String,
      default: 'x'
    },
    hoverlabel: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      instance: null,
      uuid: uuidv4(),
      firstExec: true,
      loading: false,
      config: {
        responsive: true,
        toImageButtonOptions: {
          format: "png", //png, svg, jpeg, webp
          filename: "DeckPlot",
          width: 1100,
          scale: 1, // Multiply title/legend/axis/canvas sizes by this factor,
        },
      },
    };
  },
  computed: {
    chart: function () {
      return {
        //https://plotly.com/javascript/reference/layout/
        layout: {
          colorway: this.colorway,
          showlegend: this.showLegend,
          margin: {
            l: 80,
            r: 35,
            b: 70,
            t: 20,
            pad: 2,
          },
          title: {
            text: this.title,
            font: {
              family: "Poppins",
              size: 22,
            },
          },
          autosize: true,
          xaxis: {
            tickangle: 0,
            tickfont: { family: "Poppins", size: 12 },
            title: {
              text: this.xTitle,
              font: {
                family: "Poppins",
                size: 16,
              },
            },
            gridcolor: "rgba(150,153,162,0.6)",
            gridwidth: 1,
            linecolor: "rgba(150,153,162,0.6)",
            linewidth: 2,
            range: this.xRange || null,
          },
          yaxis: {
            title: {
              text: this.yTitle,
              font: {
                family: "Poppins",
                size: 16,
              },
            },
            gridcolor: "rgba(150,153,162,0.4)",
            gridwidth: 1,
            range: this.yRange || null,
          },
          height: this.height || 650,
          bargap: 0.2,
          //boxgap: 0.3
          //------------------------------------------------------------------
          //HOVER MDOE
          //------------------------------------------------------------------
          hovermode: this.hovermode,
          hoverlabel: this.hoverlabel,
          hoverdistance: 100,
          paper_bgcolor: "rgba(45,48,65,1)",
          plot_bgcolor: "rgba(45,48,65,1)",
          font: {
            family: "Poppins",
            size: 14,
            color: "rgb(255,255,255)",
          },
          shapes: this.threshold
            ? [
                {
                  type: "line",
                  xref: "paper",
                  x0: 0,
                  y0: this.threshold,
                  x1: 1,
                  y1: this.threshold,
                  line: {
                    color: "rgb(255, 0, 0)",
                    width: 3,
                    dash: "dashdot",
                  },
                },
              ]
            : [],
        },
      };
    },
  },
  mounted() {
    this.instance = document.getElementById(this.uuid);
    Plotly.newPlot(this.$refs[this.uuid], this.traces, this.chart.layout, this.config);

    this.instance.on("plotly_click", (data) => {
      if (this.activePointOnClick) {
        this.markPoint(data)
      }
      this.$emit("plotlyClick", data);
    });
  },
  methods: {
    markPoint(data){
      const colors = data.points[0].data.x.map((v, i)=>{
        if (i === data.points[0].pointIndex) {
          return 'rgba(35, 145, 254, 1)'
        }
        return 'rgba(35, 145, 254, 0.6)'
      })
      const update = {'marker':{color: colors}}
      Plotly.restyle(this.$refs[this.uuid], update, [0]);
    }
  },
  watch: {
    traces: {
      handler: function () {
        if (this.$refs[this.uuid]) {
          Plotly.react(
            this.$refs[this.uuid],
            this.traces,
            this.chart.layout,
            this.config
          );
        }
      },
      deep: true,
    },
    chart: {
      handler: function () {
        if (this.$refs[this.uuid]) {
          Plotly.newPlot(
            this.$refs[this.uuid],
            this.traces,
            this.chart.layout,
            this.config
          );
        }
      },
      deep: true,
    },
    height: {
      handler: function () {
        if (this.$refs[this.uuid]) {
          Plotly.newPlot(
            this.$refs[this.uuid],
            this.traces,
            this.chart.layout,
            this.config
          );
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.line-plot-wrapper {
  height: fit-content;
  padding-bottom: 20px;
}
</style>
