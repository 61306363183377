import {round} from "lodash"

export function railSections(serverSections = []){
    return serverSections.map(section => {
        section.sleepers = section.sleepers.map((sleeper, index, sleepers ) => {
            sleeper.distanceFromPrevious = index > 0 ? round(sleeper.distanceFromZero - sleepers[index -1].distanceFromZero, 3) : 0;
            return sleeper
        }).map(sleeper => {
            delete sleeper.distanceFromZero;
            return sleeper
        })
        delete section.type;
        return section;
    })
}



export function getCantTimeDomain(cantTime = {aggregationWindow: 0, total: 0, values: []}){

    const x = cantTime.values.map(v => new Date(v.date))
    const y = cantTime.values.map(v => v.y)
    const id = cantTime.values.map(v => v._id)
    return {
        aggregationWindow: cantTime.aggregationWindow,
        total: cantTime.total,
        x,
        y,
        id
    }
}
 
export function getCantEvents(cantEvents = []){

    cantEvents.map(event=> {
        const intensityAbs = Math.max(Math.abs(event.maxY), Math.abs(event.minY));
        event.intensity = intensityAbs === Math.abs(event.maxY) ? Math.abs(event.maxY) : Math.abs(event.minY);
        event.intensity = round(event.intensity, 2)
        return event;
    })
    return cantEvents
}

export function getTwistEvents(twistEvents = []){

    twistEvents.map(event=> {
        const intensityAbs = Math.max(Math.abs(event.maxY), Math.abs(event.minY));
        event.intensity = intensityAbs === Math.abs(event.maxY) ? Math.abs(event.maxY) : Math.abs(event.minY);
        event.intensity = round(event.intensity, 2)
        return event;
    })
    return twistEvents
}

export function getBeamPolylineEvents(beamPolylineEvents = []){

    beamPolylineEvents.map(event=> {
        const intensityAbs = Math.max(Math.abs(event.maxY), Math.abs(event.minY));
        event.intensity = intensityAbs === Math.abs(event.maxY) ? event.maxY : event.minY;
        event.intensity = round(event.intensity, 2)
        return event;
    })
    return beamPolylineEvents
}


export function getTwistTimeDomain(cantTime = {aggregationWindow: 0, total: 0, values: []}){

    const x = cantTime.values.map(v => new Date(v.date))
    const y = cantTime.values.map(v => v.y)
    const id = cantTime.values.map(v => v._id)
    return {
        aggregationWindow: cantTime.aggregationWindow,
        total: cantTime.total,
        x,
        y,
        id
    }
}

export function getBeamPolylineSpaceDomain(beamPolyline = {date: null, groupID: null, structureID: null, subSections: [], tool: null, type: null, _id: null}){
    const subSections = beamPolyline.subSections.map(subSection => {
        const chain = subSection.chains.map(chain => {
            chain.x.unshift(0)
            chain.y.unshift(0)
            chain.usedDevices.unshift(null)
            return chain;
        })
        return chain
    })
    return {
        ...beamPolyline, ...{subSections}
    }
}


export function getTemperatures(temperatures = {aggregationWindow: 0, total: 0, values: []}){

    const dates = temperatures.values.map(v => {
        const time = v.timestamp ? v.timestamp : v.date
        return new Date(time)
    })
    const timestamps = temperatures.values.map(v => new Date(v.timestamp))
    const temperature = temperatures.values.map(v => v.temperature)
    const id = temperatures.values.map(v => v._id)
    return {
        aggregationWindow: temperatures.aggregationWindow,
        total: temperatures.total,
        dates,
        timestamps,
        temperature,
        id
    }
}
