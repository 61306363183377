const namespaced = true;

import _ from 'lodash'

import api from "../../services/api"
import * as dtoClient from "../../services/dtoClient"
import * as dtoServer from "../../services/dtoServer"
import { getShortEui } from "../../helpers/sensors"
import { getSectionEUIs } from "../../helpers/rail"

function validateStep(step, maxValue, minValue = 1) {
  return step >= minValue && step <= maxValue
}

const RAIL_SECTION_LIMIT_ITEMS = 10;

const state = {
  /****************************************************************/
  /* PAGE NAVIGATION */
  /****************************************************************/
  pages: [
    {
      path: 'main',
    },
    {
      path: 'section-form',
    },
    {
      path: 'section-resume',
    },
    {
      path: 'section-form-resume',
    },
  ],
  page: {
    path: 'main',
  },
  loading: false,

  /****************************************************************/
  /* SECTION STEP NAVIGATION */
  /****************************************************************/
  sectionForm: {
    steps: 3,
    step: 1,
  },

  /****************************************************************/
  /* SECTIONS*/
  /****************************************************************/
  sections: [],

  /* Temp section is used in creation/update phase*/
  /****************************************************************/
  tempSection: {
    _id: null,
    enabled: true,
    sleepersLength: 0,
    sectionLength: 0,
    direction: [null, null],
    createdAt: null,
    order: 0,
    notes: '',
    name: '',
    sleepers: [],
    beamSections: []
  },

  /* Default section is used in creation/update phase to calculate if it change */
  /****************************************************************/
  oldTempSection: {
    _id: null,
    enabled: true,
    sleepersLength: 0,
    sectionLength: 0,
    direction: [null, null],
    createdAt: null,
    order: 0,
    notes: '',
    name: '',
    sleepers: [],
    beamSections: []
  },

  /* Selected section is used only in viewer section */
  /****************************************************************/
  selectedSection: {
    _id: null,
    enabled: false,
    sleepersLength: 0,
    sectionLength: 0,
    direction: [null, null],
    createdAt: null,
    order: 0,
    notes: '',
    name: '',
    sleepers: [],
    beamSections: []
  },

  /****************************************************************/
  /* ASYNC VALIDATION */
  /****************************************************************/
  asyncBackendValidationName: true,

  /****************************************************************/
  /* TIMERS */
  /****************************************************************/
  timerNameBackendValidation: null,

  /****************************************************************/
  /* DATE RANGE */
  /****************************************************************/
  dateRange: [new Date(new Date().getTime() - 1000*60*60*24*7), new Date() ],

  /**************************************************************** ****************************************************************/
  /* CANT DATA */
  /**************************************************************** ****************************************************************/

  /*CANT - EVENTS */
  /****************************************************************/
  /*List of cant events */
  cantEvents: [],

  /*Selected cant event */
  /*This event will be used to plot the cant data in space domain */
  selectedCantEvent: {
    _id: null,
    date: new Date(),
    maxY: null,
    minY: null,
  },

  /*CANT - SPACE DOMAIN */
  /****************************************************************/
  /*Cant, space domain data */
  /*Rapresent the cant data in space domain based on <selectedCantEvent> */
  cantSpaceDomainData: {
    _id: null,
    structureID: null,
    groupID: null,
    date: new Date(), // Date of the first event contributing to the computation
    tool: "rail",
    type: "cant",
    usedDevices: [], // Ordered list of sleepers EUIs actually used to calculate CANT values
    x: [], // Ordered list of distance values (from the beginning of the section) of the sensors actually used for CANT computation
    y: [], // Ordered list of CANT values corresponding to the sensors
  },

  /*Cant, space domain range */
  cantRangeSpaceDomain: [-180, 180], // Cant range in mm

  /*CANT - TIME DOMAIN */
  /****************************************************************/
  /*Selected cant EUI */
  /*This sensor will be used to plot the cant data in time domain */
  selectedCantEUI: null,

  /*Cant, time domain data */
  /*Rapresent the cant data in time domain based on <selectedCantEUI> */
  cantTimeDomainData: {
    values: [],
    total: null,
    aggregationWindow: null,
  },

  cantRangeTimeDomain: [-180, 180], // Cant range in mm

  /*CANT - TEMPERATURES */
  /****************************************************************/
  /*Cant, temperatures */
  /*Rapresent the temperatures of <selectedCantEUI> */
  cantTemperatureData: {
    total: null,
    aggregationWindow: null,
    dates: [],
    timestampes: [],
    id: [],
    temperature: []
  },


  /****************************************************************/
  /* TWIST */
  /****************************************************************/

  /*TWIST - EVENTS */
  /****************************************************************/
  /*List of twist events */
  twistEvents: [],

  /*Selected twist event */
  /*This event will be used to plot the twist data in space domain */
  selectedTwistEvent: {
    _id: null,
    date: new Date(),
    maxY: null,
    minY: null,
  },

  /*TWIST - SPACE DOMAIN */
  /****************************************************************/
  /*Twist, space domain data */
  /*Rapresent the twist data in space domain based on <selectedTwistEvent> */
  twistSpaceDomainData: {
    _id: null,
    structureID: null,
    groupID: null,
    date: new Date(), // Date of the first event contributing to the computation
    tool: "rail",
    type: "twist",
    usedDevices: [], // Ordered list of sleepers EUIs actually used to calculate TWIST values
    x: [], // Ordered list of distance values (from the beginning of the section) of the sensors actually used for TWIST computation
    y: [], // Ordered list of TWIST values corresponding to the sensors
  },

  /*TWIST - TIME DOMAIN */
  /****************************************************************/
  /*Selected twist EUI option */
  /*This option will be used to plot the twist data in time domain */
  selectedTwistEUIOption: {
    devices: [],
    label: null,
    eui: null,
  },

  /*Twist, time domain data */
  /*Rapresent the twist data in time domain based on <selectedTwistEUIOption> */
  twistTimeDomainData: {
    values: [],
    total: null,
    aggregationWindow: null,
  },

  /*TWIST - TEMPERATURES */
  /****************************************************************/
  /*Twist, temperatures */
  /*Rapresent the temperatures of <selectedTwistEUIOption> */
  twistTemperatureData: [
    {
      total: null,
      aggregationWindow: null,
      dates: [],
      timestampes: [],
      id: [],
      temperature: []
    },
    {
      total: null,
      aggregationWindow: null,
      dates: [],
      timestampes: [],
      id: [],
      temperature: []
    }
  ],

  /****************************************************************/
  /* BEAM POLYLINE */
  /****************************************************************/

  /*BEAM POLYLINE - EVENTS */
  /****************************************************************/
  /*List of twist events */
  beamPolylineEvents: [],

  /*Selected twist event */
  /*This event will be used to plot the twist data in space domain */
  selectedBeamPolylineEvent: {
    _id: null,
    date: new Date(),
  },

  /*BEAM POLYLINE - SPACE DOMAIN */
  /****************************************************************/
  /*Beam Polyline, space domain data */
  /*Rapresent the beam polyline data in space domain based on <selectedBeamPolylineEvent> */
  beamPolylineData: {
    _id: null,
    structureID: null,
    groupID: null,
    date: new Date(), // Date of the first event contributing to the computation
    tool: "rail",
    type: "beam-polyline",
    subSections: []
  },

};

const getters = {
  /****************************************************************/
  /* ROOT GETTERS */
  /****************************************************************/
  getStructureID: (_state, _getters, _rootState, rootGetters) => {
    const structure = rootGetters['structure/getSelectedStructure']
    return structure && structure._id ? structure._id : null;
  },
  //get all sensors
  getAllSensorsWithRailDetails(_state, _getters, _rootState, rootGetters) {
    return rootGetters['structure/getSensorsDetails'].map((sensor) => {
      const beamFormat = sensor.beamFormat ? sensor.beamFormat : false;
      return {
        ...sensor,
        ...{
          beamFormat,
          shortEui: getShortEui(sensor.eui)
        }
      }
    });
  },
  //get sensor all sensor placed
  getSensorsPlaced(state){
    return state.sections.map(section => {
      const sleepersEUI = section.sleepers.map(s => s.device.eui)
      const beams = section.beamSections.map(b => b.beams).flat()
      const beamsEUI = beams.map(b => b.device.eui)
      return [...sleepersEUI, ...beamsEUI]
    }).flat()
  },

  //get available sensors
  //- sensor available must be beam format = true
  //- get all sensors from sections excluded tempSection, because sensors changes dinamically
  //- get all sensors from temp section
  //- filter sensor, removing sensors from all sections and temp section
  getAvailableSensorsWithRailDetails(state, getters) {
    return getters.getAllSensorsWithRailDetails
      .filter(s => s.beamFormat === true)
      .filter(sensor => {
        const sensorsAlreadyPlaced = state.sections
          .filter(section => section._id !== state.tempSection._id)
          .map(section => getSectionEUIs(section))
          .flat();
        const tempSensors = getSectionEUIs(state.tempSection)
        return !(sensorsAlreadyPlaced.includes(sensor.eui) || tempSensors.includes(sensor.eui))
    })
  },

  getTotalSectionLength(state){
    return state.sections.map(s => s.sectionLength).reduce((partialSum, v) => partialSum + v, 0)
  },


  /****************************************************************/
  /* PAGE NAVIGATION */
  /****************************************************************/
  getPage: (state) => state.page,
  getPages: (state) => state.pages,

  /****************************************************************/
  /* SECTION STEP NAVIGATION */
  /****************************************************************/
  getSteps: (state) => state.sectionForm.steps,
  getStep: (state) => state.sectionForm.step,
  getIsMinStep: (state) => state.sectionForm.step === 1,
  getIsMaxStep: (state) => state.sectionForm.step === state.sectionForm.steps,

  /****************************************************************/
  /* SECTIONS */
  /****************************************************************/
  getSections: (state) => state.sections,

  getIsLoading: (state) => state.loading,

  /****************************************************************/
  /* TEMP SECTION */
  /****************************************************************/
  getTempSection:(state) => state.tempSection,
  getOldTempSection: (state) => state.oldTempSection,
  getCleanTempSection(){
    return {
      _id: null,
      enabled: true,
      sleepersLength: 0,
      sectionLength: 0,
      direction: [null, null],
      order: 0,
      notes: '',
      name: '',
      sleepers: [],
      beamSections: []
    }
  },
  getTempSectionIsChanged(state){
    return !_.isEqual(state.tempSection, state.oldTempSection)
  },
  getAvailableSection(state, getters) {
    return state.sections.length ? state.sections[0] : getters.getCleanTempSection
  },

  /****************************************************************/
  /* SELECTED SECTION */
  /****************************************************************/
  getSelectedSection:(state) => state.selectedSection,

  /****************************************************************/
  /* DATE RANGE */
  /****************************************************************/
  getDateRange: (state) => state.dateRange,

  /****************************************************************/
  /* CANT */
  /****************************************************************/
  getCantEvents: (state) => state.cantEvents,
  getSelectedCantEvent: (state) => state.selectedCantEvent,
  getCantSpace: (state) => state.cantSpaceDomainData,
  getCantTimeDomain: (state) => state.cantTimeDomainData,
  getSelectedCantEUI: (state) => state.selectedCantEUI,
  getCantRangeSpaceDomain: (state) => state.cantRangeSpaceDomain,
  getCantRangeTimeDomain: (state) => state.cantRangeTimeDomain,
  getTemperatures: (state) => state.cantTemperatureData,

  getAvailableCantEvent(state){
    return state.cantEvents.length ? state.cantEvents[0]: null
  },
  getAvailableCantSensor(state){
    return state.cantSpaceDomainData.usedDevices.length ? state.cantSpaceDomainData.usedDevices[0] : null
  },



  /* Get clean selected cant event */
  /****************************************************************/
  getCleanSelectedCantEvent() {
    return {
      _id: null,
      date: new Date(),
      maxY: null,
    }
  },

  /* Get clean cant data in space domain */
  /****************************************************************/
  getCleanCantSpaceDomainData() {
    return {
      _id: null,
      structureID: null,
      groupID: null,
      date: new Date(), // Date of the first event contributing to the computation
      tool: "rail",
      type: "cant",
      usedDevices: [], // Ordered list of sleepers EUIs actually used to calculate CANT values
      x: [], // Ordered list of distance values (from the beginning of the section) of the sensors actually used for CANT computation
      y: [], // Ordered list of CANT values corresponding to the sensors
    }
  },

  /* Get clean cant data in time domain */
  /****************************************************************/
  getCleanCantTimeDomainData(){
    return{
      values: [],
      total: null,
      aggregationWindow: null,
    }
  },

  /* Get clean cant temperatures */
  /****************************************************************/
  getCleanCantTemperatureData() {
    return{
      total: null,
      aggregationWindow: null,
      dates: [],
      timestampes: [],
      id: [],
      temperature: []
    } 
  },

  /****************************************************************/
  /* TWIST */
  /****************************************************************/
  getTwistEvents: (state) => state.twistEvents,
  getSelectedTwistEvent: (state) => state.selectedTwistEvent,
  getTwistSpace: (state) => state.twistSpaceDomainData,
  getTwistTimeDomain: (state) => state.twistTimeDomainData,
  getTwistTemperatures: (state) => state.twistTemperatureData,

  getAvailableTwistEvent(state){
    return state.twistEvents.length ? state.twistEvents[0]: null
  },

  getTwistEUIOptions(state) {
    return state.twistSpaceDomainData.usedDevices.map((v) => {
      return {
        devices: v,
        label: `${getShortEui(v[0])} - ${getShortEui(v[1])}`,
        eui: v[0],
      };
    });
  },


  getSelectedTwistEUIOption: (state) => state.selectedTwistEUIOption,

  /* Clean twist data */
  /****************************************************************/

  /* Get clean twist space domain data */
  getCleanSelectedTwistEvent(){
    return{
      _id: null,
      date: new Date(),
      maxValue: null,
    }
  },

  /* Get clean twist space domain data */
  getCleanTwistSpaceDomainData(){
    return {
      _id: null,
      structureID: null,
      groupID: null,
      date: new Date(), // Date of the first event contributing to the computation
      tool: "rail",
      type: "twist",
      usedDevices: [], // Ordered list of sleepers EUIs actually used to calculate TWIST values
      x: [], // Ordered list of distance values (from the beginning of the section) of the sensors actually used for TWIST computation
      y: [], // Ordered list of TWIST values corresponding to the sensors
    }
  },

  /* Get clean selected twist EUI option */
  getCleanSelectedTwistEUIOption(){
    return{
      devices: [],
      label: null,
      eui: null,
    }
  },

  /* Get clean twist time domain data */
  getCleanTwistTimeDomainData(){
    return {
      values: [],
      total: null,
      aggregationWindow: null,
    }
  },

  /* Get clean twist cantTemperatureData */
  getCleanTwistTemperatures(){
    return [
      {
        total: null,
        aggregationWindow: null,
        dates: [],
        timestampes: [],
        id: [],
        temperature: []
      },
      {
        total: null,
        aggregationWindow: null,
        dates: [],
        timestampes: [],
        id: [],
        temperature: []
      }
    ]
  },

  /****************************************************************/
  /* BEAM POLYLINE */
  /****************************************************************/
  getBeamPolylineEvents: (state) => state.beamPolylineEvents,
  getSelectedBeamPolylineEvent: (state) => state.selectedBeamPolylineEvent,
  getBeamPolylineData: (state) => state.beamPolylineData,

  getAvailablePolylineEvent(state){
    return state.beamPolylineEvents.length ? state.beamPolylineEvents[0]: null
  },

  //Get Clean selected beam polyline event
  getCleanSelectedBeamPolylineEvent(){
    return {
      _id: null,
      date: new Date(),
    }
  },

  //Get Clean Beam polyline data
  getCleanBeamPolylineData(){
    return {
      _id: null,
      structureID: null,
      groupID: null,
      date: new Date(), // Date of the first event contributing to the computation
      tool: "rail",
      type: "beam-polyline",
      subSections: []
    }
  },

  /**************************************************************** ****************************************************************/
  /* VALIDATIONS */
  /**************************************************************** ****************************************************************/

  /****************************************************************/
  /* Validation of entire temp section */
  /****************************************************************/

  getValidationTempSection: (_state, getters) => {
    return (getters.getValidationTempSectionName
      && getters.getValidationTempSectionSleepersLength
      && getters.getValidationTempSectionSectionLength
      && getters.getValidationTempSectionEndDirection
      && getters.getValidationTempSectionStartDirection
      && getters.getValidationTempSectionSleepers
      && getters.getValidationTempSectionBeamSections)
  },

  //Name validation
  getValidationTempSectionName: (state) => {
    const name = state.tempSection.name;
    return name.length > 1 && name.length < 96 && state.asyncBackendValidationName
  },

  //Async validation is on going or not
  getIsAsyncValidationOnGoing: (state) => {
    return state.timerNameBackendValidation !== null
  },

  //Start direction validation 
  getValidationTempSectionStartDirection: (state) => {
    const startDirection = state.tempSection.direction[0];
    return startDirection ? startDirection.length > 1 && startDirection.length < 96 : false
  },

  //End direction validation 
  getValidationTempSectionEndDirection: (state) => {
    const endDirection = state.tempSection.direction[1];
    return endDirection ? endDirection.length > 1 && endDirection.length < 96 : false
  },

  //Sleepers length validation
  getValidationTempSectionSleepersLength: (state) => {
    const sleepersLength = state.tempSection.sleepersLength;
    return sleepersLength > 0
  },

  //validation of section length
  getValidationTempSectionSectionLength: (state) => {
    const sectionLength = state.tempSection.sectionLength;
    return sectionLength > 0;
  },

  /****************************************************************/
  /* Validation of sleepers */
  /****************************************************************/
  getValidationTempSectionSleepers(_state, getters) {
    return (getters.getValidationEmptySleepers 
      || (getters.getValidationSleepersExistenceOfFirst
      && getters.getValidationSleepersOverlapping));
  },

  getValidationEmptySleepers(state){
    const sleepers = state.tempSection.sleepers;
    return sleepers.length === 0;
  },

  //Check if exsist the first sleeper (the sleeper with distanceFromPrevious = 0)
  getValidationSleepersExistenceOfFirst(state) {
    const sleepers = state.tempSection.sleepers;
    return sleepers.length > 0 && sleepers.some((sl) => sl.distanceFromPrevious === 0)
  },

  // Checking for overlapping sleepers
  getValidationSleepersOverlapping(state) {
    const sleepers = state.tempSection.sleepers;
    return sleepers.filter(s => s.distanceFromPrevious === 0).length < 2
  },

  /****************************************************************/
  /* Validation of beam sections */
  /****************************************************************/

  getValidationTempSectionBeamSections(_state, getters) {
    return (getters.getValidationEmptyBeamSections 
      || (getters.getValidationBeamSectionsOverlapping && getters.getValidationBeams));
  },

  getValidationEmptyBeamSections(state){
    const beamSections = state.tempSection.beamSections;
    return beamSections.length === 0;
  },

  // Checking for overlapping beam section name
  getValidationBeamSectionsOverlapping(state) {
    const beamSectionNames = state.tempSection.beamSections.map(b => b.name);
    if (beamSectionNames.length > 1) {
      return new Set(beamSectionNames).size === beamSectionNames.length
    }
    return true
    
  },

  getValidationBeams(state, getters){
    //if there is not a beam section, return true
    if(getters.getValidationEmptyBeamSections){
      return true;
    }
    const beamSections = state.tempSection.beamSections;
    return beamSections.map(beamSection => beamSection.beams.length > 0).filter(v => v === false).length === 0;
  },

  /****************************************************************/
  /* Validation of beam sections /sleepers */
  /****************************************************************/

  getValidationAtLeastOneSensor(_state, getters){
    return !(getters.getValidationEmptyBeamSections && getters.getValidationEmptySleepers)
  }

};

const actions = {

  async setLoading({commit}, loading = true){
    commit("setLoading", loading)
  },

  /****************************************************************/
  /* PAGE NAVIGATION */
  /****************************************************************/
  setPage({ commit }, page) {
    commit('setPage', page)
  },
  restoreDefaultPage({ commit }) {
    commit('setPage', 'main')
  },

  /****************************************************************/
  /* FORM - SECTION STEP NAVIGATION */
  /****************************************************************/
  incrementSectionStep({ state, commit }) {
    const step = state.sectionForm.step + 1
    if (validateStep(step, state.sectionForm.steps)) {
      commit('setSectionStep', step);
    }
  },
  decrementSectionStep({ state, commit }) {
    const step = state.sectionForm.step - 1
    if (validateStep(step, state.sectionForm.steps)) {
      commit('setSectionStep', step);
    }
  },
  setSectionStep({ commit }, step) {
    if (validateStep(step, state.sectionForm.steps)) {
      commit('setSectionStep', step);
    }
  },
  /****************************************************************/
  /* SECTIONS */
  /****************************************************************/
  setSelectedSection({commit}, value){
    commit('setSelectedSection', value)
  },

  setDateRange({commit}, value){
    commit('setDateRange',value)
  },


  /****************************************************************/
  /* FORM - TEMP SECTION */
  /****************************************************************/
  /* set temp section */
  setTempSection: ({ commit, state }, value) => {
    commit('setTempSection', { ...state.tempSection, ...value })
  },

  setOldTempSection: ({ commit, state }, value) => {
    commit('setOldTempSection', { ...state.oldTempSection, ...value })
  },
  /* reset temp section */
  resetTempSection: ({ commit }) => {
    commit('setTempSection', {
      _id: null,
      enabled: true,
      sleepersLength: 0,
      sectionLength: 0,
      direction: [null, null],
      order: 0,
      notes: '',
      name: '',
      sleepers: [],
      beamSections: []
    })
  },

  resetOldTempSection: ({commit}) => {
    commit('setOldTempSection', {
      _id: null,
      enabled: true,
      sleepersLength: 0,
      sectionLength: 0,
      direction: [null, null],
      order: 0,
      notes: '',
      name: '',
      sleepers: [],
      beamSections: []
    })
  },

  /* set specific fields */
  /*****************************************/
  /* set temp section start direction */
  setTempSectionStartDirection: ({ commit, state }, value) => {
    commit('setTempSection', { ...state.tempSection, ...{ direction: [value, state.tempSection.direction[1]] } });
  },
  /* set temp section end direction */
  setTempSectionEndDirection: ({ commit, state }, value) => {
    commit('setTempSection', { ...state.tempSection, ...{ direction: [state.tempSection.direction[0], value] } });
  },

  /****************************************************************/
  /* FORM TEMP SECTION - PUBLIC ACTIONS */
  /****************************************************************/

  //Start editing mode for entire section
  editingSection: ({ dispatch, state }, id) => {
    const section = state.sections.find(s => s._id === id);
    dispatch('setTempSection', section)
  },


  /*  MANAGE SLEEPERS FROM TEMP SECTION */
  /****************************************************************/

  /* Add sleeper to temp section */
  addSleeperToTempSection({ dispatch, state }, payload = { index: -1, sleeper: null }) {
    const sleepers = state.tempSection.sleepers;
    sleepers.splice(payload.index, 0, payload.sleeper)
    dispatch('setTempSection', { sleepers });
  },

  /* Update sleeper to temp section */
  updateSleeperFromTempSection({ dispatch, state }, payload = { index: -1, sleeper: null }) {
    let sleepers = state.tempSection.sleepers;
    sleepers.splice(payload.index, 1, payload.sleeper)
    dispatch('setTempSection', { sleepers });
  },

  /* Delete sleeper to temp section */
  deleteSleeperFromTempSection: ({ dispatch, state }, index = -1) => {
    let sleepers = state.tempSection.sleepers;
    sleepers.splice(index, 1);
    if (index === 0 && sleepers.length) {
      sleepers[0].distanceFromPrevious = 0;
    }
    dispatch('setTempSection', { sleepers });
  },

  /* Move up sleeper from temp section */
  moveUpSleeperFromTempSection({ dispatch, state }, index = -1){
    let sleepers = state.tempSection.sleepers;
    const sleeper = sleepers.splice(index, 1)[0];
    sleepers.splice(index - 1, 0, sleeper)
    dispatch('setTempSection', { sleepers });
  },

  /* Move down sleeper from temp section */
  moveDownSleeperFromTempSection({ dispatch, state }, index = -1){
    let sleepers = state.tempSection.sleepers;
    const sleeper = sleepers.splice(index, 1)[0];
    sleepers.splice(index + 1, 0, sleeper)
    dispatch('setTempSection', { sleepers });
  },


  /*  MANAGE BEAM SECTIONS FROM TEMP SECTION */
  /****************************************************************/

  /* Add new beam section */
  addBeamSection({ dispatch, state }, beamSection = {name: null, beams: []}) {
    const beamSections = state.tempSection.beamSections;
    beamSections.push(beamSection)
    dispatch('setTempSection', {beamSections})
  },

  /* Rename beam section */
  renameBeamSection({ dispatch, state }, payload = {index:-1, name: null}) {
    const beamSections = state.tempSection.beamSections;
    beamSections[payload.index].name = payload.name;
    dispatch('setTempSection', {beamSections})
  },

  /* Delete beam section */
  deleteBeamSection: ({ dispatch, state }, index= -1) => {
    const beamSections = state.tempSection.beamSections;
    beamSections.splice(index, 1);
    dispatch('setTempSection', {beamSections})
  },

  /* Add beam to beamSection */
  addBeamToBeamSection({ dispatch, state }, payload = {beamSectionIDX: -1, beam: { length: 0, device: { eui: null, enabled: false } }}) {
    console.log('payload', payload)
    const beamSections = state.tempSection.beamSections;
    beamSections[payload.beamSectionIDX].beams.push(payload.beam)
    dispatch('setTempSection', {beamSections})
  },

  //modify exsisting beam from temp section
  updateBeamFromBeamSection({ dispatch, state }, payload = { beamSectionIDX: -1, beamIDX: -1, beam: { length: 0, device: { eui: null, enabled: false } } }) {
    const tempSection = state.tempSection;
    tempSection.beamSections[payload.beamSectionIDX].beams[payload.beamIDX] = payload.beam
    dispatch('setTempSection', tempSection)
  },

  //delete beam from temp section
  deleteBeamFromBeamSection({ dispatch, state }, payload = {beamSectionIDX: -1, beamIDX: -1}) {
    const tempSection = state.tempSection;
    tempSection.beamSections[payload.beamSectionIDX].beams.splice(payload.beamIDX, 1);
    dispatch('setTempSection', tempSection)
  },

  /* Move up sleeper from temp section */
  moveUpBeamFromTempSection({ dispatch, state }, payload = {beamSectionIDX : -1, beamIDX : -1}) {
    const beamSections = state.tempSection.beamSections;
    const beam = beamSections[payload.beamSectionIDX].beams.splice(payload.beamIDX, 1)[0];
    beamSections[payload.beamSectionIDX].beams.splice(payload.beamIDX - 1, 0, beam)
    dispatch('setTempSection', {beamSections})
  },

  /* Move down sleeper from temp section */
  moveDownBeamFromTempSection({ dispatch, state }, payload = {beamSectionIDX : -1, beamIDX : -1}){
    const beamSections = state.tempSection.beamSections;
    const beam = beamSections[payload.beamSectionIDX].beams.splice(payload.beamIDX, 1)[0];
    beamSections[payload.beamSectionIDX].beams.splice(payload.beamIDX + 1, 0, beam)
    dispatch('setTempSection', {beamSections})
  },


  /**************************************************************** ****************************************************************/
  /* SECTION CONFIGURATION API */
  /**************************************************************** ****************************************************************/

  /* Fetch sections */
  async fetchSections({ commit, getters, dispatch }, payload = { limit: RAIL_SECTION_LIMIT_ITEMS, page: 0, spinner: false }) {
    if (payload.spinner) {
      commit('setLoading', true)
    }
    await dispatch('structure/fetchStructures', null, { root: true })
    if (!getters.getStructureID) {
      if (payload.spinner) {
        commit('setLoading', false)
      }
      console.warn('STRUCTURE ID IS NOT DEFINED!')
      return;
    }
    try {
      const response = await api.getRailSectionsFromUser({ url: { structureID: getters.getStructureID }, params: { limit: payload.limit, page: payload.page } });
      const sections = dtoClient.railSections(response.data.values);
      commit('setSections', sections)
    } catch (e) {
      alert('API ERROR:', e.message)
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  selectAvailableSection({ state, commit }){
    if (state.sections.length) {
      commit('setSelectedSection', state.sections[0])
    }
  },

  /* Create new section */
  async createNewSection({ getters, dispatch }, payload={section: {}, spinner: false}) {
    dispatch('setLoading', true)
    await dispatch('structure/fetchStructures', null, { root: true })
    if (!getters.getStructureID) {
      dispatch('setLoading', false)
      console.warn('STRUCTURE ID IS NOT DEFINED!')
      return;
    }
    try {
      const section = dtoServer.railSection(payload.section)
      console.log(section)
      await api.createRailSectionFromUser({ url: { structureID: getters.getStructureID }, body: section })
      dispatch('setLoading', false)
    } catch (e) {
      dispatch('setLoading', false)
      alert(`API POST GROUP ERROR (${e.code}) ${e.message}`);
    }
  },

  /* Update section */
  async updateSection({ getters, dispatch }, payload={section: {}, spinner: false}) {
    dispatch('setLoading', true)
    await dispatch('structure/fetchStructures', null, { root: true })
    if (!getters.getStructureID) {
      dispatch('setLoading', false)
      console.warn('STRUCTURE ID IS NOT DEFINED!')
      return;
    }
    try {
      const section = dtoServer.railSection(payload.section);
      console.log("section", section)
      await api.putRailSectionFromUser({ url: { structureID: getters.getStructureID, groupID: payload.section._id }, body: section })
      dispatch('setLoading', false)
    } catch (e) {
      dispatch('setLoading', false)
      alert(`API PUT GROUP ERROR (${e.code}) ${e.message}`);
    }
  },

  /* Delete section */
  async deleteSection({ getters, dispatch }, id) {
    dispatch('setLoading', true)
    await dispatch('structure/fetchStructures', null, { root: true })
    if (!getters.getStructureID) {
      console.warn('STRUCTURE ID IS NOT DEFINED!')
      dispatch('setLoading', false)
      return;
    }
    try {
      await api.deleteGroupFromUser({ url: { structureID: getters.getStructureID, groupID: id } })
      dispatch('setLoading', false)
    } catch (e) {
      dispatch('setLoading', false)
      alert(`API DELETE GROUP ERROR (${e.code}) ${e.message}`);
    }
  },

  /* Validate section name */
  async getValidationSectionName({ state, commit, getters }, name) {

    if (name === null || name === '') {
      return;
    }

    commit('setAsyncBackendValidationName', false);

    const oldSection = state.sections.find(s => s._id === state.tempSection._id);
    const oldSectionName = oldSection ? oldSection.name : null;

    //if old name matches the new name, return true
    if (oldSectionName === name) {
      commit('setAsyncBackendValidationName', true);
      commit('setTimerNameBackendValidation', null);
      return;
    }

    if (state.tempSection.timerNameBackendValidation) {
      clearTimeout(state.tempSection.timerNameBackendValidation);
      commit('setTimerNameBackendValidation', null)
    }
    const timerNameBackendValidation = setTimeout(async () => {
      try {
        const data = await api.validateGroupNameFromUser({ url: { structureID: getters.getStructureID, name: name }, params: { type: 'rail-section' } })
        commit('setAsyncBackendValidationName', data.available);
        commit('setTimerNameBackendValidation', null);
      } catch (e) {
        commit('setAsyncBackendValidationName', false);
        commit('setTimerNameBackendValidation', null)
      }
    }, 1000)
    commit('setTimerNameBackendValidation', timerNameBackendValidation)
  },

  /* move section up */
  async increaseSectionOrder({ getters, dispatch }, groupID){
    try {
      dispatch('setLoading', true)
      await api.moveSections({ url: { structureID: getters.getStructureID, groupID}, params: { direction: 'up' } })
      dispatch('setLoading', false)
    } catch (e) {
      dispatch('setLoading', false)
      alert(`API MOVE SECTIONS ERROR (${e.code}) ${e.message}`);
    }
  },

  /* move section down */
  async decreaseSectionOrder({ getters, dispatch }, groupID = null){
    try {
      dispatch('setLoading', true)
      await api.moveSections({ url: { structureID: getters.getStructureID, groupID}, params: { direction: 'down' } })
      dispatch('setLoading', false)
    } catch (e) {
      dispatch('setLoading', false)
      alert(`API MOVE SECTIONS ERROR (${e.code}) ${e.message}`);
    }
  },



  /**************************************************************** ****************************************************************/
  /* RAIL DATA API */
  /**************************************************************** ****************************************************************/

  /* CANT API */
  /****************************************************************/

  /* HIGH level API */
  /****************************************************************/
  /* Clean all cant data*/
  cleanAllCantData({commit, getters}){
    commit('setCantEvents', [])
    commit('setSelectedCantEvent', getters.getCleanSelectedCantEvent)
    commit('setCantSpaceDomainData', getters.getCleanCantSpaceDomainData)
    commit('setSelectedCantEUI', null)
    commit('setCantTimeDomainData', getters.getCleanCantTimeDomainData)
    commit('setCantTemperatureData', getters.getCleanCantTemperatureData)
  },

  async fetchAllCantData({getters, commit, dispatch}, payload = {groupID : null, firstDate: null, lastDate: null, spinner: false}){
    try {
      if (!payload.groupID) {
        return;
      }
      dispatch('cleanAllCantData');
      if (payload.spinner) {
        commit('setLoading', true)
      }
      await dispatch('fetchCantEvents', payload)
      const cantEvent =  getters.getAvailableCantEvent;
      if (!cantEvent) {
        if (payload.spinner) {
          commit('setLoading', false)
        }
        return;
      }
      dispatch('setSelectedCantEvent', cantEvent)
      await dispatch('fetchCantSpaceDomainData', { eventID: cantEvent._id })
      const eui = getters.getAvailableCantSensor;
      if (!eui) {
        if (payload.spinner) {
          commit('setLoading', false)
        }
        return
      }
      dispatch('setSelectedCantEUI', eui)
      await dispatch('fetchCantTimeDomainData',{
        groupID: payload.groupID,
        eui,
        firstDate: payload.firstDate,
        lastDate: payload.lastDate,
      });
      await dispatch('fetchCantTemperatureData',{
        eui,
        firstDate: payload.firstDate,
        lastDate: payload.lastDate,
      });
    } catch (e) {
      alert('API ERROR: FETCH ALL CANT DATA', e.message)
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* LOW level API */
  /****************************************************************/
  /* Fetch Cant events */
  async fetchCantEvents({getters, commit}, payload = {groupID: null, firstDate: null, lastDate: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const response = await api.getCantEvents({url: {structureID: getters.getStructureID}, params: {firstDate: payload.firstDate, lastDate: payload.lastDate, group: payload.groupID}})
      const cantEvents = dtoClient.getCantEvents(response.data.values)
      commit('setCantEvents', cantEvents)
      return cantEvents;
    } catch (e) {
      alert(`API FETCH CANT EVENTS ERROR (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* Set selected Cant event */ 
  setSelectedCantEvent({commit}, event = null){
    commit('setSelectedCantEvent', event)
  },

  /* Fetch Cant, space domain data */
  async fetchCantSpaceDomainData({getters, commit}, payload = {eventID: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const response = await api.getCantSpace({url: {structureID: getters.getStructureID, eventID: payload.eventID}});
      console.log(response.data)
      commit('setCantSpaceDomainData', response.data)
    } catch (e) {
      alert(`API FETCH CANT SPACE ERROR (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* Set available Cant sensor*/
  setSelectedCantEUI({commit}, value){
    commit('setSelectedCantEUI', value);
  },


  /* Fetch Cant, time domain data */
  async fetchCantTimeDomainData({getters, commit},  payload = {groupID: null, eui: null, firstDate: null, lastDate: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const response = await api.getCantTimeDomain({url: {structureID: getters.getStructureID, eventID: payload.eventID}, params: {group: payload.groupID, eui: payload.eui, firstDate: payload.firstDate, lastDate: payload.lastDate}});
      const cantTime = dtoClient.getCantTimeDomain(response.data)
      commit('setCantTimeDomainData', cantTime)
    } catch (e) {
      alert(`API FETCH CANT TIME DOMAIN ERROR (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* Fetch Temperatures */
  async fetchCantTemperatureData({commit}, payload = {eui: null, firstDate: null, lastDate: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const repsonse = await api.getTemperatures({url:{eui:payload.eui}, params: {firstDate: payload.firstDate, lastDate: payload.lastDate, decimation: 'date-range'}});
      const temperature = dtoClient.getTemperatures(repsonse.data)
      commit('setCantTemperatureData', temperature)
    } catch (e) {
      alert(`API FETCH CANT TEMPERATURES DOMAIN ERROR (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /**************************************************************** ****************************************************************/
  /* TWIST DATA API */
  /**************************************************************** ****************************************************************/

  /* HIGH level API */
  /****************************************************************/
  /* Clean all twist data*/
  cleanAllTwistData({commit, getters}){
    commit('setTwistEvents', [])
    commit('setSelectedTwistEvent', getters.getCleanSelectedTwistEvent)
    commit('setTwistSpaceDomainData', getters.getCleanTwistSpaceDomainData)
    commit('setSelectedTwistEUIOption', getters.getCleanSelectedTwistEUIOption)
    commit('setTwistTimeDomainData', getters.getCleanTwistTimeDomainData)
    commit('setTwistTemperatureData', getters.getCleanTwistTemperatures)
  },

  setSelectedTwistEUIOption({commit}, payload = {twistEUIOption: null}){
    commit('setSelectedTwistEUIOption', payload.twistEUIOption)
  },

  /*Fetch all twist data*/
  async fetchAllTwistData({getters, dispatch, commit}, payload = {groupID : null, firstDate: null, lastDate: null, spinner: true}){
    try {
      if (!payload.groupID) {
        return;
      }
      dispatch('cleanAllTwistData');
      if (payload.spinner) {
        commit('setLoading', true)
      }
      await dispatch('fetchTwistEvents', payload)
      const tiwstEvent =  getters.getAvailableTwistEvent;
      if (!tiwstEvent) {
        if (payload.spinner) {
          commit('setLoading', true)
        }
        return;
      }
      dispatch('setSelectedTwistEvent', tiwstEvent)
      await dispatch('fetchTwistSpaceDomainData', { eventID: tiwstEvent._id })
      const selectedTwistEUIOption = getters.getTwistEUIOptions && getters.getTwistEUIOptions[0];
      if (!selectedTwistEUIOption) {
        if (payload.spinner) {
          commit('setLoading', true)
        }
        return;
      }
      commit('setSelectedTwistEUIOption', selectedTwistEUIOption)
      await dispatch('fetchTwistTimeDomain',{
        groupID: payload.groupID,
        euis: selectedTwistEUIOption.devices,
        firstDate: payload.firstDate,
        lastDate: payload.lastDate,
      });
      await dispatch('fetchTwistTemperatures',{
        devices: selectedTwistEUIOption.devices,
        firstDate: payload.firstDate,
        lastDate: payload.lastDate,
      });
    } catch (e) {
      alert('ERROR - LOAD CANT DATA', e.message)
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* LOW level API */
  /****************************************************************/
  /* Fetch Twist events */
  async fetchTwistEvents({getters, commit}, payload = {groupID: null, firstDate: null, lastDate: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const response = await api.getTwistEvents({url: {structureID: getters.getStructureID}, params: {firstDate: payload.firstDate, lastDate: payload.lastDate, group: payload.groupID}})
      const twistEvents = dtoClient.getTwistEvents(response.data.values)
      commit('setTwistEvents', twistEvents)
      console.log('response', response);
    } catch (e) {
      alert(`API FETCH TWIST EVENTS ERROR (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  //set selected twist event 
  async setSelectedTwistEvent({commit}, event = null){
    commit('setSelectedTwistEvent', event)
  },

  /* Fetch Twist, space domain data */
  async fetchTwistSpaceDomainData({getters, commit}, payload = {eventID: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const response = await api.getTwistSpace({url: {structureID: getters.getStructureID, eventID: payload.eventID}});
      console.log(response.data)
      commit('setTwistSpaceDomainData', response.data)
    } catch (e) {
      alert(`API FETCH TWIST SPACE ERROR (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* Fetch twist, time domain data */
  async fetchTwistTimeDomain({getters, commit},  payload = {groupID: null, euis: [], firstDate: null, lastDate: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const response = await api.getTwistTimeDomain({url: {structureID: getters.getStructureID, eventID: payload.eventID}, params: {group: payload.groupID, euis: payload.euis, firstDate: payload.firstDate, lastDate: payload.lastDate}});
      const twistTime = dtoClient.getTwistTimeDomain(response.data)
      commit('setTwistTimeDomainData', twistTime)
    } catch (e) {
      alert(`API FETCH TWIST TIME DOMAIN ERROR (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* Fetch Temperatures */
  async fetchTwistTemperatures({commit}, payload = {devices: [null, null], firstDate: null, lastDate: null, spinner: false}){
    try {
      let response = {};
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const twistTemperatureData = [null, null];
      response = await api.getTemperatures({url:{eui:payload.devices[0]}, params: {firstDate: payload.firstDate, lastDate: payload.lastDate, decimation: 'date-range'}});
      twistTemperatureData[0] = dtoClient.getTemperatures(response.data)
      response = await api.getTemperatures({url:{eui:payload.devices[1]}, params: {firstDate: payload.firstDate, lastDate: payload.lastDate, decimation: 'date-range'}});
      twistTemperatureData[1] = dtoClient.getTemperatures(response.data)
      commit('setTwistTemperatureData', twistTemperatureData)
    } catch (e) {
      alert(`API FETCH TWIST TEMPERATURES DOMAIN ERROR (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },


  /**************************************************************** ****************************************************************/
  /* BEAM POLYLINE DATA API */
  /**************************************************************** ****************************************************************/

  /* HIGH level API */
  /****************************************************************/
  /* Clean all beam polyline data */
  cleanAllBeamPolylineData({commit, getters}){
    commit('setBeamPolylineEvents', [])
    commit('setSelectedBeamPolylineEvent', getters.getCleanSelectedBeamPolylineEvent)
    commit('setBeamPolylineData', getters.getCleanBeamPolylineData)
  },

  /* Fetch all beam polyline data */
  async fetchAllBeamPolyLineData({getters, commit, dispatch}, payload = {groupID : null, firstDate: null, lastDate: null, spinner:false}){
    try {
      if (!payload.groupID) {
        return;
      }
      dispatch('cleanAllBeamPolylineData');
      if (payload.spinner) {
        commit('setLoading', true)
      }
      await dispatch('fetchBeamPolylineEvents', payload)
      const polylineEvent =  getters.getAvailablePolylineEvent;
      if (!polylineEvent) {
        if (payload.spinner) {
          commit('setLoading', false)
        }
        return;
      }
      dispatch('setSelectedBeamPolylineEvent', polylineEvent)
      await dispatch('fetchBeamPolyLineSpaceDomain', {eventID: polylineEvent._id})
    } catch (e) {
      alert(`API ERROR: FETCH ALL BEAM POLYLINE DATA, (${e.message}`)
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* LOW level API */
  /****************************************************************/

  /* BEAM POLYLINE EVENTS API */
  /****************************************************************/
  /* Fetch Beam Polyline Events */
  async fetchBeamPolylineEvents({getters, commit}, payload = {groupID: null, eui: null, firstDate: null, lastDate: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const response = await api.getBeamPolylineEvents({url: {structureID: getters.getStructureID}, params: {group: payload.groupID, firstDate: payload.firstDate, lastDate: payload.lastDate}})
      const beamPolylineEvents = dtoClient.getBeamPolylineEvents(response.data.values)
      commit('setBeamPolylineEvents' , beamPolylineEvents)
    } catch (e) {
      alert(`API ERROR: FETCH BEAM POLYLINE EVENTS, (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  },

  /* Set Selected beam polyline event */
  async setSelectedBeamPolylineEvent({commit}, event = null){
    commit('setSelectedBeamPolylineEvent', event)
  },

  /* BEAM POLYLINE SPACE DOMAIN API */
  /****************************************************************/
  /* Fetch Beam Polyline space domain data */
  async fetchBeamPolyLineSpaceDomain({getters, commit}, payload = {eventID: null, spinner: false}){
    try {
      if (payload.spinner) {
        commit('setLoading', true)
      }
      const response = await api.getBeamPolylineSpaceDomain({url: {structureID: getters.getStructureID, eventID: payload.eventID}})
      const result = dtoClient.getBeamPolylineSpaceDomain(response.data)
      console.log("result", result)
      commit('setBeamPolylineData', response.data)
    } catch (e) {
      alert(`API ERROR: FETCH BEAM POLYLINE EVENTS, (${e.code}) ${e.message}`);
    }
    if (payload.spinner) {
      commit('setLoading', false)
    }
  }

};

const mutations = {
  setPage: (state, page) => (state.page.path = page),
  setSectionStep: (state, step) => (state.sectionForm.step = step),
  setSections: (state, sections) => (state.sections = sections),
  setTempSection: (state, section) => (state.tempSection = section),
  setOldTempSection: (state, section) => (state.oldTempSection = section),
  setSelectedSection: (state, section) => (state.selectedSection = section),
  setAsyncBackendValidationName: (state, value) => (state.asyncBackendValidationName = value),
  setTimerNameBackendValidation: (state, value) => (state.timerNameBackendValidation = value),
  setLoading: (state, value) => (state.loading = value),
  setDateRange: (state, value) => (state.dateRange = value),

  //cant
  setCantEvents: (state, values) => (state.cantEvents = values),
  setSelectedCantEvent: (state, value) => (state.selectedCantEvent = value),
  setCantSpaceDomainData: (state, value) => (state.cantSpaceDomainData = value),
  setSelectedCantEUI: (state, value) => (state.selectedCantEUI = value),
  setCantTimeDomainData: (state, value) => (state.cantTimeDomainData = value),
  setCantTemperatureData: (state, value) => (state.cantTemperatureData = value),

  //twist
  setTwistEvents: (state, value) => (state.twistEvents = value),
  setSelectedTwistEvent: (state, value) => (state.selectedTwistEvent = value),
  setTwistSpaceDomainData: (state, value) => (state.twistSpaceDomainData = value),
  setSelectedTwistEUIOption: (state, value) => (state.selectedTwistEUIOption = value),
  setTwistTimeDomainData: (state, value) => (state.twistTimeDomainData = value),
  setTwistTemperatureData: (state, value) => (state.twistTemperatureData = value),

  //beam polyline
  setBeamPolylineEvents: (state, values) => (state.beamPolylineEvents = values),
  setSelectedBeamPolylineEvent: (state, value) => (state.selectedBeamPolylineEvent = value),
  setBeamPolylineData: (state, value) => (state.beamPolylineData = value),

};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
