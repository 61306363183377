<template>
  <div class="s-main-container" :class="{invalid: !valid, disabled: disabled}">
    <v-select class="t-select" :class="{reduceHeight: reduceHeight}" v-bind="$attrs" v-on="$listeners" :options="options" :disabled="disabled"/>
  </div>
</template>

<script>
export default {
  name: "MoveSelect",
  data: () => ({ placement: 'bottom', popper: null, forceClose: false }),
  props: {
    options: {
      type: Array,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    reduceHeight:{
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    updateScroll: {
      type: Number,
      default: 0
    }
  },
};
</script>


<style scoped>

.s-main-container {
  background: var(--secondary-color-dark);
  color: var(--input-placeholder-color);
  border-radius: 4px;
  padding: 2px 8px;
  box-sizing: border-box;
}

.s-main-container.disabled{
  opacity: 0.6;
}

.s-main-container.invalid{
  border: 1px solid red
}

.t-select {
  width: 100%;
}
.t-select >>> .vs__clear {
  fill: var(--text-color);
}
.t-select >>> .vs__open-indicator {
  fill: var(--text-color);
}


.t-select >>> .vs__clear {
  visibility: hidden;
  display: none;
}

.t-select >>> .vs__search::placeholder {
  padding: 0px !important;
  background: var(--secondary-color-dark);
  color: var(--input-placeholder-color) !important;
}
.t-select >>> .vs__dropdown-toggle {
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
  border: none;
}


.t-select >>> .vs__dropdown-menu {
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
  border-radius: 0px !important;
}

.t-select.reduceHeight >>> .vs__dropdown-menu{
  max-height: 100px !important;
}
.t-select >>> .vs__selected {
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
  margin: 0px !important;
  margin-top: 4px !important;
  padding: 0px !important;
}
.t-select >>> .vs__dropdown-option, .t-select >>> .vs__dropdown-option--highlight{
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
}

.t-select >>> .vs__dropdown-menu >>> .vs__dropdown-option--highlight {
  /* background: #ccc !important; */
  background: var(--secondary-color-light-2);
  color: var(--text-color) !important;
}

.t-select >>> .vs__search {
  background: var(--secondary-color-dark);
  color: var(--text-color) !important;
  outline: none !important;
  border: 1px solid transparent !important;
  border-radius: 4px;
  padding: 0px !important;
}

.t-select >>> .vs__dropdown-option:hover {
  background: var(--secondary-color-light);
  color: var(--text-color) !important;
}


.t-select >>> .vs__dropdown-menu, .t-select >>> .vs__dropdown-toggle, .t-select >>> .vs--open {
  width: 100% !important;
  min-width: auto;
}

.vs--disabled >>> .vs__clear, .vs--disabled >>> .vs__dropdown-toggle, .vs--disabled >>> .vs__open-indicator, .vs--disabled >>> .vs__search, .vs--disabled >>> .vs__selected{
  cursor: default !important;
  background-color: transparent;
}

/* width */
.t-select >>> .vs__dropdown-menu::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
  border-radius: 0px;
  outline: none;
}

/* Track */
.t-select >>> .vs__dropdown-menu::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 0px;
  outline: none;
}
 
/* Handle */
.t-select >>> .vs__dropdown-menu::-webkit-scrollbar-thumb {
  background: var(--primary-border-color); 
  border-radius: 0px;
  outline: none;
}

</style>