import Axios from "axios";
import tokenHelper from "./tokenHelper";
import configModule from "../config";
import store from "../store";

/* Se il token è scaduto lo refresho prima di inoltrare la richiesta */
function setup() {
  Axios.interceptors.request.use(
    (config) => {
      let originalRequest = config;
      const accessToken = localStorage.getItem("token");
      if (
        accessToken &&
        tokenHelper.isTokenExpired(accessToken) && // Ho l'access token ed è scaduto
        (config.url !== configModule.AUTH_ENDPOINT + "refresh_token" || // Non sto già refreshando (evito loop)
          (config.url === configModule.AUTH_ENDPOINT + "refresh_token" && config.method === "delete")) // Tranne il caso di logout (metodo delete)
      ) {
        return store.dispatch("auth/refreshToken").then((response) => {
          localStorage.setItem("token", response.token);
          originalRequest.headers.Authorization = "Bearer " + response.token;
          Axios.defaults.headers.common["Authorization"] = "Bearer " + response.token;
          return Promise.resolve(originalRequest);
        });
      }
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
}

export default setup;
