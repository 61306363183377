<i18n>
{
    "en": {},
    "it": {}
}
</i18n>
<template>
  <div class="d-flex">
    <div 
      v-for="s in steps"
      class="step-elem"
      :class="s <= step ? 'on': ''"
      :style="{marginRight: s === steps ? '0px' : '8px'}"
      :key="s"></div>
  </div>
</template>
<script>

export default {
  name: "move-step",
  props:{
    steps: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 0
    }
  }
};
</script>
<style scoped>
.step-elem{
  height: 16px;
  width: 16px;
  border: 2px solid white;
  background: transparent;
  border-radius: 50%;
}


.step-elem.on{
  background: var(--primary-color);

}
</style>
