const jwtDecode = require("jwt-decode");

function isTokenExpired(token) {
  let decoded = jwtDecode(token);

  var current_time = Date.now() / 1000;
  if (decoded.exp < (current_time + 60)) {
    return true;
  } else return false;
} 

function isAdmin(token){
  let decoded = jwtDecode(token);
  return (decoded.role === 'admin')
}
module.exports = {
  isTokenExpired,
  isAdmin
}