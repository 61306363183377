// Ritorna true se i due array contengono gli stessi elementi (a prescindere dall'ordine)
function arrayEquals(a, b) {
  if (!(Array.isArray(a) && Array.isArray(b) && a.length === b.length)) {
    return false;
  }
  const deepA = JSON.parse(JSON.stringify(a));
  const deepB = JSON.parse(JSON.stringify(b));
  deepA.sort();
  deepB.sort();
  return deepA.every((val, index) => val === deepB[index]);
}

function checkIfOnline(sensor) {
  if (sensor.stats === null || sensor.stats === undefined) return false;
  const maxOffset = sensor.type === "analog-node" ? 65 : 35; // Massimo delta ammissibile in minuti
  const extraOffset = 15; // Isteresi
  const diff = Math.abs(new Date() - new Date(sensor.stats.lastTX)); // Differenza in ms. dall'ultima trasmissione
  return Math.floor(diff / 1000 / 60) <= maxOffset + extraOffset;
}


// Se è un accelerometro con revision >= 3.0 uso il 'timestamp' al posto di 'date'
function useTimestampFieldBySensor(sensor) {
  return (sensor.type === "accelerometer" && sensor.rev >= "3.0") || sensor.type === "vibrometer" || sensor.type === "deck" || (sensor.type === 'analog-node' && sensor.rev >= "2.0") || (sensor.type === 'tiltmeter' && sensor.rev >= "3.0");
}
// Ritorna un intero corrispondente al monitoring mode in base all'array di assi ed al booleano che indica la modalità ABS
function getVibrometerMonitoringModeBySettings(customAxes, customOperation) {
  let retValue;
  if (!customAxes || customAxes.length === 0) {
    retValue = null;
  }
  if (arrayEquals(customAxes, ["x", "y", "z"])) {
    if (customOperation === "sqrt") {
      retValue = 0;
    } else {
      retValue = 4;
    }
  }
  if (arrayEquals(customAxes, ["x", "y"])) {
    if (customOperation === "sqrt") {
      retValue = 5;
    } else {
      retValue = 8;
    }
  }
  if (arrayEquals(customAxes, ["y", "z"])) {
    if (customOperation === "sqrt") {
      retValue = 7;
    } else {
      retValue = 10;
    }
  }
  if (arrayEquals(customAxes, ["x", "z"])) {
    if (customOperation === "sqrt") {
      retValue = 6;
    } else {
      retValue = 9;
    }
  }
  if (arrayEquals(customAxes, ["x"])) {
    retValue = 1;
  }
  if (arrayEquals(customAxes, ["y"])) {
    retValue = 2;
  }
  if (arrayEquals(customAxes, ["z"])) {
    retValue = 3;
  }
  return retValue;
}
// Ritorna un oggetto con la config. di allarme corrispondente alla relativa monitoring mode passata come intero
function getVibrometerSettingsByMonitoringMode(monitoringModeInt) {
  let customAxes;
  let customOperation;
  switch (monitoringModeInt) {
    case 0:
      customAxes = ["x", "y", "z"];
      customOperation = "sqrt";
      break;
    case 1:
      customAxes = ["x"];
      customOperation = "max";
      break;
    case 2:
      customAxes = ["y"];
      customOperation = "max";
      break;
    case 3:
      customAxes = ["z"];
      customOperation = "max";
      break;
    case 4:
      customAxes = ["x", "y", "z"];
      customOperation = "max";
      break;
    case 5:
      customAxes = ["x", "y"];
      customOperation = "sqrt";
      break;
    case 6:
      customAxes = ["x", "z"];
      customOperation = "sqrt";
      break;
    case 7:
      customAxes = ["y", "z"];
      customOperation = "sqrt";
      break;
    case 8:
      customAxes = ["x", "y"];
      customOperation = "max";
      break;
    case 9:
      customAxes = ["x", "z"];
      customOperation = "max";
      break;
    case 10:
      customAxes = ["y", "z"];
      customOperation = "max";
      break;
  }
  return {
    customAxes: customAxes,
    customOperation: customOperation,
  };
}

const loraRegions = [
  { _id: "EU863-870", gwchannels: ["EU868_V2"], freq: 869525000, datr: "SF9BW125" },
  {
    _id: "US902-928",
    gwchannels: ["US915_V2_CH0_7", "US915_V2_CH8_15", "US915_V2_CH16_23", "US915_V2_CH24_31", "US915_V2_CH32_39", "US915_V2_CH40_47", "US915_V2_CH48_55", "US915_V2_CH56_63"],
    freq: 923300000,
    datr: "SF12BW500",
  },
  { _id: "CN779-787", gwchannels: [], freq: 786000000, datr: "SF12BW125" },
  { _id: "EU433", gwchannels: [], freq: 434665000, datr: "SF12BW125" },
  {
    _id: "AU915-928",
    gwchannels: ["AU915_V2_CH0_7", "AU915_V2_CH8_15", "AU915_V2_CH16_23", "AU915_V2_CH24_31", "AU915_V2_CH32_39", "AU915_V2_CH40_47", "AU915_V2_CH48_55", "AU915_V2_CH56_63"],
    freq: 923300000,
    datr: "SF12BW500",
  },
  { _id: "CN470-510", gwchannels: [], freq: 505300000, datr: "SF12BW125" },
  { _id: "AS923", gwchannels: ["AS923-1_V2", "AS923-2_V2", "AS923-3_V2", "AS923-4_V2"], freq: 923200000, datr: "SF10BW125" },
  { _id: "KR920-923", gwchannels: [], freq: 921900000, datr: "SF12BW125" },
  { _id: "IN865-867", gwchannels: [], freq: 865200000, datr: "SF12BW125" },
  { _id: "RU864-870", gwchannels: [], freq: 869100000, datr: "SF12BW125" },
];

function truncateVibrometerThreshold(threshold = 0, mesUnit = 'mm/s') {
  const thresholdDecimals = String(threshold).includes('.') ? String(threshold).split('.')[1].length : 0;
  const decimals = mesUnit === "inch/s" ? 5 : 3;
  if (thresholdDecimals < decimals) {
    return threshold
  }
  const multipier = 10 ** decimals;
  return Math.trunc(threshold*multipier)/multipier;
}


function getShortEui(eui = ''){
  if (!eui || eui.length < 5) {
    return
  }
  return eui.substring(eui.length - 5, eui.length)
}

function getSensorFromEui(sensors = [], eui = null){
  if (!eui || eui.length < 5 || !sensors.length) {
    return null
  }
  return sensors.find(sensor => sensor.eui === eui)
}

function getDeckThreshold(resolution) {
  let threshold = { min: null, max: 1.2 }
  threshold.min = resolution === 0.012 ? 0.06 : 0.12;
  return threshold;
}



module.exports = {
  checkIfOnline,
  useTimestampFieldBySensor,
  getVibrometerMonitoringModeBySettings,
  getVibrometerSettingsByMonitoringMode,
  loraRegions,
  truncateVibrometerThreshold,
  getShortEui,
  getSensorFromEui,
  getDeckThreshold
};
