var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g(_vm._b({class:[
    this.noWrap ? 'noWrap' : '',
    this.bold || this.b ? 'bold' : '',
    this.paragraph || this.p ? 'paragraph' : '',
    this.caption ? 'caption' : '',
    this.h2 ? 'h2' : '',
    this.h3 ? 'h3' : '',
    this.monospace ? 'monospace' : '',
    'text' ],style:({ color: _vm.getColor })},'div',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }