<template>
  <popover
    v-bind="$attrs"
    v-on="$listeners"
    :name="name"
    :ref="popoverID"
    class="popover"
  >
    <slot />
  </popover>
</template>

<script>
export default {
  name: "MovePopUp",
  props: {
    name: String,
  },
  data() {
    return {
      popoverID: Math.random().toString(36).substr(2, 15),
    };
  },
  methods: {
    hide() {
      this.$refs[this.popoverID].visible = false;
    },
  },
};
</script>

<style scoped>
.popover {
  background: var(--secondary-color);
  border: 2px solid;
  border-color: var(--secondary-color-light-2);
  width: auto !important;
}

.popover::before {
  border-left-color: var(--secondary-color);
}

.vue-popover.dropdown-position-right:before{
  border-top:10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--secondary-color-light-2);
  left: -10px;
  top: calc(50% - 10px);
}
</style>
