var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',_vm._g(_vm._b({class:[
    this.disabled ? 'disabled' : 'enabled',
    this.large ? 'large' : '', 
    this.small ? 'small' : '',
    this.outlined ? 'btn-outlined' : '',
    this.rounded ? 'btn-rounded' : '',
    this.icon ? 'btn-icon' : '',
    this.text ? 'btn-text' : '',
    this.primary ? 'btn-primary' : '',
    this.secondary ? 'btn-secondary' : '',
    this.secondary ? 'btn-secondary' : '',
    this.tertiary ? 'btn-tertiary': '',
    this.block ? 'btn-block': '',
    'btn' ],attrs:{"disabled":this.disabled}},'button',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }