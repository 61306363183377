<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "app",
  data() {
    return {};
  },
mounted() {}
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500i,700,700i,900,900i");

body {
  background-color: rgb(45, 48, 65);
  margin: 0;
  font-size: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 1100px;
}

.noselect {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

#app {
  -webkit-text-size-adjust: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: grid;
}
</style>